import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Translate } from "../../../../utils/lang/translate";
import TabTeam from "./TabTeam";
import { userActions } from "../../../../actions";
import { TeamLoader } from "./TeamLoader";
import { Link } from "react-router-dom";
import { Event, initGAPg } from "../../_Common";

interface Props {
  GetingTeam: string;
  dataListTeam: any;
  ListTeam: () => void;
}

const TeamIndex: React.FC<Props> = ({ GetingTeam, dataListTeam, ListTeam }) => {
  useEffect(() => {
    initGAPg();
  }, []);
  if (GetingTeam !== "1") {
    ListTeam();
  }
  const handleAddTeam = (e: React.MouseEvent) => {
    Event("TEAMS", "USE_ADD_NEW_TEAM_BUTTON", "CLICK_EVENT");
  };

  return (
    <div>
      <div className="InFlx spctwB stbd2">
        <h3 className="DlMg">{Translate("settings", "storeteamor")}</h3>
        <div className=" StWdpwrp StBrdRd SthgMaxC">
          <Link to="/settings/team/add/new" onClick={handleAddTeam}>
            <div className="BdgBlcl StBrdRd" style={{ padding: "10px 20px" }}>
              <div className="FlWd RlPs">
                <span className="d-large">
                  {Translate("settings", "addteam")}
                </span>
                <span className="d-sm">{Translate("shop", "add")}</span>
                <span className="StLanNl StTpNl StAbs">+</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      {GetingTeam === "0" ? (
        <div className="InFlx FlWd StFlxWrp2">
          <TeamLoader />
          <TeamLoader />
        </div>
      ) : (
        <div>
          <div className="InFlx FlWd JstfCnt AlgnItm StFlxWrp2 responsive-team">
            {GetingTeam === "1" &&
              dataListTeam.teams.map((elem: any, key: number) => {
                return (
                  <TabTeam
                    key={key}
                    path={"/settings/team/view/" + elem.id}
                    name={elem.name}
                    permissions={elem.permissions}
                    vOrd={elem.view_order}
                    vsOrd={elem.view_stock}
                    vpOrd={elem.view_payment}
                    aeOrd={elem.add_edit_order}
                    lstmem={[null]}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

function mapState(state: any) {
  const { GetingTeam, dataListTeam } = state.team;
  return { GetingTeam, dataListTeam };
}

const actionCreators = {
  ListTeam: userActions.ListTeam,
};

export default connect(mapState, actionCreators)(TeamIndex);
