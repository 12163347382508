import React from 'react'

type Props = {
    theme?: string
}
const ArrowSvg = ({theme}:Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      fill="none"
      viewBox="0 7 13 10"
    >
      <g clipPath="url(#clip0_342_13210)">
        <path
          fill={theme === "light" ? "#000" : "#989797"}
          d="M6.583 15.953L1.73 11.101a.92.92 0 10-1.3 1.3l5.5 5.5a.918.918 0 001.3 0l5.5-5.5a.92.92 0 00-1.3-1.3l-4.848 4.852z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_342_13210">
          <path
            fill="#fff"
            d="M0 0H12.834V27.333H0z"
            transform="translate(.166 .834)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowSvg