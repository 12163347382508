import React, { useState } from 'react'
import { Translate } from '../../../utils/lang/translate'
import { VariantsBtnSvg } from './IconSvg'
import { Event } from './TrakEvent'
import { history } from './../../../utils/history';


const LandingOptionsButton = () => {
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <div className='landing-options-btn'>
      
      <button 
        className="rmOut FntfMin StBrdRd BdgBlcl DlBrd StSizLn StWht stbold CrsPoi BtNset FlWd variants-options-btn"
        onClick={()=>setShowDropdown(prev => !prev)}
        >
        {Translate("shop", "createLandingPage")}
        <span>{ VariantsBtnSvg }</span>
      </button>

      {showDropdown && (
        
      <ul>
          <li onClick={() => {
                Event("SHOP", "CREATE_LANDING_PAGE_BUTTON", "CLICK_EVENT");
                history.push("/shop/landing-page/add");
              }}>
                {Translate("shop", "defaultLandingPage")}
          </li>

          <li onClick={() => {
                Event("SHOP", "CREATE_LANDING_PAGE_WITH_VARIANTS_BUTTON", "CLICK_EVENT");
                history.push({ pathname: "/shop/landing-page/addwithvariants"});
              }}>
                {Translate("shop", "landingPageWithVariants")}
          </li>

      </ul>

      )}
    </div>
  );
}

export default LandingOptionsButton
