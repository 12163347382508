import React from "react";
export const ArDesign = (
  <style>{`@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@500&display=swap');
          :root{
              --fontLan:'El Messiri';
              --pslan:right;
              --psinv:left;
              --sizFnt:18px;
              --dirct:row-reverse;
          }
          .StAutMr{
            margin-right:auto
            }
            .StLftAut{
                margin-left:auto
                }
          .StrVl{
              right:none
          }
          @media screen and (max-width: 1200px){
              .StrVl{
                  right:220px
              }
          }
          .StLanNl{
              left: 0px;
          }
          .StFlxSDp{
            justify-content: flex-start;
            }
          .StLanNlin{
              right:0px;
          }
          .BrdLft{
              border-top-left-radius:5px;
              border-bottom-left-radius:5px;
          }
          .StMrtg{
              margin-left: 14px;
          }
          .StMrtg5{
            margin-left: 30px;
            }
          .StpdElm{
            padding:9px 18px 5px 12px
          }
          .StMrtg2{
              margin-left: 10px;
          }
          .SpcMrgvl:last-child{
            margin-left: 0;
            }
          .StMrtg3{
              margin-left: 7px;
          }
          .InvStMrtg{
            margin-right: 8px;
            }
          .LeftSet{
              right:16px;
          }
          .NgValRg{
              left:-17px
          }
          .RgDshbr{
            width:calc(50% - 5px);
            margin-left:5px
        }
        .RgDshbr_next{
            width:calc(50% - 5px);
            margin-right:5px
        }
          .StBrdRdSc > div:first-child{
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
          }
          .StBrdRdSc > div:last-child{
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
          }
          .StBrdBtLn{
              border-left: 1px solid var(--clrbrd);
          }
          .StDrc{
              direction: rtl;
          }
          .StDrcIn{
            direction: ltr;
          }
          .StCordNt{
            bottom:10px;
            right:22px
        }
        .RtVl{
            transform: scale(-1,1);
        }
          .Mrg2{
              margin-right: 2.5%;
          }
          .StMerV{
              margin-left:23px;
          }
          input[type="checkbox"] + label::before {
            margin-left:7px
        }
        .StRotVl{
            transform:rotate(180deg);
        }


        .Stwd-stck td:first-child,.Stwd-stck th:first-child{
            position: sticky;
            right: 0;
            z-index: 1;
            vertical-align: middle;
        }
        
        .Stwd-stck td:last-child,.Stwd-stck th:last-child{
            position: sticky;
            left: 0;
            vertical-align: middle;
        }
        .frCl-ss th:first-child{
            background:linear-gradient(to left,var(--bdgclInp) 70%, transparent);
        }
        
        .frCl-ss th:last-child{
            background:linear-gradient(to right,var(--bdgclInp) 70%, transparent);
        }
        .frCl-ss2 td:first-child{
            background:linear-gradient(to left,var(--defcl) 80%, transparent);
        }
        
        .frCl-ss2 td:last-child{
            background:linear-gradient(to right,var(--defcl) 80%, transparent);
        }
        .frCl-scnd3 th:nth-child(2){
            position: sticky;
            right: 35px;
            z-index: 1;
            background:linear-gradient(to left,var(--bdgclInp) 70%, transparent);
        }
        
        .frCl-scnd3 td:nth-child(2){
           
            background:linear-gradient(to left,var(--defcl) 80%, transparent);
        }
          `}</style>
);
export const EnDesign = (
  <style>{`@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&display=swap');
    :root{
        --fontLan:'Nunito Sans';
        --pslan:left;
        --psinv:right;
        --sizFnt:18px;
        --dirct:row;
    }
    .StAutMr{
        margin-left:auto
    }
    .StLanNl{
        right:0px;
    }
    .StrVl{
        left:none
    }
    .StCordNt{
        bottom:10px;
        left:22px
    }
    @media screen and (max-width: 1200px){
        .StrVl{
            left:220px
        }
    }
    .StLanNlin{
        left:0px;
    }
    .StpdElm{
        padding:9px 12px 5px 16px
      }
    .LeftSet{
        left:16px;
    }
    .NgValRg{
        right:-17px
    }
    .RgDshbr{
        width:calc(50% - 5px);
        margin-right:5px
    }
    .RgDshbr_next{
        width:calc(50% - 5px);
        margin-left:5px
    }
    .StFlxSDp{
        justify-content: flex-end;
        }
    .BrdLft{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .StMrtg{
        margin-right: 14px;
    }
    .StMrtg5{
        margin-right: 30px;
    }
    .StMrtg2{
        margin-right: 10px;
    }
    .SpcMrgvl:last-child{
        margin-right: 0px;
    }
    .StMrtg3{
        margin-right: 7px;
    }
    .InvStMrtg{
        margin-left: 8px;
    }
    .StBrdRdSc > div:first-child{
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }
    .StBrdRdSc > div:last-child{
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }
    .StBrdBtLn{
        border-right: 1px solid var(--clrbrd);
    }
    .StDrc{
        direction: ltr;
    }
    .Mrg2{
        margin-left: 2.5%;
    }
    .StMerV{
        margin-right:23px;
    }
    input[type="checkbox"] + label::before {
        margin-right:7px
    }


    .Stwd-stck td:first-child,.Stwd-stck th:first-child{
        position: sticky;
        left: 0;
        z-index: 1;
        vertical-align: middle;
    }
    
    .Stwd-stck td:last-child,.Stwd-stck th:last-child{
        position: sticky;
        right: 0;
        vertical-align: middle;
    }
    .frCl-ss th:first-child{
        background:linear-gradient(to right,var(--bdgclInp) 70%, transparent);
    }
    
    .frCl-ss th:last-child{
        background:linear-gradient(to left,var(--bdgclInp) 70%, transparent);
    }
    .frCl-ss2 td:first-child{
        background:linear-gradient(to right,var(--defcl) 80%, transparent);
    }
    
    .frCl-ss2 td:last-child{
        background:linear-gradient(to left,var(--defcl) 80%, transparent);
    }
    .frCl-scnd3 th:nth-child(2){
        position: sticky;
        left: 35px;
        z-index: 1;
        background:linear-gradient(to right,var(--bdgclInp) 70%, transparent);
    }
    
    .frCl-scnd3 td:nth-child(2){
        
        background:linear-gradient(to right,var(--defcl) 80%, transparent);
    }
      `}</style>
);
