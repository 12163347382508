import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../actions";
import GroupeForm from "../../_Common/GroupeForm";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import HeadLog from "../_Common/HeadLog";
import ContainerLog from "../_Common/ContainerLog";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { Event, initGAPg } from "../../StorePages/_Common";
import Loader from "../../_Common/Loader";

interface RequiredInput {
  id: string;
  name: string;
  placeholder: string;
  text: string;
  type: string;
  pattern?: string;
}

interface RegisterPageProps {
  user: any;
  CheckPhoneRegister: (phone: string) => void;
  CheckUsernameRegister: (username: string) => void;
  usernamedata: any;
  phonedata: any;
  checkingusername: string;
  checkingphone: string;
  RegisterTap: any;
  Sended: any;
  data: any;
  CrtingMember: string;
  registered: string;
}

const requiredInputs: RequiredInput[] = [
  {
    id: "full_name",
    name: "full_name",
    placeholder: Translate("auths", "fullname"),
    text: Translate("auths", "fullname"),
    type: "text",
  },
  {
    id: "phone_number",
    name: "phone",
    placeholder: Translate("auths", "phone"),
    text: Translate("auths", "phone"),
    type: "tel",
    pattern: "^((\\+216)?[1-9]\\d{7}|(0?|(00|\\+)?213)[56789]\\d{8})$",
  },
  {
    id: "Username",
    name: "user_name",
    placeholder: Translate("auths", "username"),
    text: Translate("auths", "username"),
    type: "text",
    pattern: "^\\S+",
  },
  {
    id: "Email",
    name: "email",
    placeholder: Translate("auths", "email"),
    text: Translate("auths", "email"),
    type: "email",
  },
  {
    id: "Password",
    name: "password",
    placeholder: Translate("auths", "password"),
    text: Translate("auths", "password"),
    type: "password",
  },
  {
    id: "confirmed_password",
    name: "confirmed_password",
    placeholder: Translate("auths", "confpassword"),
    text: Translate("auths", "confpassword"),
    type: "password",
  },
];

interface ErrorInterface {
  full_name?: string;
  phone?: any;
  user_name?: string;
  email?: any;
  password?: string;
  confirmed_password?: string;
  terms_use?: any;
  privacy_police?: any;
}

const RegisterPage: React.FC<RegisterPageProps> = ({
  user,
  CheckPhoneRegister,
  CheckUsernameRegister,
  usernamedata,
  phonedata,
  checkingusername,
  checkingphone,
  RegisterTap,
  Sended,
  data,
  CrtingMember,
  registered,
}) => {
  const [state, setState] = useState<any>({
    full_name: user ? user.full_name : "",
    phone: user ? user.phone : "",
    user_name: user ? user.user_name : "",
    email: user ? user.email : "",
    password: "",
    confirmed_password: "",
    terms_use: "",
    privacy_police: "",
    submited: false,
    shows: requiredInputs,
    errors: Object.assign(
      {},
      ...Array.from(requiredInputs, (el) => ({ [el.name]: "" }))
    ),
    alreadyExists: {},
    code: "+213",
  });
  const [hideInput, setHideInput] = useState(10);

  const onChangeCode = (e: any) => {
    setState({ ...state, code: e.target.value });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value, alreadyExists: {} });

    const limitPlusDZ = 10;
    const limitPlusTN = 11;
    const limitDZ = 10;
    const StartNumberPhone = value.substring(0, 1);

    if (state.code === "+213") {
      setHideInput(limitPlusDZ);
    }
    if (state.code === "+216") {
      setHideInput(limitPlusTN);
    }
    if (StartNumberPhone === "0") {
      setHideInput(limitDZ);
    }
  };

  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setState({ ...state, [name]: checked });
  };

  const handleAlreadyHaveAccount = () => {
    Event("REGISTER", "ALREADY_HAVE_ACCOUNT", "CLICK_EVENT");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password, confirmed_password, terms_use, privacy_police } = state;
    let valid = true,
      err: ErrorInterface = {};
    Event("REGISTER", "SUBMIT_ACCOUNT_INFOS", "CLICK_EVENT");
    requiredInputs.forEach((el: any) => {
      if (!state[el.name]) {
        err[el.name as keyof ErrorInterface] = Translate("error", "reqfield");
        valid = false;
      }
    });
    if (password && password.length < 6) {
      err.password = Translate("error", "e19");
      valid = false;
    }
    if (password !== confirmed_password) {
      err.confirmed_password = Translate("error", "e20");
      valid = false;
    }
    if (!terms_use) {
      err.terms_use = true;
      valid = false;
    }
    if (!privacy_police) {
      err.privacy_police = true;
      valid = false;
    }
    setState({ ...state, errors: err });
    if (!valid) {
      Event(
        "REGISTER",
        "ACCOUNT_FORM_ERRORS",
        `[${Object.keys(err).join(",")}]`
      );
      return;
    } else {
      const PremierChiffre = state.phone?.substr(0, 1);
      if (PremierChiffre === "0") {
        const elem = state.phone.slice(1);
        const phone = state.code + elem;
        CheckPhoneRegister(phone);
      } else {
        const elem = state.phone;
        const phone = state.code + elem;
        CheckPhoneRegister(phone);
      }
      CheckUsernameRegister(state.user_name);
    }
  };

  const { full_name, user_name, email, password, confirmed_password } = state;

  const PremierChiffre = state.phone?.substr(0, 1);
  if (PremierChiffre === "0") {
    const elem = state.phone.slice(1);
    const phone = state.code + elem;

    if (
      usernamedata?.exists === false &&
      phonedata?.exists === false &&
      checkingusername === "1" &&
      checkingphone === "1"
    ) {
      RegisterTap({
        full_name,
        phone,
        user_name,
        email,
        password,
        confirmed_password,
      });
      // Save this step infos temporary in case of abondon/lost connexion in next step
      Cookies.set(
        "temp-user-infos",
        {
          full_name,
          phone,
          user_name,
          email,
          password,
          confirmed_password,
        } as any,
        { expires: undefined }
      );
    }
  } else {
    const elem = state.phone;
    const phone = state.code + elem;

    if (
      usernamedata?.exists === false &&
      phonedata?.exists === false &&
      checkingusername === "1" &&
      checkingphone === "1"
    ) {
      RegisterTap({
        full_name,
        phone,
        user_name,
        email,
        password,
        confirmed_password,
      });
      // Save this step infos temporary in case of abondon/lost connexion in next step
      Cookies.set(
        "temp-user-infos",
        {
          full_name,
          phone,
          user_name,
          email,
          password,
          confirmed_password,
        } as any,
        { expires: undefined }
      );
    }
  }

  if (Sended === "0") {
    // return <Redirect to="/store/add"/>
    return <Redirect to="/register/continue" />;
  }

  return (
    <ContainerLog>
      <HeadLog
        titleHead={Translate("auths", "createaccount")}
        descHead={Translate("auths", "signuptext")}
      />
      <form method="post" onSubmit={handleSubmit} className="StMarMx">
        {state.shows.map((group: any, index: number) => {
          const { id, name, placeholder, text, type, pattern, ...others } = group;
          return (
            <div>
              <GroupeForm
                key={index}
                pattern={pattern ?? ".*"}
                id={id}
                name={name}
                placeholder={placeholder}
                text={text}
                type={type}
                value={state[name]}
                {...others}
                workfun={handleChange}
                maxLength={id === "phone_number" && hideInput}
                error={state.errors[name] || state.alreadyExists[name]}
                select={
                  <select
                    defaultValue={state.code}
                    onChange={onChangeCode}
                    className="selectPhone"
                  >
                    <option value="+213">DZ +213</option>
                    <option value="+216">TN +216</option>
                  </select>
                }
              />

              {usernamedata?.exists === true && name === "user_name" && (
                <p className="StRedClr TxtLeft DlMg DlPg">
                  {Translate("auths", "usernameValidation")}
                </p>
              )}
              {phonedata?.exists === true && name === "phone" && (
                <p className="StRedClr TxtLeft DlMg DlPg">
                  {Translate("auths", "phonenumberValidation")}
                </p>
              )}
            </div>
          );
        })}
        <CheckBoxForm
          id={"terms_use"}
          name={"terms_use"}
          text={
            <div>
              {Translate("auths", "ihaveread")}
              <Link className="StBle stbold StWdDst" to="/privace">
                {Translate("auths", "termus")}
              </Link>
            </div>
          }
          workfun={handleChangeCheck}
          error={state.errors.terms_use}
        />
        <CheckBoxForm
          id={"privacy_police"}
          name={"privacy_police"}
          text={
            <div>
              {Translate("auths", "ihaveread")}
              <Link className="StBle stbold StWdDst" to="/police">
                {Translate("auths", "privacypolic")}
              </Link>
            </div>
          }
          workfun={handleChangeCheck}
          error={state.errors.privacy_police}
        />
        <div className="SmtGrp MrAot StMarMx">
          <Button BtnText={Translate("auths", "signup")} />
        </div>
      </form>
      <div className="StOpcVal">
        <div className="StMrg">
          <span className="StWdDst">{Translate("auths", "haveaccount")}</span>
          <Link
            className="StBle stbold StWdDst"
            to="/login"
            onClick={handleAlreadyHaveAccount}
          >
            {Translate("auths", "logins")}
          </Link>
        </div>
      </div>
      {(checkingusername === "0" || checkingphone === "0") && <Loader />}
    </ContainerLog>
  );
};

function mapState(state: any) {
  const { Sended, user, data, CrtingMember, registered } = state.registration;
  const { checkingusername, usernamedata, checkingphone, phonedata } =
    state.user;
  return {
    Sended,
    user,
    data,
    CrtingMember,
    registered,
    checkingusername,
    usernamedata,
    checkingphone,
    phonedata,
  };
}

const actionCreators = {
  RegisterTap: userActions.RegisterTap,
  CheckUsernameRegister: userActions.CheckUsernameRegister,
  CheckPhoneRegister: userActions.CheckPhoneRegister,
};

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
