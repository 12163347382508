import React, { Component } from "react";
export default class SearchInputType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Glbldata: this.props.options,
      valueData: "",
    };
    this.ChangeSelect = this.ChangeSelect.bind(this);
    this.CheckChange = this.CheckChange.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      Glbldata: nextProps.options,
    });
  }
  ChangeSelect(e) {
    const { value } = e.target;
    this.setState({
      valueData: value,
    });
  }
  CheckChange(e) {
    const { value } = e.target;
    this.props.onChangeSearch(this.props.nameOption, value);
  }
  render() {
    let dataShowed = [];
    let CompteId = 0;
    const { NameShow, NameAffect, CheckedValues, nameOption, placeholder } =
      this.props;
    if (Array.isArray(this.state.Glbldata)) {
      if (this.props.deliverytype) {
        dataShowed = this.state.Glbldata.filter(
          (elem) =>
            elem[this.props.NameShow]
              .toLowerCase()
              .indexOf(this.state.valueData.toLowerCase()) !== -1
        );
        if (!dataShowed) {
          dataShowed = [];
        }
      } else {
        if (this.state.valueData && this.state.valueData.length >= 2) {
          dataShowed = this.state.Glbldata.filter(
            (elem) =>
              elem[this.props.NameShow]
                .toLowerCase()
                .indexOf(this.state.valueData.toLowerCase()) !== -1
          );
          if (!dataShowed) {
            dataShowed = [];
          }
        }
      }
    }

    return (
      <div>
        {!this.props.deliverytype && (
          <div style={{ height: "40px" }}>
            <input
              type="text"
              placeholder={placeholder}
              className="FntfMin lytwdp2p StdirLn FrInp StSizLn FlHg StBrdRd Fntcl"
              value={this.state.valueData}
              onChange={this.ChangeSelect}
            />
          </div>
        )}
        <div className="StPdsml stbd2">
          <div style={{ maxHeight: "250px" }} className="HidScrolStil">
            {Array.isArray(CheckedValues) &&
              Array.isArray(this.state.Glbldata) &&
              CheckedValues.map((option, key) => {
                let ElementCheckd = this.state.Glbldata.find(
                  (elem) => elem[NameAffect] === option.toString()
                );
                if (ElementCheckd) {
                  CompteId += 1;
                  return (
                    <div key={key} className="StMrg">
                      <div className="InFlx flxDrc AlgnItm">
                        <input
                          type="checkbox"
                          onChange={this.CheckChange}
                          value={ElementCheckd[NameAffect]}
                          id={"SearchInputType_" + nameOption + CompteId}
                          checked={true}
                        />
                        <label
                          htmlFor={"SearchInputType_" + nameOption + CompteId}
                        >
                          {ElementCheckd[NameShow]}
                        </label>
                      </div>
                    </div>
                  );
                }
                return undefined;
              })}
            {Array.isArray(dataShowed) &&
              dataShowed.map((option, key) => {
                let ValueName = option[NameShow];
                let ValueAffect = option[NameAffect];
                if (
                  Array.isArray(CheckedValues) &&
                  CheckedValues.indexOf(ValueAffect.toString()) === -1
                ) {
                  CompteId += 1;
                  return (
                    <div key={key} className="StMrg">
                      <div className="InFlx flxDrc AlgnItm">
                        <input
                          type="checkbox"
                          onChange={this.CheckChange}
                          value={ValueAffect}
                          id={"SearchInputType_" + nameOption + CompteId}
                          checked={false}
                        />
                        <label
                          htmlFor={"SearchInputType_" + nameOption + CompteId}
                        >
                          {ValueName}
                        </label>
                      </div>
                    </div>
                  );
                }
                return undefined;
              })}
          </div>
        </div>
      </div>
    );
  }
}
