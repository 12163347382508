import React, { useRef, useState, useEffect } from "react";
import GroupeForm from "../../../_Common/GroupeForm";
import { CameraSvg } from "../../_Common/IconSvg";
import { Translate } from "../../../../utils/lang/translate";
import Button from "../../../_Common/Button";
import {
  DataShop,
  UpdateDataShopSettings,
  UploadDataShopLogoSettings,
} from "../../../../types";
import TagInput from "../../../_Common/TagInput";
import useExitPrompt from "../../../../utils/useExitPrompt";
import { Prompt } from "react-router-dom";
import GetStartedPopup from "./GetStartedPopup";
import { Event } from "../../_Common";

interface Form {
  name: string;
  title: string;
  categories: string[];
}

interface Logo {
  source: string;
  imageFile: File;
}

interface Props {
  dataShop: DataShop;
  history: any;
  UploadDataShopLogo: (
    data: DataShop,
    settings: UploadDataShopLogoSettings,
    logo: File,
    successCallback?: () => void
  ) => void;
  UpdateDataShop: (
    data: DataShop,
    settings: UpdateDataShopSettings,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const StoreSettingsForm: React.FC<Props> = ({
  dataShop,
  history,
  UploadDataShopLogo,
  UpdateDataShop,
  SendAlert,
}) => {
  const [form, setForm] = useState<Form>({
    name: "",
    title: "",
    categories: [],
  });
  const [logo, setLogo] = useState<Logo>();
  const [isChange, setIsChange] = useExitPrompt(false);
  const [isGetStartedPopup, setIsGetStartedPopup] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => {
      setIsChange(false);
    };
  }, []);

  useEffect(() => {
    if (dataShop) {
      setForm({
        name: dataShop.name,
        title: dataShop.website_title,
        categories: dataShop.store_categories,
      });
      // if (!dataShop.published) setIsGetStartedPopup(true);
    }
  }, [dataShop]);

  const handlePhotoChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event) => {
    if (event.target.files) {
      const imageFile = event.target.files[0];
      setLogo({
        source: URL.createObjectURL(imageFile),
        imageFile,
      });
      setIsChange(true);
    }
  };

  const handlePhotoClick = () => {
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (event) => {
    const { value, name } = event.target as HTMLInputElement;
    setForm({ ...form, [name]: value });
    setIsChange(true);
  };

  const handleCategoriesChange = (newCategories: string[], name: string) => {
    setForm({ ...form, categories: newCategories });
    setIsChange(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      (dataShop.logo || logo) &&
      form.name &&
      form.name.length > 0 &&
      form.title &&
      form.title.length > 0
    ) {
      let newData = {
        name: form.name,
        website_title: form.title,
        store_categories: form.categories,
        settings_stage:
          dataShop.settings_stage === 0 ? 1 : dataShop.settings_stage,
      };
      if (!logo) {
        Event("SHOP", "SHOP_INFO_SAVE", "CLICK_EVENT");
        UpdateDataShop(dataShop, newData, () => {
          setIsChange(false);
          if (!dataShop.published) history.push("/shop/settings/theme");
          else SendAlert("41", Translate("shop", "changesSaved"));
        });
      } else {
        Event("SHOP", "SHOP_INFO_SAVE", "CLICK_EVENT");
        UploadDataShopLogo(dataShop, newData, logo.imageFile, () => {
          setIsChange(false);
          if (!dataShop.published) history.push("/shop/settings/theme");
          else SendAlert("41", Translate("shop", "changesSaved"));
        });
      }
    }
  };

  return (
    <div className="StoreSettingsForm">
      <Prompt when={isChange} message={Translate("alert", "prompt")} />

      <GetStartedPopup
        onClose={() => {
          setIsGetStartedPopup(false);
        }}
        isHidden={!isGetStartedPopup}
      />

      <div
        className="image-container"
        onClick={() => {
          if (inputRef.current) inputRef.current.click();
        }}
      >
        {logo?.source ? (
          <img src={logo?.source} alt="Store logo" />
        ) : dataShop.logo ? (
          <img src={dataShop.logo} alt="Store logo" />
        ) : (
          <div>
            {CameraSvg}
            <p>{Translate("shop", "uploadStoreLogo")}</p>
          </div>
        )}
        <input
          ref={inputRef}
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/webp"
          onChange={handlePhotoChange}
          onClick={handlePhotoClick}
        />
      </div>
      <div className="StoreSettingsForm-form">
        <GroupeForm
          id="name"
          name="name"
          placeholder={Translate("shop", "shopName")}
          text={Translate("shop", "shopName")}
          type="text"
          value={form.name}
          workfun={handleChange}
          required
        />
        <GroupeForm
          id="title"
          name="title"
          placeholder={Translate("shop", "website_title")}
          text={Translate("shop", "website_title")}
          type="text"
          value={form.title}
          workfun={handleChange}
          required
        />
        <TagInput
          values={form.categories}
          text={Translate("shop", "categories")}
          name="categories"
          placeholder={Translate("shop", "categoryPlaceholder")}
          handleChange={handleCategoriesChange}
        />
        <Button
          BtnText={Translate("shop", !dataShop.published ? "next" : "save")}
          disabled={
            !(dataShop.logo || logo) ||
            !form.name ||
            form.name.length < 1 ||
            !form.title ||
            form.title.length < 1
          }
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default StoreSettingsForm;
