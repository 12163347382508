import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Translate } from "../../../utils/lang/translate";
import Cookies from "js-cookie";
import "./RichTextEditor.scss";

interface Props {
  value: string;
  setValue: (content: string) => void;
  title?: string;
  id?: string;
  optional?: boolean;
  isError?: boolean;
  loadingGenerateBtn?: boolean;
  disabledGenerateBtn?: boolean;
  onClickGenerateBtn?: () => void;
  generatedBtnSvg?: JSX.Element;
  generateBtn?: boolean;
}

const RichTextEditor = ({
  value,
  setValue,
  title,
  id,
  optional,
  isError,
  loadingGenerateBtn,
  disabledGenerateBtn,
  generatedBtnSvg,
  onClickGenerateBtn,
  generateBtn
}: Props) => {
  const editorRef = useRef<any>(null);

  const handleChange = (editorContent: string = "") => {
    setValue(editorContent);
  };

  const theme = Cookies.get("theme") == "0" ? "light" : "dark";

  return (
    <div className={`RichTextEditor ${theme} ${isError ? "isError" : ""}`}>
      <div 
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <label htmlFor={id ? id : ""}>
          {title}
          {optional && <span> ({Translate("shop", "optional")})</span>}
        </label>

        {
          generateBtn
          &&
          <button
            disabled={disabledGenerateBtn}
            onClick={onClickGenerateBtn} 
            style={{ 
              backgroundColor: "rgba(0, 138, 255, 0.90)",
              color: "white",
              padding: "8px 10px",
              borderRadius: "20px",
              border: "2px solid #008AFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              cursor: "pointer",
              minWidth: "165px"
            }}
          > 
            { 
              loadingGenerateBtn
              ?
                <div className="loader-spinner"></div>
              :
                <>
                  <span>{generatedBtnSvg}</span>
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      lineHeight: "normal",
                      fontStyle: "normal"
                      }}
                  >
                    {Translate("shop", "generateDescriptionWithAi")}
                  </span>
                </>
              }
          </button>
        }
      </div>
      <Editor
        onInit={(_, editor) => (editorRef.current = editor)}
        value={value}
        onEditorChange={handleChange}
        id={id ? id : ""}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            "advlist",
            "advcode",
            "advtable",
            "autolink",
            "checklist",
            "export",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "powerpaste",
            "fullscreen",
            "formatpainter",
            "insertdatetime",
            "media",
            "help",
            "wordcount",
            "code",
            "directionality",
          ],
          file_picker_types: "image",
          file_picker_callback: (callback, value, meta) => {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            input.onchange = function () {
              var file = input.files ? input.files[0] : null;

              var reader = new FileReader();
              reader.onload = function () {
                /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                var id = "blobid" + new Date().getTime();
                var blobCache = editorRef.current.editorUpload.blobCache;
                var base64 = reader.result
                  ? (reader.result as string).split(",")[1]
                  : null;
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                callback(blobInfo.blobUri(), { title: file?.name });
              };
              file && reader.readAsDataURL(file);
            };

            input.click();
          },
          toolbar:
            "undo redo | casechange blocks | bold italic backcolor " +
            "alignleft aligncenter alignright alignjustify ltr rtl | link image media | " +
            "bullist numlist checklist outdent indent | removeformat code | help",
          contextmenu: false,
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          skin: theme === "light" ? "oxide" : "oxide-dark",
          content_css: theme,
        }}
      />
    </div>
  );
};

export default RichTextEditor;
