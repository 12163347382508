import React, { useState, Fragment, useEffect } from "react";
import { Translate } from "../../../utils/lang/translate";
import { FormatDate } from "../../../utils/lang/translate";
import { Link } from "react-router-dom";
import { FeatureLoked } from "../_Common/FeatureLoked";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TableLoader from "../_Common/TableLoader";
import {
  DeletSvg,
  DoneSvg,
  FlechDwnSvg,
  FilterSvg,
  RefreshSvg,
  CloseSvg,
  ViewSvg,
  PrintSvg,
  OutSvg,
  CheckSvg,
  PlayListAddSvg,
  FileDownSvg,
  ExcelSvg,
} from "../_Common/IconSvg";
import SmallText from "../_Common/SmallText";
import InlinedElement from "../_Common/InlinedElement";
import ContainerPage from "../_Common/ContainerPage";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import DatePicker from "../../_Common/DatePicker";
import Table from "../_Common/Table";
import GroupeBtn from "../_Common/GroupeBtn";
import PaginationBar from "../_Common/PaginationBar";
import { RECEIVED_PRODUCT_HEADER } from "../../constants";
import {
  productActions,
  commonActions,
  alertActions,
  userActions,
} from "../../../actions";
import { useQueryParam } from "use-query-params";
import PropTypes from "prop-types";

import { addUrlProps, UrlQueryParamTypes, Serialize } from "react-url-query";

import { NoVoucher } from "../_Common/NoVoucher";

import DropDown from "./InputSearch";

import { initGAPg, Event } from "../_Common";
import CheckBoxForm from "../../_Common/CheckBoxForm";

const urlPropsQueryConfig = {
  page: { type: UrlQueryParamTypes.number },

  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
};

const ReceiveProduct = ({
  dataReceive,
  GettingBonReceive,
  GetAllReceiveProducts,
  props,
  Waredata,
  GetWarehouse,
  onChangePage,
  page = 1,
  onChangeBegan,
  onChangeEnd,
  onChangeDate,
  end,
  began,
  SendAlert,
  location,
  Exporting,
  ExportReceivedProduct,
  dtStoreinfo,
}) => {
  const history = useHistory();

  const [listWarehouse, setListWarehouse] = useState();
  const [reference, setReference] = useQueryParam("reference");
  const [inputReference, setInputReference] = useState(reference);

  const [warehouse, setWarehouse] = useQueryParam("warehouse");
  const [inputWarehouse, setInputWarehouse] = useState(warehouse);

  const [nextpage, setNextpage] = useState(
    GettingBonReceive === 2 ? dataReceive?.next : null
  );
  const [prevpage, setPrevpage] = useState(
    GettingBonReceive === 2 ? dataReceive?.previous : null
  );
  const [count, setCount] = useState(dataReceive?.count);

  const [allSelect, setAllSelect] = useState(null);

  const [filteredResults, setFilteredResults] = useState([]);

  const changeInputReference = (e) => {
    const { value } = e.target;

    setInputReference(value);
  };

  const changeSelectWarehouse = (e) => {
    const { value } = e.target;

    setWarehouse(value);
  };

  const handleReferenceInput = () => {
    Event("VOUCHER_RECEIVE", "FILTER_BY_REFERENCE", "CLICK_EVENT");
    if (inputReference === reference) {
      setInputReference("");
      setReference("");
    } else {
      setReference(inputReference);
    }
  };

  const [titlepage, setTitlePage] = useState(
    <div>
      <Link className="StOpcVal" to="/stock/receiveProduct">
        {Translate("titles", "receiveProduct")}
      </Link>
      <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
      <span>{Translate("product", "ReceivedProducts")}</span>
    </div>
  );

  function refreshPage() {
    Event(
      "VOUCHER_RECEIVE",
      "USE_REFRESH_VOUCHER_RECEIVE_BUTTON",
      "CLICK_EVENT"
    );
    window.location.href = "/stock/receiveProduct";
  }
  function resetFilter() {
    Event("VOUCHER_RECEIVE", "RESET_ALL_FILTERS", "CLICK_EVENT");
    window.location.href = "/stock/receiveProduct";
  }
  const handleSelectAll = (e) => {
    Event(
      "ORDERS",
      "USE_SELECTALL_CHECKBOX",
      `${allSelect ? "UNCHECK" : "CHECK"}`
    );
    setAllSelect(allSelect ? null : true);
  };

  const handleUseSelect = (e) => {
    const { name } = e.target;
    let cuurent = selectIdlist;
    let idOrder = name;
    let index = cuurent.indexOf(idOrder);
    if (index === -1) {
      cuurent.push(idOrder);
    } else {
      cuurent.splice(index, 1);
    }
    selectIdlist = cuurent;
    if (allSelect) {
      setAllSelect(null);
    }
  };

  useEffect(() => {
    initGAPg();
  });

  useEffect(() => {
    GetAllReceiveProducts(`delivery/vouchers/receipt/?page=${page}`);
  }, [page]);

  useEffect(() => {
    if (reference && reference.length > 0)
      GetAllReceiveProducts(`delivery/vouchers/receipt/?search=${reference}`);
  }, [reference]);

  useEffect(() => {
    if (warehouse && warehouse.length > 0)
      GetAllReceiveProducts(
        `delivery/vouchers/receipt/?warehouse=${warehouse}`
      );
  }, [warehouse]);

  useEffect(() => {
    if (began && end)
      GetAllReceiveProducts(
        `delivery/vouchers/receipt/??start_date=${began}&end_date=${end}`
      );
  }, [began, end]);

  useEffect(() => {
    GetWarehouse();
    if (Exporting === "0") {
      SendAlert("31", Translate("alert", "exportreqstsent"));
    } else if (Exporting === "1") {
      SendAlert("41", Translate("alert", "succesexprt"));
    } else if (Exporting === "2") {
      SendAlert("50", Translate("alert", "tryagain"));
    }
  }, [Exporting]);

  useEffect(() => {
    if (Waredata?.list?.results) {
      setListWarehouse(Waredata?.list?.results);
    }
  }, [Waredata?.list?.results]);

  useEffect(() => {
    if (GettingBonReceive === 2) {
      document.getElementById("Container-root").scrollTo(0, 0);
      setCount(props?.dataReceive?.count);
      setNextpage(props?.dataReceive?.next);
      setPrevpage(props?.dataReceive?.previous);
    }
  }, [props]);

  const CallExportOrder = (fileEx) => {
    Event("ORDERS", "EXPORT_ORDERS", `EXORT_AS_${fileEx.toUpperCase()}`);
    ExportReceivedProduct(
      location.search.replaceAll(".", ",").replace("prduord", "products"),
      fileEx,
      allSelect === null ? false : true,
      selectIdlist
    );
  };

  const ExportXlsMobile = () => {
    ExportReceivedProduct(
      location.search.replaceAll(".", ",").replace("prduord", "products"),
      "xlsx",
      allSelect === null ? false : true,
      selectIdlist
    );
  };
  let bodytab = [];
  var selectIdlist = [];
  bodytab = dataReceive?.results?.map((elem) => {
    let actionBtns = [];
    const { id, reference, warehouse, created_at } = elem;

    let checked = selectIdlist.indexOf(id) !== -1 || allSelect;

    actionBtns.push({
      type: "BDgInpc",
      action: () => (
        Event("VOUCHER_RECEIVE", "VIEW_DETAILS_VOUCHER_RECEIVE", "CLICK_EVENT"),
        (window.location.href = `/stock/detail/${id}`)
      ),
      svgbtn: ViewSvg,
    });

    return [
      !dtStoreinfo?.stock_managed && (
        <CheckBoxForm
          name={id}
          rmMarg={true}
          workfun={handleUseSelect}
          check={checked}
          id={"order_print_" + id}
        />
      ),
      "N° BS/" + reference,
      <SmallText lengthText={70}>{warehouse}</SmallText>,
      <SmallText lengthText={70}>{FormatDate(created_at)}</SmallText>,

      <GroupeBtn data_btn={actionBtns} />,
    ];
  });

  const ShowFilter = () => {
    Event("VOUCHER_RECEIVE", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", handleClickOutsideFilter);
    } else {
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    }
  };
  const FilterRef = React.createRef();

  const handleClickOutsideFilter = (event) => {
    if (
      FilterRef &&
      FilterRef.current &&
      !FilterRef.current.contains(event.target)
    ) {
      ShowFilter();
    }
  };

  const controlExport = () => {
    Event("ORDERS", "DROP_EXPORT_LIST", "CLICK_EVENT");
    let clsList = document.getElementById("List_Export");
    if (clsList.classList.value.indexOf("hidElem2") === -1) {
      clsList.style.height = "0px";
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", handleClickOutsideExpRef);
    } else {
      clsList.classList.remove("hidElem2");
      clsList.style.height = "165px";
      document.addEventListener("mousedown", handleClickOutsideExpRef);
    }
  };
  const ExpRef = React.createRef();

  const handleClickOutsideExpRef = (event) => {
    if (ExpRef && ExpRef.current && !ExpRef.current.contains(event.target)) {
      controlExport();
    }
  };

  if (dataReceive?.count == 0) {
    return <NoVoucher />;
  } else {
    return (
      <ContainerPage
        page_title={titlepage}
        data_top={
          <div>
            <div className="StAutMr d-sm">
              <div
                className="Inlflx AlgnItm StDrc StFlxStr FlWd"
                style={{ height: "48px" }}
              >
                <GroupeBtnSp
                  style={{ margin: "0", zoom: "1.2" }}
                  data_btn={[
                    {
                      type: "BDgInpc",
                      action: refreshPage,
                      svgbtn: RefreshSvg,
                      tooltip: Translate("tooltips", "refresh"),
                    },
                    {
                      type: "BDgInpc",
                      action: ExportXlsMobile,
                      svgbtn: PlayListAddSvg,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="d-large">
              <DatePicker
                from={began}
                to={end}
                changeFrom={onChangeBegan}
                changeTo={onChangeEnd}
                chnageDate={onChangeDate}
              />
            </div>
          </div>
        }
      >
        <div
          className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
          style={{ height: "48px", padding: "7px 0", top: "-20px" }}
        >
          <div role="filter-data" className="FlHg InFlx StwdMaxC flxDrc">
            <div ref={FilterRef} className="FlHg ClSidTh StBrdRdS StMrtg2">
              <div className="CrsPoi FlHg" onClick={ShowFilter}>
                <InlinedElement
                  secnd={<div className="IcnSiz">{FilterSvg}</div>}
                />
              </div>
              <div
                id="Filter_container"
                className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                style={{ width: "350px" }}
              >
                <div id="Sub_Container_filter">
                  <div style={{ marginTop: "8px" }}>
                    <div onClick={ShowFilter} className="RlPs">
                      <InlinedElement
                        secnd={<strong>{Translate("orders", "filter")}</strong>}
                        leftElm={
                          <div className="InFlx CrsPoi IcnSizSm">
                            {CloseSvg}
                          </div>
                        }
                        style={{ width: "100%", padding: "0px" }}
                      />
                    </div>

                    <span
                      className="CrsPoi StBle DsBlc StdirLn"
                      onClick={resetFilter}
                    >
                      {Translate("orders", "resetfilter")}
                    </span>
                  </div>
                  <div className="BdgClN StBrdRdS StPdsml SmMarS1">
                    <div className="StHeivl RlPs CrsPoi">
                      <DropDown
                        valuee={warehouse}
                        onChange={changeSelectWarehouse}
                        options={listWarehouse}
                      />
                    </div>
                    <div id="wilaya_Container" className="hidElem3 stTranEs">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="FlHg StBrdRdS ClSidTh">
              <InlinedElement
                style={{ padding: "0" }}
                secnd={
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      name="filterbyid"
                      value={inputReference}
                      onChange={changeInputReference}
                      style={{ width: "300px", maxWidth: "30vw" }}
                      className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                      placeholder={Translate("product", "searchVoucher")}
                    />
                  </div>
                }
                leftElm={
                  <button
                    onClick={handleReferenceInput}
                    className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                    style={{ padding: "10px", margin: "0 5px" }}
                  >
                    {inputReference === reference ? OutSvg : CheckSvg}
                  </button>
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          ></div>

          <div className="StAutMr d-large" ref={ExpRef}>
            <div
              className="Inlflx AlgnItm StDrc StFlxStr FlWd"
              style={{ height: "48px" }}
            >
              <GroupeBtnSp
                style={{ margin: "0", zoom: "1.2" }}
                data_btn={[
                  !dtStoreinfo?.stock_managed && {
                    type: "BDgInpc",
                    action: controlExport,
                    svgbtn: PlayListAddSvg,
                  },
                  {
                    type: "BDgInpc",
                    action: refreshPage,
                    svgbtn: RefreshSvg,
                    tooltip: Translate("tooltips", "refresh"),
                  },
                ]}
              />
            </div>
            <div className="RlPs InFlx StDrc StFlxStr" style={{ zIndex: "2" }}>
              <div
                id="List_Export"
                className="hidElem2 StwdMaxC HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1"
                style={{ height: "0px" }}
              >
                <div
                  className="CrsPoi"
                  style={{ height: "55px" }}
                  onClick={() => CallExportOrder("csv")}
                >
                  <InlinedElement
                    style={{ flexDirection: "row" }}
                    first={
                      <div className="IcnSiz" style={{ zoom: "1.2" }}>
                        {FileDownSvg}
                      </div>
                    }
                    secnd={<div>{Translate("orders", "exportordcsv")}</div>}
                  />
                </div>
                <div
                  className="CrsPoi"
                  style={{ height: "55px" }}
                  onClick={() => CallExportOrder("xlsx")}
                >
                  <InlinedElement
                    style={{ flexDirection: "row" }}
                    first={
                      <div className="IcnSiz" style={{ zoom: "1.2" }}>
                        {ExcelSvg}
                      </div>
                    }
                    secnd={<div>{Translate("orders", "exportordxsl2007")}</div>}
                  />
                </div>
                <div
                  className="CrsPoi"
                  style={{ height: "55px" }}
                  onClick={() => CallExportOrder("xls")}
                >
                  <InlinedElement
                    style={{ flexDirection: "row" }}
                    first={
                      <div className="IcnSiz" style={{ zoom: "1.2" }}>
                        {ExcelSvg}
                      </div>
                    }
                    secnd={<div>{Translate("orders", "exportordxsl97")}</div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-sm" style={{ marginBottom: "20px" }}>
          <DatePicker
            from={began}
            to={end}
            changeFrom={onChangeBegan}
            changeTo={onChangeEnd}
            chnageDate={onChangeDate}
          />
        </div>
        {GettingBonReceive == 1 ? (
          <TableLoader />
        ) : (
          <Fragment>
            <Table
              thead_elem={[
                !dtStoreinfo?.stock_managed && (
                  <CheckBoxForm
                    rmMarg={true}
                    name="all_order"
                    check={allSelect}
                    workfun={handleSelectAll}
                    id="order_all_order_printed"
                  />
                ),
                Translate("receivedProducts", "refrence").toUpperCase(),
                Translate("receivedProducts", "wharehouse").toUpperCase(),
                Translate("receivedProducts", "date").toUpperCase(),
                Translate("receivedProducts", "action").toUpperCase(),
              ]}
              tbody_elem={bodytab}
            />
            <div role="pagination" className="lytWdp SmMarS1">
              <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                <span className="d-large">
                  {Translate("orders", "showing") +
                    " " +
                    (page * 20 - 19) +
                    " - " +
                    (page * 20 < dataReceive?.count
                      ? page * 20
                      : dataReceive?.count) +
                    " " +
                    Translate("orders", "of") +
                    " " +
                    dataReceive?.count}
                </span>
                <PaginationBar
                  className="StAutMr"
                  NbPage={Math.trunc(dataReceive?.count / 20) + 1}
                  currentPage={parseInt(page)}
                  ChangePage={onChangePage}
                  blockNext={dataReceive?.next}
                  blockPrev={dataReceive?.previous}
                />
              </div>
            </div>
          </Fragment>
        )}
      </ContainerPage>
    );
  }
};

function mapState(state) {
  const { dataReceive, GettingBonReceive } = state.product;
  const { dtStoreinfo } = state.user;
  const { Exporting } = state.listener;
  const { Waredata } = state.common;

  return {
    dataReceive,
    GettingBonReceive,
    Waredata,
    Exporting,
    dtStoreinfo,
  };
}

const actionCreators = {
  GetAllReceiveProducts: productActions.GetAllReceiveProducts,
  GetWarehouse: commonActions.GetWarehouse,
  SendAlert: alertActions.SendAlert,
  ExportReceivedProduct: productActions.ExportReceivedProduct,
};

const connectedProductsAll = connect(mapState, actionCreators)(ReceiveProduct);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedProductsAll);
export { QueryProps as ReceiveProduct };

ReceiveProduct.defaultProps = {
  page: 1,
};

ReceiveProduct.propTypes = {
  page: PropTypes.number,
  began: PropTypes.string,
  end: PropTypes.string,
  onChangePage: PropTypes.func,
  onChangeBegan: PropTypes.func,
  onChangeEnd: PropTypes.func,
};
