import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { userActions, alertActions } from "../../../../actions";
import { Translate } from "../../../../utils/lang/translate";
import { FlechSvg } from "../../_Common/IconSvg";
import GroupeForm from "../../../_Common/GroupeForm";
import CheckBoxForm from "../../../_Common/CheckBoxForm";
import Button from "../../../_Common/Button";
import Loader from "../../../_Common/Loader";
import { initGAPg, Event } from "../../_Common";

interface AddTeamProps {
  CreatingTeam: string;
  UpdatingTeam: string;
  SendAlert: (id: string, message: string) => void;
  GetingDTeam: string;
  dataDListTeam: { team: { id: number; name: string; permissions: any[] } };
  CreateTeam: (team: { name: string; permissions: any[] }) => void;
  UpdateTeam: (
    team: { name: string; permissions: any[] },
    id: string | number
  ) => void;
  GetProfileUser: () => void;
  dtStoreinfo: any;
  idteam: any;
}
type Params = {
  idteam: string;
};
const AddTeam: React.FC<AddTeamProps> = (props: AddTeamProps) => {
  const {
    CreatingTeam,
    UpdatingTeam,
    SendAlert,
    GetingDTeam,
    dataDListTeam,
    CreateTeam,
    UpdateTeam,
    GetProfileUser,
    dtStoreinfo,
  } = props;

  const { idteam } = useParams<Params>();
  const [state, setState] = useState({
    team_name: "",
    view_order: false,
    add_order: false,
    change_order: false,
    view_stock: false,
    view_payment: false,
    view_issue_store: false,
    view_pickup: false,
    titlepage: Translate("settings", "addteam"),
    ttbtn: Translate("settings", "addtm"),
    idTeam: "new",
    backto: "/settings/team/home",
  });
  const [errors, setErrors] = useState("");
  const [errorsCheckbox, setErrorsCheckbox] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (idteam !== "new") {
      if (GetingDTeam === "1") {
        if (dataDListTeam.team && dataDListTeam.team.id === Number(idteam)) {
          setState((prevState) => ({
            ...prevState,
            team_name: dataDListTeam.team.name,
            idTeam: dataDListTeam.team.id,
            titlepage: Translate("settings", "editteam"),
            ttbtn: Translate("orders", "save"),
            ...dataDListTeam.team.permissions.reduce(
              (acc: any, prem: { codename: string }) => ({
                ...acc,
                [prem.codename]: true,
              }),
              {}
            ),
          }));
        } else {
          history.push("/settings/team/home");
        }
      } else {
        history.push("/settings/team/home");
      }
    }
  }, [GetingDTeam]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangecheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.target;
    setState((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    let prem = [];
    let isValid = true;

    if (state.view_order) {
      prem.push({ codename: "view_order" });
    }
    if (state.add_order) {
      prem.push({ codename: "add_order" });
    }
    if (state.view_stock) {
      prem.push({ codename: "view_stock" });
    }
    if (state.view_issue_store) {
      prem.push({ codename: "view_issue_store" });
    }
    if (state.change_order) {
      prem.push({ codename: "change_order" });
    }
    if (state.view_payment) {
      prem.push({ codename: "view_payment" });
    }
    if (state.view_pickup) {
      prem.push({ codename: "view_pickup" });
    }
    let team = { name: state.team_name, permissions: prem };
    if (!state.team_name) {
      isValid = false;
      setErrors(Translate("error", "reqfield"));
    } else {
      setErrors("");
    }
    if (prem.length === 0) {
      isValid = false;
      setErrorsCheckbox(true);
    } else {
      setErrorsCheckbox(false);
    }

    if (state.idTeam === "new" && isValid === true) {
      Event("STORE_TEAM", "CREAT_NEW_TEAM", "SUBMIT_EVENT");
      props.CreateTeam(team);
    } else if (isValid === true) {
      Event("STORE_TEAM", "EDIT_EXIST_TEAM", "SUBMIT_EVENT");
      props.UpdateTeam(team, state.idTeam);
    }
  };
  if (CreatingTeam === "1") {
    props.SendAlert("41", Translate("alert", "teamAddsuc"));
    return <Redirect to="/settings/team/home" />;
  }
  return (
    <div>
      {(CreatingTeam === "0" || UpdatingTeam === "0") && <Loader />}
      <div className="stbd2">
        <div className="InFlx flxDrc StDrcIn">
          <div className="StWdDst StPaddingVal CrsPoi RtVl">
            <Link to={state.backto}>{FlechSvg}</Link>
          </div>
          <div className="StdirLn">
            <h3 className="DlMg">{state.titlepage}</h3>
            <p className="DlMg">{Translate("settings", "txtteam")}</p>
          </div>
        </div>
      </div>
      <div className="MrAot Hlwd StMrtp responsive-team">
        <form method="post" onSubmit={handleSubmit}>
          <GroupeForm
            id={"team_name"}
            name={"team_name"}
            placeholder={Translate("settings", "teamname")}
            text={Translate("settings", "teamname")}
            type={"text"}
            value={state.team_name}
            workfun={handleChange}
            error={errors}
          />
          <div className="stbd2">
            <div>
              <span className="DsBlc">
                {Translate("settings", "permmision")}
              </span>
            </div>
            <div className="StdirLn StDrcIn">
              <CheckBoxForm
                id={"view_order"}
                name={"view_order"}
                text={Translate("settings", "view_order")}
                workfun={handleChangecheck}
                check={state.view_order}
                error={errorsCheckbox}
              />
              <CheckBoxForm
                id={"add_order"}
                name={"add_order"}
                text={Translate("settings", "add_order")}
                workfun={handleChangecheck}
                check={state.add_order}
                error={errorsCheckbox}
              />
              <CheckBoxForm
                id={"change_order"}
                name={"change_order"}
                text={Translate("settings", "change_order")}
                workfun={handleChangecheck}
                check={state.change_order}
                error={errorsCheckbox}
              />
              <CheckBoxForm
                id={"view_stock"}
                name={"view_stock"}
                text={Translate("settings", "view_stock")}
                workfun={handleChangecheck}
                check={state.view_stock}
                error={errorsCheckbox}
              />
              <CheckBoxForm
                id={"view_payment"}
                name={"view_payment"}
                text={Translate("settings", "view_payment")}
                workfun={handleChangecheck}
                check={state.view_payment}
                error={errorsCheckbox}
              />
              {!dtStoreinfo?.stock_managed && (
                <CheckBoxForm
                  id={"view_pickup"}
                  name={"view_pickup"}
                  text={Translate("settings", "view_pickup")}
                  workfun={handleChangecheck}
                  check={state.view_pickup}
                  error={errorsCheckbox}
                />
              )}
            </div>
          </div>
          <div className="MrAot StMarMx" style={{ width: "200px" }}>
            <Button BtnText={state.ttbtn} />
          </div>
        </form>
      </div>
    </div>
  );
};

function mapState(state: any) {
  const { dtStoreinfo } = state.user;
  const {
    GetingDTeam,
    dataDListTeam,
    UpdatingTeam,
    dataTeamUpdate,
    CreatingTeam,
    dataTeamCreate,
  } = state.team;
  return {
    GetingDTeam,
    dataDListTeam,
    UpdatingTeam,
    dataTeamUpdate,
    CreatingTeam,
    dataTeamCreate,
    dtStoreinfo,
  };
}
const actionCreators = {
  CreateTeam: userActions.CreateTeam,
  UpdateTeam: userActions.UpdateTeam,
  GetProfileUser: userActions.GetProfileUser,
  SendAlert: alertActions.SendAlert,
};

const connectedAddTeam = connect(mapState, actionCreators)(AddTeam);
export { connectedAddTeam as AddTeam };
