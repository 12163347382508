import { pickupsConstants } from "../constants";

export function pickups(state, action) {
  switch (action.type) {
    case pickupsConstants.ADD_PICKUP_REQUEST:
      return {
        PickupAdded: "1",
        dataAddPickup: action.payload,
      };
    case pickupsConstants.ADD_PICKUP_SUCCESS:
      return {
        PickupAdded: "2",
        dataAddPickup: action.payload,
      };
    case pickupsConstants.ADD_PICKUP_FAILURE:
      return {
        PickupAdded: "3",
        dataAddPickup: action.payload,
      };
    case pickupsConstants.GET_PICKUP_REQUEST:
      return { ...state, PickupGetting: "1", PickupsList: action.payload };
    case pickupsConstants.GET_PICKUP_SUCCESS:
      return { ...state, PickupGetting: "2", PickupsList: action.payload };
    case pickupsConstants.GET_PICKUP_FAILURE:
      return { ...state, PickupGetting: "3", PickupsList: action.payload };
    case pickupsConstants.GET_POSSIBLE_ORDERS_REQUEST:
      return {
        ...state,
        GettingPossibleOrders: "1",
        OrdersList: action.payload,
      };
    case pickupsConstants.GET_POSSIBLE_ORDERS_SUCCESS:
      return {
        ...state,
        GettingPossibleOrders: "2",
        OrdersList: action.payload,
      };
    case pickupsConstants.GET_POSSIBLE_ORDERS_FAILURE:
      return {
        ...state,
        GettingPossibleOrders: "3",
        OrdersList: action.payload,
      };
    case pickupsConstants.GET_PICKUPORDERS_REQUEST:
      return {
        ...state,
        PickupOrdersGetting: "1",
        PickupOrders: action.payload,
      };
    case pickupsConstants.GET_PICKUPORDERS_SUCCESS:
      return {
        ...state,
        PickupOrdersGetting: "2",
        PickupOrders: action.payload,
      };
    case pickupsConstants.GET_PICKUPORDERS_FAILURE:
      return {
        ...state,
        PickupOrdersGetting: "3",
        PickupOrders: action.payload,
      };
    case pickupsConstants.UPDATE_PICKUP_REQUEST:
      return {
        PickupUpdating: "1",
        DataUpdatePickup: action.payload,
      };
    case pickupsConstants.UPDATE_PICKUP_SUCCESS:
      return {
        PickupUpdating: "2",
        DataUpdatePickup: action.payload,
      };
    case pickupsConstants.UPDATE_PICKUP_FAILURE:
      return {
        PickupUpdating: "3",
        DataUpdatePickup: action.payload,
      };
    case pickupsConstants.UPDATE_STATUS_PICKUP_REQUEST:
      return {
        PickupUpdateStatus: "0",
        DataUpdateStatus: action.payload,
      };
    case pickupsConstants.UPDATE_STATUS_PICKUP_SUCCESS:
      return {
        PickupUpdateStatus: "1",
        DataUpdateStatus: action.payload,
      };
    case pickupsConstants.UPDATE_STATUS_PICKUP_FAILURE:
      return {
        PickupUpdateStatus: "2",
        DataUpdateStatus: action.payload,
      };
    case pickupsConstants.POST_BORDEREAU_PICKUP_REQUEST:
      return {
        ...state,
        postingbordereauPickup: "1",
        dataBordereauPickup: action.payload,
      };
    case pickupsConstants.POST_BORDEREAU_PICKUP_SUCCESS:
      return {
        ...state,
        postingbordereauPickup: "2",
        dataBordereauPickup: action.payload,
      };
    case pickupsConstants.POST_BORDEREAU_PICKUP_FAILURE:
      return {
        ...state,
        postingbordereauPickup: "3",
        dataBordereauPickup: action.payload,
      };
    case pickupsConstants.CLEAR:
      return {};
    default:
      return { ...state };
  }
}
