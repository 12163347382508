import React from "react";
import GroupeForm from "../../_Common/GroupeForm";
import { Translate } from "../../../utils/lang/translate";
import { DeletSvg } from "../_Common/IconSvg";
import { ProductShopOptions } from "../../../types";
import { priceChecker } from "../../../utils/helpers";

interface Props {
  options: ProductShopOptions[];
  handleChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key?: number
  ) => void;
  handleDeleteOption: (key: number) => void;
  handleAddOption: () => void;
}

const ProductPricingOptionsFields: React.FC<Props> = ({
  options,
  handleChange,
  handleDeleteOption,
  handleAddOption,
}) => {
  return (
    <>
      {options.map((option, key) => {
        const hasSameNameAsOtherOptions = options.some(
          (opt, k) => key !== k && opt.name === option.name
        );
        return (
          <div
            className="grid grid-4-1-2-2 RlPs marginTopSmall OptionsField align-items-end"
            key={key}
            style={options.length > 1 ? { width: "calc(100% - 30px)" } : {}}
          >
            <GroupeForm
              id={`name-option-${key}`}
              name="name"
              placeholder={Translate("shop", "optionName")}
              text={Translate("shop", "optionName")}
              type="text"
              value={option.name}
              workfun={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                handleChange(e, key);
              }}
              stclass={
                !option.name || hasSameNameAsOtherOptions ? "borderError" : ""
              }
              required
            />
            <GroupeForm
              id={`quantity-option-${key}`}
              name="quantity"
              text={Translate("shop", "quantity")}
              type="number"
              placeholder={Translate("shop", "quantity")}
              min={1}
              value={key === 0 ? 1 : option.quantity}
              workfun={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                handleChange(e, key);
              }}
              readOnly={key === 0}
              stclass={!option.quantity ? "borderError" : ""}
              required
            />
            <GroupeForm
              id={`price-option-${key}`}
              name="price"
              text={Translate("shop", "price")}
              type="number"
              placeholder={Translate("shop", "price")}
              min={0}
              value={option.price}
              workfun={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                handleChange(e, key);
              }}
              inputStyle={
                option.reduced_price ? { textDecoration: "line-through" } : {}
              }
              stclass={
                !priceChecker(option.price, option.reduced_price)
                  ? "borderError"
                  : ""
              }
              required
            />
            <GroupeForm
              id={`reduced_price-option-${key}`}
              name="reduced_price"
              text={Translate("shop", "reducedPrice")}
              type="number"
              placeholder={Translate("shop", "reducedPrice")}
              min={0}
              value={option.reduced_price}
              workfun={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                handleChange(e, key);
              }}
            />
            {options.length > 1 && key !== 0 && (
              <div
                className="deleteOptionButton"
                onClick={() => {
                  handleDeleteOption(key);
                }}
              >
                {DeletSvg}
              </div>
            )}
          </div>
        );
      })}
      {options.length < 10 && (
        <div className="addOptionButton" onClick={handleAddOption}>
          + {Translate("shop", "addAnotherOption")}
        </div>
      )}
    </>
  );
};

export default ProductPricingOptionsFields;
