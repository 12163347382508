import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "../../../utils/lang/translate";
import ContainerPage from "../_Common/ContainerPage";
import PaginationBar from "../_Common/PaginationBar";
import { NotificationIcons } from "../_Common/IconSvg";
import { notificationsActions, ordersActions } from "../../../actions";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar-dz";
import { Event, initGAPg } from "../_Common";

const lang = Cookies.get("lang");
moment.locale(lang);

const NotificationItem = (props) => {
  return (
    <div
      className={
        "FlWd InFlx responsFlx flxDrc AlgnItm CrsPoi " +
        (props.read ? "" : "Tstatus_31")
      }
      style={{
        borderBottom: props.noBorder ? "none" : "0.5px inset var(--bdgclInp)",
      }}
      onClick={props.onClickHandler}
    >
      <div
        style={{ flexGrow: 1, width: "10%" }}
        className="FlHg InFlx AlgnItm JstfCnt TxtCn StOpcVal"
      >
        <div className="FlHg FlWd">{moment(props.time).fromNow()}</div>
      </div>
      <div style={{ flexGrow: 9, width: "90%" }}>
        <div className="InFlx responsFlx AlgnItm flxDrc">
          <div
            style={{ flexGrow: 1, width: "10%" }}
            className="FlHg InFlx AlgnItm JstfCnt"
          >
            {props.notif}
          </div>
          <div
            style={{ flexGrow: 9, width: "90%" }}
            className="FlHg InFlx flxDrc"
          >
            <div
              className={
                "FlHg FlWd Stclmnf StPdsml " + (lang === "ar" ? "StFlxStr" : "")
              }
            >
              <div className="InFlx flxDrc">
                <h4 className="DlMg">
                  {" "}
                  {Translate("notifevents", props.title)}
                </h4>
              </div>
              <div className="InFlx flxDrc">
                <p className=" DlMg StOpcVal">{props.desc}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="CrsPoi" style={{flexGrow: 1,zoom: 1.4}}>{DeletSvg}</div> */}
    </div>
  );
};
NotificationItem.prototype = {
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
};

const urlPropsQueryConfig = {
  page: { type: UrlQueryParamTypes.number },
};

class NotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertdelet: null,
      allSelect: null,
      allNotifs: [],
      nextpage:
        this.props.OrderGeting === "2"
          ? this.props.OrdersList.ressuc.list.next
          : null,
      prevpage:
        this.props.OrderGeting === "2"
          ? this.props.OrdersList.ressuc.list.previous
          : null,
      count:
        this.props.OrderGeting === "2"
          ? this.props.OrdersList.ressuc.list.count
          : 0,
    };
  }
  static defaultProps = {
    page: 1,
  };
  static propTypes = {
    page: PropTypes.number,
    /********************** */
    onChangePage: PropTypes.func,
  };
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }

  componentWillReceiveProps(nextProps) {
    const { GettingNotifs, dataNotifs } = nextProps;
    if (
      this.props.GettingNotifs === "1" &&
      GettingNotifs === "2" &&
      dataNotifs.ressuc &&
      dataNotifs.ressuc.results
    ) {
      this.setState({
        allNotifs: dataNotifs.ressuc.results,
        count: dataNotifs.ressuc.count,
        nextpage: dataNotifs.ressuc.next,
        prevpage: dataNotifs.ressuc.previous,
      });
    }
    if (nextProps.page !== this.props.page) {
      this.props.GetNotifications(this.getFilterToState(nextProps));
    }
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "notifs");
    initGAPg();
    this.props.GetNotifications(this.getFilterToState());
  }
  MarkAsRed(notif) {
    Event("NOTIFICATIONS", "READ_NOTIFICATION", "CLICK_EVENT");
    this.props.MarkAsRead(notif.id);

    if (notif.payload) {
      try {
        let payload = JSON.parse(notif.payload.replace(/'/g, '"'));
        if (payload.title === "Low Stock") {
          this.props.history.push("/products/detail/" + payload.product_id);
        } else if (payload.title === "Alert") {
          this.props.history.push(
            "/order/detail/" + payload.display_id + "?source=order"
          );
        } else {
          this.props.history.push("/unfulfilled/order/" + payload.display_id);
        }
      } catch (err) {}
    }
  }

  render() {
    const { page } = this.props;

    return (
      <ContainerPage page_title={Translate("titles", "notifs")}>
        <div className="StBrdTb StBgbrds StbgPad ClSidTh">
          {this.state.allNotifs &&
            this.state.allNotifs.map((el, idx) => {
              let text = "";
              if (el.payload) {
                try {
                  let payload = JSON.parse(el.payload.replace(/'/g, '"'));
                  if (el.title === "Low Stock") {
                    text = (
                      <div>
                        <div className=" d-large">
                          <div className="InFlx flxDrc">
                            <span>
                              {Translate("notifeventsdesc", "lowstock")}{" "}
                            </span>
                            <span>{" ' "}</span>
                            <span>
                              {" "}
                              {payload.product_name &&
                                payload.product_name}{" "}
                            </span>
                            <span>{" ' "}</span>
                            <span>{" . "}</span>
                            <span>{Translate("orders", "quantity")}</span>
                            <span>{" : "}</span>
                            <span>{payload.quantity && payload.quantity} </span>
                          </div>
                        </div>
                        <div className="d-sm">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              {Translate("notifeventsdesc", "lowstock")}
                            </span>

                            <span>
                              {" ' "}
                              {payload.product_name && payload.product_name}
                              {" ' "}
                            </span>
                            <span>
                              {Translate("orders", "quantity")}
                              {" : "}
                              {payload.quantity && payload.quantity}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    text = el.text;
                  }
                } catch (err) {}
              }

              return (
                <NotificationItem
                  noBorder={idx === this.state.allNotifs.length - 1}
                  time={el.created_at}
                  title={el.title}
                  desc={text}
                  read={el.read}
                  notif={
                    el.title === "Low Stock"
                      ? NotificationIcons.LowStock
                      : el.title === "Alert"
                      ? NotificationIcons?.Alert
                      : el.title === "Order Postponed"
                      ? NotificationIcons.postponed
                      : NotificationIcons.Orders
                  }
                  onClickHandler={() => this.MarkAsRed(el)}
                />
              );
            })}
          <div role="pagination" className="lytWdp SmMarS1">
            <div className="InFlx flxDrc FlWd RlPs AlgnItm">
              <span className="d-large">
                {Translate("orders", "showing") +
                  " " +
                  (page * 20 - 19) +
                  " - " +
                  (page * 20 < this.state.count
                    ? page * 20
                    : this.state.count) +
                  " " +
                  Translate("orders", "of") +
                  " " +
                  this.state.count}
              </span>
              <PaginationBar
                className="StAutMr"
                NbPage={Math.trunc(this.state.count / 20) + 1}
                currentPage={parseInt(page)}
                ChangePage={this.props.onChangePage}
                blockNext={this.state.nextpage}
                blockPrev={this.state.prevpage}
              />
            </div>
          </div>
        </div>
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { OrderUpdSta, DataUpdSta, OrderUpdating } = state.orders;
  const { dataProfile, dtStoreinfo } = state.user;
  const { GettingNotifs, dataNotifs, UpdatingNotif, dataUpdateNotif } =
    state.notifications;
  return {
    dataProfile,
    OrderUpdSta,
    DataUpdSta,
    dtStoreinfo,
    OrderUpdating,
    GettingNotifs,
    dataNotifs,
    UpdatingNotif,
    dataUpdateNotif,
  };
}

const actionCreators = {
  MarkAsRead: notificationsActions.MarkNotifAsRead,
  GetNotifications: notificationsActions.GetNotifications,
  GetOrders: ordersActions.GetOrders,
};

const connectedNotificationPage = connect(
  mapState,
  actionCreators
)(NotificationPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(
  connectedNotificationPage
);

export { QueryProps as NotificationPage };
