import React, { Component } from "react";
import { connect } from "react-redux";
import {
  productActions,
  ordersActions,
  exchangeActions,
  commonActions,
  alertActions,
} from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import ToggleSwitch from "../../_Common/ToggleSwitch";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link, Redirect } from "react-router-dom";
import { initGAPg, Event, Select } from "../_Common";
import Cookies from "js-cookie";
import { whichWilayaHasPermissionToStopDesk } from "../../../utils/helpers";

const lang = Cookies.get("lang");

const SwitchOptionButton = ({ optionId, onOptionChange, currentOption }) => {
  return (
    <div
      onClick={() => {
        optionId === 1
          ? Event(
              "REFUND_ORDER",
              "USE_HOME_DELIVERY_OPTION_BUTTON",
              "CLICK_EVENT"
            )
          : Event("REFUND_ORDER", "USE_STOP_DESK_OPTION_BUTTON", "CLICK_EVENT");
        onOptionChange();
      }}
      className="col-half InFlx AlgnItm JstfCnt responsive-text"
      style={{
        borderRadius: "50px",
        padding: "10px 0",
        cursor: "pointer",
        backgroundColor: `${
          currentOption === optionId ? "#008AFF" : "transparent"
        }`,
      }}
    >
      {optionId === 1
        ? Translate("orders", "home")
        : Translate("orders", "stpdsk")}
    </div>
  );
};

class RefundPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [
        {
          product_id: "",
          quantity: 0,
          logistical_description: "",
          defective: false,
        },
      ],
      orderproducts: [],
      replaceproducts: [],
      extraproducts: [],
      customername: "",
      customerphonenumber: "",
      adresse: "",
      district: null,
      city: "",
      orderprice: "0",
      notetodriver: "",
      selectedOption: null,
      delivryprice: 0,
      totalprice: "0",
      error: {},
      notfound: false,
      nbproduct: 1,
      productnames: [],
      stopDesk: null,
      loadingPrice: false,
      filtersource:
        this.props.OrderGeting === "2" ? this.props.OrderGeting.filter : "",
      titlepage: Translate("orders", "refundorder"),
      titlebtn: Translate("exchanges", "submit"),
      submit: false,
      tab_id: null,
      source: this.$_GET("source"),
      permmisionedit:
        this.props.dataProfile.is_store || this.props.dataProfile.change_order,
      permmisionadd:
        this.props.dataProfile.is_store || this.props.dataProfile.add_order,
    };
    this.isExternCity = null;
    this.isExternCommune = null;
    // this.submit = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    this.handleReplaceProduct = this.handleReplaceProduct.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleStopDeskSelect = this.handleStopDeskSelect.bind(this);

    // this.AddFieldProduct = this.AddFieldProduct.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const {
      data,
      ProductFinded,
      GetingDelivery,
      dataDelivery,
      RefundAdded,
      OrderGeting,
      OrdersList,
    } = nextProps;
    var dataCommed = null;
    var externTitle, FilterSource;
    const {
      match: { params },
    } = this.props;
    let idorder = params.idorder;
    if (this.props.OrderGeting === "1" && OrderGeting === "2") {
      dataCommed = OrdersList.ressuc.list.results.filter(
        (elem) => elem.display_id === idorder
      )[0];
      FilterSource = OrdersList.filter;
      this.isExternCommune = dataCommed.commune;
      externTitle = (
        <div className="StDrc">
          <Link className="StOpcVal" to={"/orders"}>
            {Translate("titles", "order")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <div className="Inlflx">{Translate("orders", "refundorder")}</div>
          <div className="Inlflx StWdDst">#</div>
          <div className="Inlflx">{dataCommed.display_id}</div>
        </div>
      );
    }

    if (dataCommed) {
      this.isExternCity = dataCommed.wilaya;
      this.setState({
        id: dataCommed.id,
        products: dataCommed.products.map((el) => ({
          ...el,
          quantity: 0,
          defective: false,
        })),
        orderproducts: dataCommed.products.map((el) => ({ ...el })),
        replaceproducts: dataCommed.products.map((el) => []),
        customername: dataCommed.customer_name,
        customerphonenumber: dataCommed.customer_phone,
        adresse: dataCommed.destination_text,
        orderprice: (Number(dataCommed.product_price) * 1e12) / 1e12,
        notetodriver: dataCommed.note_to_driver,
        delivryprice: Number(dataCommed.price) ? Number(dataCommed.price) : 0,
        selectedOption: dataCommed.delivery_type,
        totalprice:
          (((dataCommed.price ? Number(dataCommed.price) : 0) +
            Number(dataCommed.product_price)) *
            1e12) /
          1e12,
        notfound: false,
        status: dataCommed.status,
        nbproduct: dataCommed.products.length,
        titlepage: externTitle,
        titlebtn: Translate("orders", "save"),
        filtersource: FilterSource,
        // stopDesk: dataCommed.delivery_type === 2 ? dataCommed : null
      });
    }

    if (ProductFinded === "2" && this.state.submit) {
      this.setState({ productnames: data.list });
      // this.submit=false;
    }
    if (this.props.GetingDelivery === "1" && GetingDelivery === "2") {
      this.setState({
        delivryprice: Number(dataDelivery.delivery_price),
        orderprice:
          (Number(this.state.totalprice) * 1e12) / 1e12 -
          Number(dataDelivery.delivery_price),
      });
    }
    if (RefundAdded === "3" && this.state.submit) {
      document.getElementById("Container-root").scrollTo(0, 0);
      this.setState({ submit: false });
      // this.submit=false;
    }
  }
  componentWillUnmount() {
    this.props.ClearStore("CLEAR_COMMON");
  }
  componentDidMount() {
    document.title =
      "Maystro Delivrey - " + Translate("titles", "reimbursements");
    initGAPg();
    this.props.GetCountry();
    this.props.Getwilayas(this.props.dtStoreinfo?.country?.id);
    this.props.GetStopdeskWilayas(this.props.dtStoreinfo?.country?.id);
    const {
      match: { params },
    } = this.props;
    let idorder = params.idorder;
    this.props.GetOrders("?display_id_or_external_id=" + idorder, false);
    this.props.GetAllProduct();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.dtStoreinfo?.country?.id !== prevProps.dtStoreinfo?.country?.id
    ) {
      if (this.props.dtStoreinfo?.country?.id) {
        this.props.Getwilayas(this.props.dtStoreinfo.country.id);
        this.props.GetStopDeskWilayas(this.props.dtStoreinfo.country.id);
      }
    }

    if (
      this.state.selectedOption !== prevState.selectedOption ||
      (this.state.district && this.state.district !== prevState.district)
    ) {
      if (this.state.selectedOption === 1) {
        if (this.state.city && prevState.city !== this.state.city) {
          this.props.Getcommunes("?wilaya=" + this.state.city[0]);
        }
        if (this.state.district) {
          this.props.GetDelivery(
            this.state.district.id,
            false,
            this.state.selectedOption
          );
        }
      }

      if (this.state.selectedOption === 2) {
        if (this.state.stopDesk) {
          this.props.GetDelivery(
            this.state.stopDesk.center_commune,
            false,
            this.state.selectedOption
          );
        }
      }
    }

    if (this.props.GetingDelivery !== prevProps.GetingDelivery) {
      if (this.props.GetingDelivery === "1") {
        this.setState({
          loadingPrice: true,
        });
      } else {
        if (
          this.props.GetingDelivery === "2" &&
          this.props.dtStoreinfo?.country?.id === 1
        ) {
          this.setState({
            delivryprice: Number(this.props.dataDelivery.delivery_price),
            orderprice:
              Number(this.state.totalprice) -
              Number(this.props.dataDelivery.delivery_price),
          });
        }
        if (
          this.props.GetingDelivery === "2" &&
          this.props.dtStoreinfo?.country?.id === 2
        ) {
          this.setState({
            delivryprice: Number(this.props.dataDelivery.delivery_price),
            orderprice:
              Number(this.state.totalprice) -
              Number(this.props.dataDelivery.delivery_price),
          });
        }
        this.setState({
          loadingPrice: false,
        });
      }
    }

    const {
      match: { params },
    } = this.props;
    let idorder = params.idorder;

    if (
      this.props.GetingSDWilayas === "1" &&
      this.props.OrderGeting === "2" &&
      (this.props.GetingSDWilayas !== prevProps.GetingSDWilayas ||
        this.props.OrderGeting !== prevProps.OrderGeting)
    ) {
      const orderData = this.props.OrdersList.ressuc.list.results.find(
        (elem) => elem.display_id === idorder
      );
      const desk = this.props.dataStopDeskWilaya.find(
        (value) => value.name_lt === orderData.wilaya
      );
      if (desk) {
        this.setState({
          stopDesk: desk,
        });
      }
    }
  }

  handleStopDeskSelect(stopDeskWilaya) {
    this.setState({
      stopDesk: stopDeskWilaya,
    });
    this.props.GetDelivery(
      stopDeskWilaya.center_commune,
      false,
      this.state.selectedOption
    );
  }

  AddExtraFieldProduct() {
    Event("ADD_REFUND", "USE_ADD_ANOTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    let products = this.state.extraproducts;
    products.push({ product_id: "", quantity: 0 });
    this.setState({
      extraproducts: products,
    });
  }
  RemoveFieldProduct(pos) {
    Event(
      "ADD_REFUND",
      "USE_REMOVE_EXCHANGE_WITH_OTHER_PRODUCT_BUTTON",
      "CLICK_EVENT"
    );
    let products = this.state.replaceproducts;
    products[pos].splice(pos, 1);
    this.setState({
      replaceproducts: products,
    });
  }
  RemoveExtraFieldProduct(pos) {
    Event("ADD_REFUND", "USE_REMOVE_ADD_OTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    let nb = this.state.extraproducts.length;
    let products = this.state.extraproducts;
    if (nb > 0) {
      products.splice(pos, 1);
      this.setState({
        extraproducts: products,
      });
    }
  }
  handleChange(e) {
    const { name, value } = e.target;

    if (name === "orderprice" && this.props.dtStoreinfo?.country?.id === 1) {
      let prix = parseInt(value.replace(/\D/g, ""));
      this.setState({
        orderprice: value === "" ? value : prix,
        // orderprice:
        //   prix >= this.state.delivryprice ? prix - this.state.delivryprice : 0,
        totalprice: value === "" ? value : prix,
      });
      return;
    } else if (
      name === "orderprice" &&
      this.props.dtStoreinfo?.country?.id === 2
    ) {
      let prix = value === "" ? "0" : value.replace(/[^0-9.]/g, "");
      this.setState({
        orderprice: value === "" ? value : prix,
        // orderprice:
        //   prix >= this.state.delivryprice ? prix - this.state.delivryprice : 0,
        totalprice: value === "" ? value : prix,
      });
      return;
    }
    this.setState({ [name]: value });
  }

  handleChangeProduct(e) {
    const { name, value } = e.target;
    let product = this.state.products;
    let tabn = name.split("_");
    let indx = parseInt(tabn[1]);
    product[indx - 1].quantity = Number(value);
    if (value > 0) product[indx - 1].is_refund = true;
    this.setState({
      products: product,
    });
  }
  handleReplaceProduct(e) {
    const { name, value } = e.target;
    let tabn = name.split("_");
    let indx = parseInt(tabn[1]);
    let extraproduct = tabn[0].startsWith("extra");
    let products = extraproduct
      ? this.state.extraproducts
      : this.state.replaceproducts;
    if (tabn[0].endsWith("productname")) {
      let key_elem = this.state.productnames.filter(
        (elem) => elem.logistical_description === value
      )[0];
      if (key_elem) {
        if (extraproduct) {
          // changing productname for extra products
          products[indx - 1].product_id = key_elem.id;
          products[indx - 1].logistical_description = value;
          this.setState({
            extraproducts: products,
            notfound: false,
          });
        } else {
          products[indx - 1][0].product_id = key_elem.id;
          products[indx - 1][0].logistical_description = value;
          this.setState({
            replaceproducts: products,
            // products: product,
            notfound: false,
          });
        }
      }
      if (!key_elem) {
        if (extraproduct) {
          products[indx - 1].logistical_description = value;
          products[indx - 1].product_id = "";
          this.setState({
            notfound: this.props.dtStoreinfo.stock_managed ? true : false,
            extraproducts: products,
            // products: product,
          });
        } else {
          products[indx - 1][0].logistical_description = value;
          products[indx - 1][0].product_id = "";
          this.setState({
            notfound: this.props.dtStoreinfo.stock_managed ? true : false,
            replaceproducts: products,
          });
        }
      }
    } else {
      if (extraproduct) {
        // changing quantity for extra products
        products[indx - 1].quantity = Number(value);
        this.setState({
          extraproducts: products,
        });
      } else {
        products[indx - 1][0].quantity = Number(value);
        this.setState({
          replaceproducts: products,
        });
      }
    }
  }

  handleSelect(elem, name) {
    if (elem) {
      if (
        name === "city" &&
        ((this.state.city && this.state.city[0] !== elem[0]) ||
          !this.state.city)
      ) {
        this.setState({
          district: null,
        });

        this.props.Getcommunes("?wilaya=" + elem[0]);
      }
    }
    this.setState({
      [name]: elem,
    });
  }

  setProductDefective(product, index) {
    let products = this.state.products;
    products[index].defective = !products[index].defective;
    Event(
      "ADD_REFUND",
      "TOGGLE_PRODUCT_DEFECTIVE",
      `SET_TO_${products[index].defective ? "DEFECTIVE" : "NOT_DEFECTIVE"}`
    );
    this.setState({
      products: products,
    });
  }

  handleSubmit(e) {
    const { dtStoreinfo } = this.props;
    e.preventDefault();
    Event("ADD_REFUND", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
    let keepGoing = true;
    let order = {},
      error = {};
    if (this.state.selectedOption === 1) {
      if (this.state.city && this.state.city[0]) {
        order.wilaya = this.state.city[0];
      } else {
        error.ercity = Translate("error", "eo1");
        keepGoing = false;
      }
      if (this.state.district && this.state.district.id) {
        order.commune = this.state.district.id;
      } else {
        error.ercommune = Translate("error", "eo2");
        keepGoing = false;
      }
      if (this.state.adresse) {
        order.destination_text = this.state.adresse;
      }
    }

    if (this.state.selectedOption === 2) {
      if (this.state.stopDesk && typeof this.state.stopDesk.code === "number") {
        order.wilaya = this.state.stopDesk.code;
      } else {
        keepGoing = false;
      }
      if (this.state.stopDesk.center_commune) {
        order.commune = this.state.stopDesk.center_commune;
      } else {
        keepGoing = false;
      }
      order.destination_text = "";
    }

    if (this.state.customerphonenumber) {
      order.customer_phone = this.state.customerphonenumber;
    } else {
      error.erphone = Translate("error", "eo5");
      keepGoing = false;
    }
    if (this.state.customername) {
      order.customer_name = this.state.customername;
    } else {
      error.ername = Translate("error", "eo4");
      keepGoing = false;
    }
    
    if (Number(this.state.orderprice) >= 0 && this.state.orderprice !== "") {
      order.total_price = Number(this.state.orderprice);
    }else{
      error.erprice = Translate("error", "eo6");
      keepGoing = false;
    }

    if (this.state.delivryprice) {
      order.price = Number(this.state.delivryprice);
    }
    if (this.state.selectedOption) {
      order.delivery_type = this.state.selectedOption;
    }

    if (keepGoing) {
      this.setState({ submit: true });
      // this.submit = true;

      // if(dtStoreinfo.stock_managed){
      //   let replacementProducts=this.state.replaceproducts.filter(el=>el.length>0).map(el=>({...el[0]}))
      //   order.products = this.state.products.filter(el=>el.is_refund).concat(replacementProducts);
      //   let nbrExchanged=this.state.products.filter(el=>el.quantity>0).length+(replacementProducts.length)

      //   if(nbrExchanged===0) {
      //     alert(Translate("error","e12"))
      //     return
      //   }

      // }else{
      //
      // }
      if (dtStoreinfo.stock_managed) {
        order.products = this.state.products
          .filter((el) => el.quantity > 0)
          .map((el) => ({ ...el, is_refund: true }));
        if (order.products.length === 0) {
          alert(Translate("error", "e14"));
          return;
        }
        order.products = order.products.concat(this.state.extraproducts);
      } else {
        order.products = this.state.products
          .filter((el) => el.quantity > 0)
          .map((el) => ({ ...el, is_refund: true }));
        if (order.products.length === 0) {
          alert(Translate("error", "e14"));
          return;
        }
        order.products = this.state.products
          .filter((el) => el.quantity > 0)
          .map((el) => ({ ...el, is_refund: true }));
        order.products = order.products.concat(this.state.extraproducts);
      }

      order.old_order = this.state.id;
      order.is_refund = true;
      order.note_to_driver = this.state.notetodriver;

      if (this.state.permmisionadd) {
        Event("ADD_REFUND", "SUBMIT_INFOS", "INFOS_VALID");
        const isPremium = dtStoreinfo.stock_managed;
        this.props.AddRefund(order, isPremium);
      }
    } else {
      Event(
        "ADD_REFUND",
        "ERROR_INFORMATION_ENTER",
        `[${Object.keys(error).join(",")}]`
      );
      this.setState({
        error: error,
      });
    }
  }
  render() {
    const {
      RefundAdded,
      dataAddRefund,
      dataWilaya,
      DataCommune,
      GetingWilaya,
      GetigCommune,
    } = this.props;

    if (RefundAdded === "2" && this.state.submit) {
      this.props.GetOrders("");

      this.props.SendAlert("41", Translate("alert", "refundadd"), "a01");
      return <Redirect to="/orders" />;
    }
    let Alertfail = "";
    if (RefundAdded === "3" && this.state.submit) {
      if (
        dataAddRefund &&
        dataAddRefund.response &&
        dataAddRefund.response.data
      ) {
        Alertfail = Translate("error", dataAddRefund.response.data.detail);
      }

      this.submit = false;
      this.setState({
        error: {},
        // submit:false
      });
      this.props.SendAlert("50", Translate("alert", "refundaddfail"), "a02");
    }
    if (
      this.isExternCity &&
      this.props.GetingWilaya === "1" &&
      Array.isArray(dataWilaya)
    ) {
      let wilaya = dataWilaya.find((elem) => elem[1] === this.isExternCity);
      this.handleSelect(wilaya, "city");
      this.isExternCity = null;
    }
    if (
      this.isExternCommune &&
      this.props.GetigCommune === "1" &&
      Array.isArray(DataCommune)
    ) {
      let commune = DataCommune.find(
        (elem) => elem.id === this.isExternCommune
      );
      this.handleSelect(commune, "district");
      this.isExternCommune = null;
    }
    if (!this.state.permmisionadd && !this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "addneworder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    if (!this.state.permmisionedit && this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "editorder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    return (
      <ContainerPage page_title={this.state.titlepage}>
        {RefundAdded === "1" && <Loader />}
        <div className="ClSidTh StBrdTb StBgbrds widthAdd">
          <div className="FlWd TxtCn StRedClr">{Alertfail}</div>
          <form method="post" onSubmit={this.handleSubmit}>
            <ContainerPage subContainer page_title={""} small_title={true}>
              {Array.isArray(this.state.orderproducts) &&
                this.state.orderproducts.map((product, key) => {
                  return (
                    <div key={key}>
                      <div className="FlWd">
                        <div
                          style={{ flexGrow: "1" }}
                          className="InFlx AlgnItm flxDrc"
                        >
                          <div
                            style={{ flexGrow: "0.02" }}
                            className="FlHg InFlx AlgnItm flxDrc"
                          >
                            <h2 className="StOpcVal"># {key + 1}</h2>
                          </div>
                          {product.picture && (
                            <div
                              style={{ flexGrow: "0.18" }}
                              className="FlHg InFlx AlgnItm"
                            ></div>
                          )}
                          <div
                            style={{ flexGrow: "0.8" }}
                            className="FlHg InFlx flxDrc"
                          >
                            <div
                              className={
                                "FlHg FlWd Stclmnf " + lang === "ar"
                                  ? "StFlxStr"
                                  : ""
                              }
                            >
                              <div className="InFlx flxDrc">
                                <h2 className="DlMg">
                                  {" "}
                                  {product.logistical_description}
                                </h2>
                              </div>
                              <div className="InFlx flxDrc">
                                <p className=" DlMg StOpcVal">
                                  {Translate("exchanges", "deliveredqty")} :{" "}
                                  {product.quantity}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="FlWd InFlx AlgnItm flxDrc">
                            <div className="Hlwd">
                              <GroupeForm
                                id={"quantity_" + (key + 1)}
                                name={"quantity_" + (key + 1)}
                                placeholder={Translate(
                                  "exchanges",
                                  "refundquantity"
                                )}
                                text={Translate("exchanges", "refundquantity")}
                                type={"number"}
                                min={0}
                                max={product.quantity}
                                defaultValue={0}
                                workfun={this.handleChangeProduct}
                              />
                            </div>
                            <div className="Hlwd FlHg Mrg2 RlPs">
                              <div className="FlWd FlHg InFlx AlgnItm Stclmnf">
                                <div className="FlWd InFlx flxDrc SmMarS">
                                  <p className="StSizLn DlMg">
                                    {Translate("exchanges", "productstat")}
                                  </p>
                                </div>
                                <div className="InFlx AlgnItm flxDrc">
                                  <div
                                    style={{ flexGrow: "0.2" }}
                                    className="FlHg InFlx flxDrc"
                                  >
                                    <ToggleSwitch
                                      toggleHandler={() =>
                                        this.setProductDefective(product, key)
                                      }
                                    />
                                  </div>

                                  <div
                                    style={{ flexGrow: "0.8" }}
                                    className="FlHg InFlx flxDrc"
                                  >
                                    <div
                                      className={
                                        "FlHg FlWd Stclmnf " + lang === "ar"
                                          ? "StFlxStr"
                                          : ""
                                      }
                                    >
                                      <div className="InFlx flxDrc">
                                        <b>
                                          {" "}
                                          {Translate(
                                            "exchanges",
                                            "productdefective"
                                          )}
                                        </b>
                                      </div>

                                      <p className="DlMg StOpcVal">
                                        {Translate(
                                          "exchanges",
                                          "productdefectiveseparat"
                                        )}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </ContainerPage>
            {/* <ContainerPage
              
            >
              {this.state.extraproducts.length===0 && 
              <div className="FlWd StdirLn">
                <span className="StBle CrsPoi" onClick={()=>this.AddExtraFieldProduct()}>
                  <div className={"StMrtg2 StHgVls InFlx AlgnItm StBlFil StSmallSvg"}>
                      <p>{" " + Translate("exchanges", "sellanotherproduct")}</p> {ExchangeSvg}
                  </div>
                </span>
              </div>}
              <div>
              {this.state.extraproducts.length>0  && <div className="FlWd InFlx flxDrc"> <h4>{Translate("exchanges","newproducts")}</h4></div>}
              {this.state.extraproducts.length>0 && this.state.extraproducts.map((el,idx)=>(
              <div className="FlWd InFlx Stclmnf">

                <div className="FlWd InFlx flxDrc">

                      <div className="Hlwd">
                        <SelectFormSearch
                            id={"extraproductname_" + (idx + 1)}
                            name={"extraproductname_" + (idx + 1)}
                            index={"logistical_description"}
                            option={this.state.productnames}
                            text={Translate("product", "productname")}
                            workfun={this.handleReplaceProduct}
                            placeholder={
                            ProductFinded === "2"
                            ? Translate("product", "productname")
                            : Translate("alert", "load")
                          }
                            value={
                              this.state.extraproducts[idx].logistical_description
                            }
                        />
                            
                      </div>
                      <div className="Hlwd Mrg2 RlPs">
                            <div className="Flwd InFlx flxDrc AlgnItm">
                                <GroupeForm
                                id={"extraquantity_" + (idx + 1)}
                                name={"extraquantity_" + (idx + 1)}
                                placeholder={Translate("orders", "quantity")}
                                text={Translate("orders", "quantity")}
                                type={"number"}
                                value={this.state.extraproducts[idx].quantity}
                                workfun={this.handleReplaceProduct}
                                style={{width:"80%"}}
                                />
                              <div className="CrsPoi InFlx JstfCnt" style={{alignItems: "flex-end",margin: "5% 0% 0% 2%"}} onClick={()=>this.RemoveExtraFieldProduct(idx)}>{CloseSvg}</div>

                            </div>
                        
                      </div>
                  </div>
                
                  <div className="FlWd InFlx flxDrc">
                    
                      <div
                        className="StRedClr CrsPoi"
                        onClick={() => this.RemoveExtraFieldProduct(idx)}
                      >
                        - {" " + Translate("orders", "removeproduct")}
                      </div>
                            
                      <span>{this.state.error.erproduct}</span>
                      {this.state.notfound ? (
                        <div className="FlWd StdirLn">
                          <span className="CrsPoi">
                            {" " + Translate("product", "prdntfond")}
                          </span>
                          <Link to="/products/add" className="CrsPoi StBle">
                            {" " + Translate("product", "addnewproduct")}
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      
                  </div>
              </div>  
              ))
              }
                
              </div>


            </ContainerPage> */}
            <ContainerPage
              subContainer
              page_title={Translate("orders", "clientinfo")}
              small_title={true}
            >
              <div className="FlWd InFlx flxDrc">
                <div className="Hlwd">
                  <GroupeForm
                    id={"customername"}
                    name={"customername"}
                    placeholder={Translate("orders", "customername")}
                    text={Translate("orders", "customername")}
                    type={"text"}
                    value={this.state.customername}
                    workfun={this.handleChange}
                    error={this.state.error.ername}
                  />
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm
                    id={"customerphonenumber"}
                    name={"customerphonenumber"}
                    placeholder={Translate("orders", "customerphonenumber")}
                    text={Translate("orders", "customerphonenumber")}
                    type={"tel"}
                    pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
                    value={this.state.customerphonenumber}
                    workfun={this.handleChange}
                    error={this.state.error.erphone}
                  />
                </div>
              </div>
            </ContainerPage>
            <ContainerPage
              subContainer
              withOvrl={true}
              page_title={Translate("orders", "deliverydetails")}
              small_title={true}
            >
              {this.props.dtStoreinfo?.country?.id !== 2 && (
                <div
                  className="FlWd InFlx responsFlx flxDrc"
                  style={{ height: "100%", marginBottom: "15px" }}
                >
                  <div className="Hlwd">
                    {/* stop-desk option toggle */}
                    <div
                      className="FlWd InFlx FrInp"
                      style={{ borderRadius: "50px" }}
                    >
                      <SwitchOptionButton
                        optionId={1}
                        currentOption={this.state.selectedOption}
                        onOptionChange={() =>
                          this.setState({ selectedOption: 1 })
                        }
                      />
                      <SwitchOptionButton
                        optionId={2}
                        currentOption={this.state.selectedOption}
                        onOptionChange={() =>
                          this.setState({ selectedOption: 2 })
                        }
                      />
                    </div>
                  </div>
                  <div className="Hlwd Mrg2">
                    {/* free delivery spot upcoming... */}
                  </div>
                </div>
              )}
              <div
                className="FlWd InFlx responsFlx flxDrc"
                style={{ height: "100%" }}
              >
                <div className="Hlwd">
                  {this.state.selectedOption === 2 &&
                  this.props.dtStoreinfo?.country?.id !== 2 ? (
                    <div className="FlWd" style={{ marginBottom: "15px" }}>
                      <Select
                        label={Translate("orders", "stpdsk")}
                        placholder={Translate("orders", "stpdsk")}
                        search={true}
                        Options={
                          this.props.dataStopDeskWilaya
                            ? this.props.dataStopDeskWilaya
                            : []
                        }
                        fieldShow={lang === "ar" ? "name_ar" : "name_lt"}
                        name="stopdesk"
                        loading={this.props.GetingSDWilayas === "0"}
                        value={this.state.stopDesk}
                        onChange={this.handleStopDeskSelect}
                        maxSize="200px"
                        disabled={true}
                        containerClass={
                          this.state.submit &&
                          !this.state.stopDesk &&
                          this.state.selectedOption === 2
                            ? "borderError"
                            : ""
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <GroupeForm
                        id={"adresse"}
                        name={"adresse"}
                        placeholder={Translate("orders", "adresse")}
                        text={Translate("orders", "adresse")}
                        type={"text"}
                        value={this.state.adresse}
                        workfun={this.handleChange}
                        error={this.state.error.eradress}
                      />
                      <div
                        className="FlWd InFlx responsFlx flxDrc"
                        style={{ margin: "28px 0 28px 0" }}
                      >
                        <div className="Hlwd">
                          <Select
                            label={Translate("orders", "city")}
                            placholder={Translate("orders", "citySearch")}
                            search={true}
                            Options={dataWilaya}
                            fieldShow={1}
                            name="city"
                            loading={GetingWilaya === "0"}
                            value={this.state.city}
                            onChange={this.handleSelect}
                            maxSize="200px"
                          />
                        </div>
                        <div className="Hlwd Mrg2">
                          <Select
                            label={Translate("orders", "district")}
                            placholder={Translate("orders", "districtSearch")}
                            search={true}
                            Options={DataCommune}
                            fieldShow={"name"}
                            name="district"
                            loading={GetigCommune === "0"}
                            value={this.state.district}
                            onChange={this.handleSelect}
                            maxSize="200px"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <GroupeForm
                    id={"orderprice"}
                    name={"orderprice"}
                    placeholder={Translate("exchanges", "amounthanded")}
                    text={Translate("exchanges", "amounthanded")}
                    type={"text"}
                    value={this.state.orderprice}
                    workfun={this.handleChange}
                    error={this.state.error.erprice}
                    stclass={this.state.error?.erprice ? "borderError" : ""}
                  />
                </div>
                <div className="Hlwd Mrg2">
                  {this.state.selectedOption === 1 && (
                    <GroupeForm
                      id={"notetodriver"}
                      name={"notetodriver"}
                      placeholder={Translate("orders", "notetodriver")}
                      text={Translate("orders", "notetodriver")}
                      type={"text"}
                      value={this.state.notetodriver}
                      workfun={this.handleChange}
                      textarea={true}
                    />
                  )}
                </div>
              </div>
            </ContainerPage>
            <span
              className="DsBlc FlWd"
              style={{
                height: "1px",
                backgroundColor: "var(--fntClr)",
                opacity: ".2",
              }}
            ></span>
            <div className="FlWd TxtCn StRedClr">
              {this.state.city &&
                !this.state.city.id &&
                this.state.city.name &&
                Translate("error", "Order out of our service")}
            </div>
            <div className="StMarMx stbold">
              <div className="PsinLan SmMarS">
                <span>{Translate("orders", "deliveryprice")}</span>
                <span> ({Translate("exchanges", "extracharge")}) </span>
                <span>:</span>
                <span className="Mrg2">
                  {this.props.GetingDelivery === "1" ? (
                    <span className="Mrg2">{Translate("alert", "load")}</span>
                  ) : (
                    <span className="Mrg2">
                      {lang == "ar"
                        ? Number(this.state.delivryprice) +
                          "   " +
                          this.props.dtStoreinfo.country.ar_currency
                        : Number(this.state.delivryprice) +
                          "   " +
                          this.props.dtStoreinfo.country.lt_currency}
                    </span>
                  )}
                </span>
              </div>
              <div className="PsinLan SmMarS">
                <span>{Translate("exchanges", "amounthanded")}</span>
                <span> ({Translate("exchanges", "extracharge")}) </span>
                <span>:</span>
                <span className="Mrg2">
                  {this.props.GetingDelivery === "1" ? (
                    <span className="Mrg2">{Translate("alert", "load")}</span>
                  ) : (
                    <span className="Mrg2">
                      {lang == "ar"
                        ? this.props.dtStoreinfo?.country?.id === 2
                          ? Number(this.state.orderprice).toFixed(2) +
                            "   " +
                            this.props.dtStoreinfo.country.ar_currency
                          : Number(this.state.orderprice) +
                            "   " +
                            this.props.dtStoreinfo.country.ar_currency
                        : this.props.dtStoreinfo?.country?.id === 2
                        ? Number(this.state.orderprice).toFixed(2) +
                          "   " +
                          this.props.dtStoreinfo.country.lt_currency
                        : Number(this.state.orderprice) +
                          "   " +
                          this.props.dtStoreinfo.country.lt_currency}
                    </span>
                  )}
                </span>
              </div>
            </div>

            <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
              {this.props.GetingDelivery === "1" ? (
                <Button gray disabled BtnText={Translate("alert", "load")} />
              ) : Number(this.state.totalprice) < 0 ? (
                <Button danger disabled BtnText={Translate("error", "eo6")} />
              ) : (
                <Button BtnText={this.state.titlebtn} />
              )}
            </div>
          </form>
        </div>
      </ContainerPage>
    );
  }
  $_GET(param) {
    var vars = {};
    window.location.href
      .replace(window.location.hash, "")
      .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
        vars[key] = value !== undefined ? value : "";
      });
    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const {
    dataStopDeskWilaya,
    GetingSDWilayas,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GetingDelivery,
    dataDelivery,
    dataCountry,
    GetingPays,
  } = state.common;
  const { ProductFinded, data } = state.product;
  const { OrderGeting, OrdersList } = state.orders;
  const { RefundAdded, dataAddRefund } = state.exchanges;

  const { DataSearch, Searching } = state.search;
  const { dtStoreinfo, dataProfile } = state.user;
  return {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    data,
    ProductFinded,
    RefundAdded,
    dataAddRefund,
    GetingDelivery,
    dataDelivery,
    OrderGeting,
    OrdersList,
    DataSearch,
    Searching,
    dtStoreinfo,
    dataProfile,
    dataCountry,
    GetingPays,
    GetingSDWilayas,
    dataStopDeskWilaya,
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetAllProduct: productActions.GetAllProduct,
  GetDelivery: commonActions.GetDelivery,
  AddRefund: exchangeActions.AddRefund,
  GetOrders: ordersActions.GetOrders,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
  GetCountry: commonActions.GetCountry,
  GetStopdeskWilayas: commonActions.GetStopDeskWilayas,
};

const connectedRefundPage = connect(mapState, actionCreators)(RefundPage);
export { connectedRefundPage as RefundPage };
