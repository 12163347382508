export const commonConstants = {
  GET_WILAYAS_SUCCESS: "GET_WILAYAS_SUCCESS",
  GET_WILAYAS_FAILURE: "GET_WILAYAS_FAILURE",
  GET_WILAYAS_REQUEST: "GET_WILAYAS_REQUEST",

  GET_STOP_DESK_WILAYAS_SUCCESS: "GET_STOP_DESK_WILAYAS_SUCCESS",
  GET_STOP_DESK_WILAYAS_FAILURE: "GET_STOP_DESK_WILAYAS_FAILURE",
  GET_STOP_DESK_WILAYAS_REQUEST: "GET_STOP_DESK_WILAYAS_REQUEST",

  GET_COMMUNE_SUCCESS: "GET_COMMUNE_SUCCESS",
  GET_COMMUNE_FAILURE: "GET_COMMUNE_FAILURE",
  GET_COMMUNE_REQUEST: "GET_COMMUNE_REQUEST",

  GET_WAREHOUSE_SUCCESS: "GET_WAREHOUSE_SUCCESS",
  GET_WAREHOUSE_FAILURE: "GET_WAREHOUSE_FAILURE",
  GET_WAREHOUSE_REQUEST: "GET_WAREHOUSE_REQUEST",

  GET_VEHICULETYPES_SUCCESS: "GET_VEHICULETYPES_SUCCESS",
  GET_VEHICULETYPES_FAILURE: "GET_VEHICULETYPES_FAILURE",
  GET_VEHICULETYPES_REQUEST: "GET_VEHICULETYPES_REQUEST",

  GET_DELIVERY_SUCCESS: "GET_DELIVERY_SUCCESS",
  GET_DELIVERY_FAILURE: "GET_DELIVERY_FAILURE",
  GET_DELIVERY_REQUEST: "GET_DELIVERY_REQUEST",

  GET_COUNTRY_REQUEST: "GET_COUNTRY_REQUEST",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",

  GET_INFO_REQUEST: "GET_INFO_REQUEST",
  GET_INFO_SUCCESS: "GET_INFO_SUCCESS",
  GET_INFO_FAILURE: "GET_INFO_FAILURE",
};
