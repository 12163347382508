import ReactGA from "react-ga4";

var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
export const initGAPg = () => {
  ReactGA.initialize(
    process.env.REACT_APP_STATE !== "prod" ? "G-7B2KXEKK29" : "G-EVV13KHV1F"
  );

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};
export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
