import React from 'react'

const CloseSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#008AFF"
        d="M12.667 4.274l-.94-.94L8 7.061 4.274 3.334l-.94.94L7.06 8.001l-3.726 3.726.94.94L8 8.941l3.727 3.726.94-.94L8.94 8.001l3.727-3.727z"
      ></path>
    </svg>
  )
}

export default CloseSvg