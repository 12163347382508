import React, { useState, useEffect, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Translate } from "../../../../utils/lang/translate";
import GroupeBtnSp from "../../_Common/GroupeBtnSp";
import { DeletSvg, EditSvg, FlechSvg, CallSvg } from "../../_Common/IconSvg";
import InlinedElement from "../../_Common/InlinedElement";
import Button from "../../../_Common/Button";
import GroupeForm from "../../../_Common/GroupeForm";
import { userActions, alertActions } from "../../../../actions";
import Loader from "../../../_Common/Loader";
import { ViewteamLoder } from "./TeamLoader";
import { Link, RouteComponentProps, useParams } from "react-router-dom";
import { initGAPg, Event } from "../../_Common";

type Params = {
  idteam: string;
};
type Props = {
  team: string;
  phone: string;
};
interface ViewTeamProps {
  DeletingTeam: string;
  ListMemberTeam: any;
  history: any;
  InvitingMember: string;
  SendAlert: (id: string, message: string) => void;
  GetingDTeam: string;
  dataDListTeam: any;
  InvitMember: (inv: { team: string; phone: string }) => void;
  DeleteTeam: (idTeam: string) => void;
}

const ViewTeam: React.FC<ViewTeamProps> = (props: ViewTeamProps) => {
  const {
    ListMemberTeam,
    DeletingTeam,
    history,
    InvitingMember,
    SendAlert,
    GetingDTeam,
    dataDListTeam,
    InvitMember,
    DeleteTeam,
  } = props;
  const { idteam } = useParams<Params>();
  const [phoneInvit, setPhoneInvit] = useState<string>("");
  const [hideInput, setHideInput] = useState(10);
  const [idTeam, setIdTeam] = useState<string>(idteam);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPhoneInvit(value);
    const limitPlusDZ = 13;
    const limitPlusTN = 12;
    const limitDZ = 10;
    const StartNumberPhone = value.substring(0, 4);
    const StartNumberPhone0 = value.substring(0, 1);

    if (StartNumberPhone === "+213") {
      setHideInput(limitPlusDZ);
    }
    if (StartNumberPhone === "+216") {
      setHideInput(limitPlusTN);
    }
    if (StartNumberPhone0 === "0") {
      setHideInput(limitDZ);
    }
  };

  const invitMember = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let inv = { team: idteam, phone: phoneInvit } as Props;
    InvitMember(inv);
  };

  useEffect(() => {
    initGAPg();
    ListMemberTeam(idteam);
  }, [ListMemberTeam, idteam]);

  useEffect(() => {
    if (DeletingTeam === "1") {
      history.push("/settings/team/home");
    }
  }, [DeletingTeam, history]);

  useEffect(() => {
    if (InvitingMember === "1") {
      SendAlert("41", Translate("alert", "invsend"));
      ListMemberTeam(idteam);
    } else if (InvitingMember === "2") {
      SendAlert("50", Translate("alert", "failinv"));
    }
  }, [InvitingMember, SendAlert, ListMemberTeam, idteam]);

  const GotToEdit = (path: string) => {
    Event("TEAM_DETAIL", "USE_EDIT_TEAM_BUTTON", "CLICK_EVENT");
    history.push(path);
  };

  const DeletTeam = (idTeam: string) => {
    Event("TEAM_DETAIL", "DELET_TEAM_MEMBER", "CLICK_EVENT");
    DeleteTeam(idTeam);
  };

  return GetingDTeam === "0" ? (
    <ViewteamLoder />
  ) : (
    GetingDTeam === "1" && dataDListTeam && (
      <Fragment>
        {DeletingTeam === "0" && <Loader />}
        <div className="StAbs StLanNl DltTpMar2">
          <GroupeBtnSp
            data_btn={[
              {
                type: "BdgBlcl",
                action: () => GotToEdit("/settings/team/add/" + idTeam),
                svgbtn: EditSvg,
              },
              {
                type: "TxDng",
                action: () => DeletTeam(idTeam),
                svgbtn: DeletSvg,
              },
            ]}
          />
        </div>
        <div className="stbd2">
          <div className="InFlx flxDrc StDrcIn">
            <div className="StWdDst StPaddingVal CrsPoi RtVl">
              <Link to="/settings/team/home">{FlechSvg}</Link>
            </div>
            <div style={{ textTransform: "capitalize" }}>
              <h3 className="DlMg">{dataDListTeam.team.name}</h3>
            </div>
          </div>
        </div>
        <div className="InFlx flxDrc responsFlx Mrtpvl StFlxWrp2 RlPs">
          <div className="StWdpwrp StBrdRd SthgMaxC" style={{ zIndex: "5" }}>
            <div className="stbd2 TxtCn">
              <h3 className="DlMg">{Translate("settings", "teammembers")}</h3>
            </div>
            <div className="RlPs StDrcIn">
              {Array.isArray(dataDListTeam.members)
                ? dataDListTeam.members.map((member: any, key: number) => {
                    return (
                      <div className="RlPs SmMarS1" key={key}>
                        <InlinedElement
                          first={
                            <div className="StWdTh1">
                              <img
                                className="StWdTh1"
                                alt="team member avatar"
                                src={
                                  member.picture
                                    ? member.picture
                                    : "/assets/img/default_user.png"
                                }
                              />
                            </div>
                          }
                          secnd={
                            <div>
                              <h4 className="DlMg StdirLn TxTrnf">
                                {member.full_name}
                              </h4>
                              <span className="StdirLn DsBlc StOpcVal">
                                {member.phone}
                              </span>
                            </div>
                          }
                          style={{ width: "100%", padding: "0" }}
                        />
                      </div>
                    );
                  })
                : ""}
              {Array.isArray(dataDListTeam.invitation)
                ? dataDListTeam.invitation.map((invit: any, key: number) => {
                    return (
                      <div className="RlPs SmMarS1" key={key}>
                        <InlinedElement
                          first={
                            <div className="StWdTh1">
                              <img
                                alt="team member profile"
                                className="StWdTh1"
                                src="/assets/img/user.png"
                              />
                            </div>
                          }
                          secnd={
                            <div>
                              <h4 className="DlMg StdirLn TxTrnf">
                                {invit.phone}
                              </h4>
                              <span className="StdirLn DsBlc Bgstatus_11">
                                {Translate("statusorder", "11")}
                              </span>
                            </div>
                          }
                          style={{ width: "100%", padding: "0" }}
                        />
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
          <div className="StAbs FlWd FlHg vsep" style={{ zIndex: "0" }}>
            <span
              className="DsBlc RlPs MrAot StOpcVal"
              style={{
                width: "3px",
                height: "100%",
                backgroundColor: "var(--fntClr)",
              }}
            ></span>
          </div>
          <div
            className="StWdpwrp StBrdRd SthgMaxC RlPs"
            style={{ zIndex: "5" }}
          >
            {InvitingMember === "0" && <Loader />}
            <div className="stbd2 TxtCn">
              <h3 className="DlMg">{Translate("settings", "invitemembers")}</h3>
            </div>
            <div className="MrAot" style={{ width: "90%" }}>
              <div className="InFlx FlWd TxtCn SmMarS2">
                <div
                  className="TxtCn RlPs CrsPoi StVlwd ActelmV"
                  style={{ width: "100%" }}
                >
                  {CallSvg}
                  <div>{Translate("auths", "phone")}</div>
                </div>
              </div>
              <form onSubmit={invitMember}>
                <GroupeForm
                  id={"phone_invit"}
                  name={"phone_invit"}
                  placeholder={Translate("auths", "phone")}
                  type={"tel"}
                  pattern={
                    "^(((+216)?[1-9]d{7})|((0?|(00|+)?213)[56789]d{8}))$"
                  }
                  value={phoneInvit}
                  workfun={handleChange}
                  maxLength={hideInput}
                />
                <div className="MrAot StMarMx" style={{ width: "200px" }}>
                  <Button BtnText={Translate("settings", "invitemembers")} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    )
  );
};

function mapState(state: any) {
  const { GetingTeam, dataListTeam, GetingDTeam, dataDListTeam, DeletingTeam } =
    state.team;
  const { InvitingMember, dataInvitingMember } = state.memberTeam;
  return {
    GetingTeam,
    dataListTeam,
    InvitingMember,
    dataInvitingMember,
    GetingDTeam,
    dataDListTeam,
    DeletingTeam,
  };
}

const actionCreators = {
  ListMemberTeam: userActions.ListMemberTeam,
  InvitMember: userActions.InvitMember,
  DeleteTeam: userActions.DeleteTeam,
  SendAlert: alertActions.SendAlert,
};

const connectedViewTeam = connect(mapState, actionCreators)(ViewTeam);
export { connectedViewTeam as ViewTeam };
