import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("auth");
let URL_API ="https://backenddev.maystro-delivery.com/api/";

if (process.env.REACT_APP_STATE === "prod") {
  URL_API = "https://b.maystro-delivery.com/api/";
}
if (process.env.REACT_APP_STATE === "costumized") {
  console.log("you are using a costumized api address!");
  URL_API = process.env.DJANGO_BACKEND;
  console.log("URL API IS:", URL_API);
}
if (process.env.REACT_APP_STATE === "dev") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://backenddev.maystro-delivery.com/api/";
  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "dev2") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://maystro-backend-nyg42cuu7q-ew.a.run.app/api/";
  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "dev-new") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://backenddev-new.maystro-services.com/api/";
  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "developement") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://developement.backend.maystro-delivery.com/api/";
  console.log("URL API IS:", URL_API);
}
if (process.env.REACT_APP_STATE === "pre-prod") {
  URL_API = "https://backend-preprod.maystro-delivery.com/api/";
  console.log("URL API IS:", URL_API);
}
if (process.env.REACT_APP_STATE === "staging") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://backend-staging-utvw6oxohq-ew.a.run.app/api/";
  console.log("URL API IS:", URL_API);
}
if (process.env.REACT_APP_STATE === "dev-loc") {
  console.error("you are using the local server!");
  URL_API = "http://localhost:8000/api/";
  console.log("URL API IS:", URL_API);
}

export const getStoreInfo = async () => {
  let response = [];
  let error = [];
  let hasBeenFetched = false;
  try {
    response = await axios.get(`${URL_API}stores/profile/`, {
      headers: { Authorization: `token ${Cookies.get("auth")}` },
    });
    hasBeenFetched = true;
  } catch (e) {
    error = e;
  }
  return { response, error, hasBeenFetched };
};
