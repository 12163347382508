import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import "../../_Common/ShopSelectFormSearch.css";
import { Translate } from '../../../utils/lang/translate';
import ArrowSvg from "../_Common/ArrowSvg";

export default function ShopSelectFromSearchForVariants(props) {

  const [productList, setProductList] = useState(props.option)
  const [isFocused, setIsFocused] = useState(false); // State to track input focus
  const inputRef = useRef(null);

  const toggleFocus = () => {
    if (inputRef.current) {
      !isFocused ? inputRef.current.focus() : inputRef.current.blur();
      setIsFocused(!isFocused);
    }
  };

  const handleClickOutside = (event) => {
    if (!inputRef.current) return;

    const isInsideInput = inputRef.current.contains(event.target);
    const isInsideSelectArrow = event.target.classList.contains('select-arrow');
    const isInsideSvg = document.querySelector('.select-arrow svg')?.contains(event.target);

    if (!isInsideInput && !isInsideSelectArrow && !isInsideSvg) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); 


  useEffect(()=>{
    const filtredProductList = props.option?.filter((prod) =>
            props.variants.every((variant) => variant.product !== prod.id ) 
          ) 

    setProductList(filtredProductList)
  }
    ,[props.options,props.variants])
 
  return (
    <div className="ShopSelectFormSearch grp-fm StdirLn StFnt">
      <div className="SsGrpRm RlPs">
        <label htmlFor={props.id}>
          {props.text}
          {props.optional && <span> ({Translate("shop", "optional")})</span>}
        </label>
        <div className="StAbs StLanNl StTpNl StOpcVal">{props.more}</div>
      </div>
      <div className="SsGrpRm StMrg RlPs">
        <Fragment>
          <input
            ref={inputRef}
            list={props.id + "search"}
            autoComplete="off"
            value={props.value}
            id={props.id}
            name={props.name}
            className={
              "lytwdp2p Fntcl StdirLn FrInp StSizLn StHgInp StBrdRd StOpcVal FntfMin " +
              (props.stclass ? props.stclass : "")
            }
            data_key=""
            placeholder={
              props.loading ? Translate("alert", "load") : props.placeholder
            }
            onChange={props.workfun}
            onFocus={()=>setIsFocused(true)}
          />
          {!props.loading && (
            <DataList
              value={props.value}
              list={productList}
              workfun={props.workfun}
              index={props?.index}
            />
          )}
        </Fragment>
        <button className="select-arrow" onClick={toggleFocus}>
          <ArrowSvg />
        </button>
        <span className="StRedClr">{props.error}</span>
      </div>
    </div>
  );
}

function DataList({ value, list, workfun, index }) {
  const [filteredList, setFilteredList] = useState([]);
  const val = useMemo(() => value ?? "", [value]);


  useEffect(() => {
    if (Array.isArray(list))
      setFilteredList(
        list.filter((item) =>
          item[index]?.toLowerCase().includes(val.toLowerCase())
        )
      );
  }, [val, list]);

  return (
    <div className="DataList">
      {filteredList.map((item) => {
        const value = item[index];
        return (
          <div
            className="DataList-option"
            key={item?.id}
            onClick={() => {
              const pseudoEvent = { target: { value } };
              workfun(pseudoEvent);
              setFilteredList([]);
            }}
          >
            {value}
          </div>
        );
      })}
    </div>
  );
}

