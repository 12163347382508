import React, { useState, useEffect, useRef } from "react";
import GroupeForm from "../../../_Common/GroupeForm";
import Button from "../../../_Common/Button";
import { Translate } from "../../../../utils/lang/translate";
import {
  DataShop,
  FacebookPixel,
  UpdateDataShopSettings,
} from "../../../../types";
import useExitPrompt from "../../../../utils/useExitPrompt";
import { Prompt } from "react-router-dom";
import { Event } from "../../_Common";
import { AddSvg, FlechDwnSvg } from "../../_Common/IconSvg";

interface Props {
  dataShop: DataShop;
  history: any;
  UpdateDataShop: (
    data: DataShop,
    settings: UpdateDataShopSettings,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

interface FacebookPixelState {
  label: string;
  color: string;
}
interface FacebookPixelAction {
  state: FacebookPixelState;
  onAction: () => void;
}
interface FacebookPixelActionDropDownProps {
  value: FacebookPixelState;
  actions: (FacebookPixelAction | "seperator")[];
}

const FacebookPixelActionDropDown = ({
  actions,
  value,
}: FacebookPixelActionDropDownProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const dropDown = useRef<HTMLDivElement>(null);

  const toggleDropDown = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault();
    // e && e.stopPropagation();
    setIsCollapsed((prev) => !prev);
  };

  useEffect(() => {
    const callback = (e: MouseEvent) => {
      if (dropDown.current && dropDown.current.contains(e.target as Node)) {
        return;
      }
      setIsCollapsed(true);
    };
    window.addEventListener("click", callback);

    return () => {
      window.removeEventListener("click", callback);
    };
  }, []);

  return (
    <div className="facebook-pixel-drop-down-root">
      <div className="facebook-pixel-status-drop-down" ref={dropDown}>
        <Button
          BtnText={value.label}
          style={{ backgroundColor: value.color }}
          suffix={FlechDwnSvg}
          onClick={toggleDropDown}
        />
        {!isCollapsed && (
          <div className="facebook-pixel-actions">
            {actions.map((action) => {
              if (action === "seperator") {
                return <span className="seperator"></span>;
              }

              return (
                <div
                  className="facebook-pixel-action"
                  style={{ color: action.state.color }}
                  onClick={() => {
                    action.onAction();
                    toggleDropDown();
                  }}
                  key={action.state.label}
                >
                  {action.state.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const FACEBOOK_PIXEL_STATES = {
  Active: { label: "Active", color: "#2BC194" },
  Inactive: { label: "Inactive", color: "#C18E2B" },
  Delete: { label: "Delete", color: "#DE1818" },
};

interface FacebookPixelWithKey {
  key: string;
  pixel: FacebookPixel;
}

const FacebookSettingsForm: React.FC<Props> = ({
  dataShop,
  history,
  UpdateDataShop,
  SendAlert,
}) => {
  const [pixels, setPixels] = useState<FacebookPixelWithKey[]>([
    { key: "0", pixel: { pixel_id: "", active: true } },
  ]);
  const [isChange, setIsChange] = useExitPrompt(false);

  useEffect(() => {
    return () => {
      setIsChange(false);
    };
  }, []);

  useEffect(() => {
    dataShop &&
      dataShop.analytics_data &&
      setPixels(
        Array.isArray(dataShop.analytics_data.fb_pixels) &&
          dataShop.analytics_data.fb_pixels.length > 0
          ? dataShop.analytics_data.fb_pixels.map((fp) => ({
              key: fp.pixel_id,
              pixel: { ...fp },
            }))
          : [{ key: "0", pixel: { pixel_id: "", active: true } }]
      );
  }, [dataShop]);

  const handleChange: (
    pixelIndex: number
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void =
    (pixelIndex) => (event) => {
      const { value } = event.target as HTMLInputElement;
      const pixel = pixels.at(pixelIndex)?.pixel;
      if (!pixel) {
        return;
      }
      pixel.pixel_id = value;
      setPixels((prev) => [...prev]);
      setIsChange(true);
    };

  const setPixelActiveState = (index: number, isActive: boolean) => {
    const updatedPixels = [...pixels];
    if (!updatedPixels.at(index)) {
      return;
    }

    updatedPixels[index].pixel.active = isActive;
    setPixels(updatedPixels);
  };

  const removePixel = (index: number) => {
    if (pixels.length === 1) {
      setPixels([{ key: "0", pixel: { pixel_id: "", active: true } }]);
      return;
    }
    const leftPixels = [...pixels];
    leftPixels.splice(index, 1);
    setPixels(leftPixels);
  };

  const newPixel = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const newPixel: FacebookPixelWithKey = {
      key: `${pixels.length}`,
      pixel: { pixel_id: "", active: true },
    };
    setPixels((prev) => [...prev, newPixel]);
  };

  const handleSkip = () => {
    Event("SHOP", "META_KEY_SKIP", "CLICK_EVENT");
    UpdateDataShop(
      dataShop,
      {
        settings_stage:
          dataShop.settings_stage <= 3 ? 4 : dataShop.settings_stage,
      },
      () => {
        history.push("/shop/settings/finish");
      }
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    Event("SHOP", "META_KEY_SAVE", "CLICK_EVENT");
    e.preventDefault();
    UpdateDataShop(
      dataShop,
      {
        analytics_data: {
          fb_pixels: pixels.map((p) => p.pixel).filter((p) => !!p.pixel_id),
        },
        settings_stage:
          dataShop.settings_stage <= 3 ? 4 : dataShop.settings_stage,
      },
      () => {
        if (!dataShop.published) history.push("/shop/settings/finish");
        else SendAlert("41", Translate("shop", "changesSaved"));
      }
    );
  };

  return (
    <>
      <Prompt when={isChange} message={Translate("alert", "prompt")} />
      <form
        className="FacebookSettingsForm"
        method="post"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {pixels.map(({ key, pixel }, index) => {
          return (
            <div key={key} className="facebook-pixel-container">
              <GroupeForm
                id={`pixel_key_${pixel.pixel_id}`}
                name={`pixel_key_${pixel.pixel_id}`}
                placeholder={Translate("shop", "facebookKeyPlaceholder")}
                text={Translate("shop", "pixel_key")}
                type={"pixel_key"}
                value={pixel.pixel_id}
                workfun={handleChange(index)}
              />
              <GroupeForm text="Status">
                <FacebookPixelActionDropDown
                  value={
                    pixel.active
                      ? FACEBOOK_PIXEL_STATES.Active
                      : FACEBOOK_PIXEL_STATES.Inactive
                  }
                  actions={[
                    {
                      state: FACEBOOK_PIXEL_STATES.Active,
                      onAction: () => setPixelActiveState(index, true),
                    },
                    {
                      state: FACEBOOK_PIXEL_STATES.Inactive,
                      onAction: () => setPixelActiveState(index, false),
                    },
                    "seperator",
                    {
                      state: FACEBOOK_PIXEL_STATES.Delete,
                      onAction: () => removePixel(index),
                    },
                  ]}
                />
              </GroupeForm>
            </div>
          );
        })}
        <div className="new-pixel-control">
          <a href="#" className="link" onClick={newPixel}>
            {"+ " + Translate("shop", "addNewPixelKey")}
          </a>
        </div>
        <div className="buttons">
          <a
            href="https://www.facebook.com/business/help/952192354843755?id=1205376682832142"
            target="_blank"
            className="link"
          >
            {Translate("shop", "getFacebookKey")}
          </a>
          {!dataShop.published && (
            <Button
              BtnText={Translate("shop", "skip")}
              onClick={handleSkip}
              type="button"
              gray
            />
          )}
          <Button
            BtnText={Translate(
              "shop",
              !dataShop.published ? "addPixel" : "save"
            )}
            disabled={
              !dataShop.published &&
              pixels.filter((p) => !!p.pixel.pixel_id).length < 1
            }
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default FacebookSettingsForm;
