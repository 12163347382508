import React from 'react'

const PlusSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g clipPath="url(#clip0_342_13197)">
        <g clipPath="url(#clip1_342_13197)">
          <path
            fill="#fff"
            d="M17.25 10.179h-7.071v7.071H7.82v-7.071H.75V7.82h7.071V.75h2.358v7.071h7.071v2.358z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_342_13197">
          <path fill="#fff" d="M0 0H18V18H0z"></path>
        </clipPath>
        <clipPath id="clip1_342_13197">
          <path fill="#fff" d="M0 0H18V18H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default PlusSvg