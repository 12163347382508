import React, { Component } from "react";
import Cropper from "react-easy-crop";
import { connect } from "react-redux";
import { Translate } from "../../utils/lang/translate";
import TabShow from "../StorePages/_Common/TabShow";
import BtnLg from "../StorePages/_Common/BtnLg";
import { userActions } from "../../actions";
import Loader from "./Loader";
class ImgCropper extends Component {
  state = {
    imageSrc: null,
    nameImg: "",
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 9 / 9,
    croppedAreaPixels: null,
    croppedArea: null,
  };
  componentWillReceiveProps(nextProps) {
    const { UpdatingPicture, dataPicture, UpdatingPic } = nextProps;
    if (
      (UpdatingPicture === "1" && this.props.UpdatingPicture === "0") ||
      (UpdatingPic === "1" && this.props.UpdatingPic === "0")
    ) {
      if (dataPicture && dataPicture["successful operation"]) {
        this.setState({
          imageSrc: null,
        });
        this.props.GetProfileUser();
      }
    }
  }
  onCropChange = (crop) => {
    this.setState({ crop });
  };
  createImage(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });
  }
  async getCroppedImg(imageSrc, pixelCrop) {
    const image = await this.createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, "image/jpeg");
    });
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels: croppedAreaPixels,
      croppedArea: croppedArea,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  onChange(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        imageSrc: reader.result,
        nameImg: files[0].name,
      });
    };
    reader.readAsDataURL(files[0]);
  }

  closeOpt() {
    this.setState({
      imageSrc: null,
    });
  }
  async MoveFile() {
    var blob = await this.getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels
    );
    var formdata = new FormData();
    formdata.append("profile_picture", blob, this.state.nameImg);
    if (this.props.fromSrc) {
      this.props.UpdatePicture(formdata);
    } else {
      this.props.UpdatePictureUser(formdata);
    }
  }
  render() {
    const { UpdatingPicture, UpdatingPic } = this.props;
    return (
      <div>
        <label htmlFor="ImgCrop" className="CrsPoi blueapp">
          {this.props.namebtn}
        </label>
        <input
          type="file"
          onChange={(e) => this.onChange(e)}
          className="hidElem"
          id="ImgCrop"
          name={this.props.name}
        />
        {this.state.imageSrc && (
          <TabShow tab_title={this.props.tabtitle}>
            {(UpdatingPicture === "0" || UpdatingPic === "0") && <Loader />}
            <div className="MrAot RlPs" style={{ height: "330px" }}>
              <Cropper
                image={this.state.imageSrc}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                cropShape={"round"}
              />
            </div>
            <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
              <div
                className="StwdMaxC CrsPoi StWdDst"
                style={{ height: "30px" }}
                onClick={() => this.closeOpt()}
              >
                <BtnLg text={Translate("profile", "cancel")} classSet={""} />
              </div>
              <div
                className="StwdMaxC CrsPoi StWdDst"
                style={{ height: "30px" }}
                onClick={this.MoveFile.bind(this)}
              >
                <BtnLg text={Translate("orders", "save")} classSet={"TxtScc"} />
              </div>
            </div>
          </TabShow>
        )}
      </div>
    );
  }
}
function mapState(state) {
  const { UpdatingPicture, dataPicture, UpdatingPic } = state.user;
  return { UpdatingPicture, dataPicture, UpdatingPic };
}

const actionCreators = {
  UpdatePicture: userActions.UpdatePicture,
  UpdatePictureUser: userActions.UpdatePictureUser,
  GetProfileUser: userActions.GetProfileUser,
};
const connectedImgCropper = connect(mapState, actionCreators)(ImgCropper);
export { connectedImgCropper as ImgCropper };
