import React from "react";
import { Translate } from "../../../utils/lang/translate";
import {
  CheckSvg2,
  DeletSvg,
  CallBackSvg,
  PostponeSvg,
} from "../_Common/IconSvg";
import "./UnfulfilledOrdersDashboard.scss";

const UnfulfilledOrdersDashboard = ({
  statusCounts,
}: {
  statusCounts?: {
    callback_count: number;
    postponed_count: number;
    approved_order: number;
    canceled_orders: number;
  };
}) => {
  return (
    <div className="UnfulfilledOrdersDashboard">
      <div className="container approved-orders">
        <div className="score">
          <p>{Translate("shop", "approvedOrders")}</p>
          <h3>{statusCounts?.approved_order}</h3>
        </div>
        <div className="icon">{CheckSvg2}</div>
      </div>
      <div className="container cancelled-orders">
        <div className="score">
          <p>{Translate("shop", "canceledOrders")}</p>
          <h3>{statusCounts?.canceled_orders}</h3>
        </div>
        <div className="icon">{DeletSvg}</div>
      </div>
      <div className="container callback-orders">
        <div className="score">
          <p>{Translate("shop", "callbackOrders")}</p>
          <h3>{statusCounts?.callback_count}</h3>
        </div>
        <div className="icon">{CallBackSvg}</div>
      </div>
      <div className="container postponed-orders">
        <div className="score">
          <p>{Translate("shop", "postponedOrders")}</p>
          <h3>{statusCounts?.postponed_count}</h3>
        </div>
        <div className="icon">{PostponeSvg}</div>
      </div>
    </div>
  );
};

export default UnfulfilledOrdersDashboard;
