import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ContainerPage from "../_Common/ContainerPage";
import Table from "../_Common/Table";
import GroupeBtn from "../_Common/GroupeBtn";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import { AddProduct } from "./AddProduct";
import { ProductAvail } from "./ProductAvail";
import { Switch, Route } from "react-router-dom";
import { Translate } from "../../../utils/lang/translate";
import {
  DeletSvg,
  ViewSvg,
  AddProductSvg,
  RefreshSvg,
  MoreHSvg,
  OutSvg,
  CheckSvg,
} from "../_Common/IconSvg";
import SmallText from "../_Common/SmallText";
import PaginationBar from "../_Common/PaginationBar";
import { productActions, commonActions, alertActions } from "../../../actions";
import BtnLg from "../_Common/BtnLg";
import TableLoader from "../_Common/TableLoader";
import TabShow from "../_Common/TabShow";
import Loader from "../../_Common/Loader";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import { initGAPg, Event } from "../_Common";
import { PRODUCTS_TABLE_HEADER } from "../../constants";
import InlinedElement from "../_Common/InlinedElement";
const urlPropsQueryConfig = {
  page: { type: UrlQueryParamTypes.number },
  logistical_description: { type: UrlQueryParamTypes.string }
};

class ProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removingProductId: null,
      premmison:
        this.props.dataProfile.is_store || this.props.dataProfile.view_stock,
      logistical_description: ""
    };
    this.crntfilter = "";
    this.action = {
      next: null,
      prev: null,
      count: 0,
    };
    this.refreshPage = this.refreshPage.bind(this);
    this.Gotodetail = this.Gotodetail.bind(this);
    this.CallPropsS = this.CallPropsS.bind(this);
    this.SetLogisticalDescription = this.SetLogisticalDescription.bind(this);
    this.ChangeLogistical_description = this.ChangeLogistical_description.bind(this);
  }
  static defaultProps = {
    page: 1,
  };
  static propTypes = {
    page: PropTypes.number,
    /********************** */
    onChangePage: PropTypes.func,
    onChangeLogistical_description: PropTypes.func
  };
  componentWillReceiveProps(nextProps) {
    const { GetPrdPage, PrdPageData, ProductAdded, ProductDeleted } = nextProps;
    if (
      this.props.GetPrdPage === "0" &&
      GetPrdPage === "1" &&
      PrdPageData &&
      PrdPageData.list
    ) {
      this.action = {
        next: PrdPageData.list.next,
        prev: PrdPageData.list.previous,
        count: PrdPageData.list.count,
      };
    }
    if (
      (this.props.ProductAdded === "1" && ProductAdded === "2") ||
      (this.props.ProductDeleted === "1" && ProductDeleted === "2")
    ) {
      if (ProductDeleted === "2") {
        this.setState({ removingProductId: null });
        if (this.props.ProductFinded === "2") {
          this.props.GetAllProduct();
        }
      }
      this.exctuteFilterUrl(this.getFilterToState(), true);
    }
    if (nextProps.page !== this.props.page || this.CallProps) {
      this.CallProps = false;
      this.exctuteFilterUrl(this.getFilterToState(nextProps));
    }

  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "product");
    initGAPg();
    this.exctuteFilterUrl(this.getFilterToState(), true);
  }
  componentWillUnmount() {
    this.props.ClearStore("REMOVE_PRODUCT_PAGE");
  }

  CallPropsS() {
    this.CallProps = true;
  }

  SetLogisticalDescription(e) {
    e.preventDefault();
    Event("PRODUCTS", "FILTER_BY_LOGISTICAL_DESCRIPTION", "CLICK_EVENT");
    this.CallProps = true;
    if (this.props.logistical_description) {
      this.props.onChangeLogistical_description(undefined);
      this.setState({
        logistical_description: ""
      });
    } else {
      this.props.onChangeLogistical_description(this.state.logistical_description);
    }
  }

  ChangeLogistical_description(e) {
    const { value } = e.target;
    this.setState({
      logistical_description: value
    });
  }

  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force) {
    let prevFilter = this.crntfilter;
    if (prevFilter !== nextFilter || force) {
      this.crntfilter = nextFilter;
      this.props.GetProductPage(nextFilter);
    }
  }
  Gotodetail(id) {
    const { dtStoreinfo } = this.props;
    if (dtStoreinfo.stock_managed) {
      Event("PRODUCT", "GO_TO_PRODUCT_DETAILS_PAGE", "CLICK_EVENT");
      this.props.history.push("/products/detail/" + id);
    }
  }
  actionclick(path) {
    Event("PRODUCT", "ADD_PRODUCT_FROM_CLICK", "CLICK_EVENT");
    this.props.history.push(path);
  }
  handleDeleteProduct(id) {
    const { removingProductId } = this.state;

    if (removingProductId) {
      Event("PRODUCT", "CONFIRM_DELETING_PRODUCT", "CLICK_EVENT");
      this.props.DeletProduct(removingProductId);
    } else {
      Event("PRODUCT", "CLICK_TO_DELET_PRODUCT", "CLICK_EVENT");
      this.setState({
        removingProductId: id,
      });
    }
  }
  refreshPage() {
    Event("PRODUCT", "REFRESH_PRODUCT", "CLICK_EVENT");
    this.exctuteFilterUrl(this.getFilterToState(), true);
  }
  showOpt(name) {
    Event("PRODUCT", "CANCEL_DELETING_PRODUCT", "CLICK_EVENT");
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  }

  render() {
    const { GetPrdPage, PrdPageData, ProductDeleted, page, dtStoreinfo } =
      this.props;
    let bodytab = [];
    if (GetPrdPage === "1") {
      bodytab = PrdPageData.list.results.map((elem) => {
        let actionBtns = [];
        const {
          id,
          logistical_description,
          display_id,
          delivery_rate,
          cancelation_rate,
        } = elem;
        if (dtStoreinfo.stock_managed)
          actionBtns.push({
            type: "BDgInpc",
            action: () => this.Gotodetail(display_id),
            svgbtn: ViewSvg,
            tooltip: Translate("product", "productDetails"),
          });
          actionBtns.push({
            type: "BDgInpc",
            action: () => this.handleDeleteProduct(id),
            svgbtn: DeletSvg,
            tooltip: Translate("profile", "delete"),
          });
        return [
          display_id,
          <SmallText lengthText={70}>{logistical_description}</SmallText>,
          delivery_rate + "%",
          cancelation_rate + "%",
          this.state.premmison ? <GroupeBtn data_btn={actionBtns} /> : "",
          { type: "forindex", blocked: [4], idaction: display_id },
        ];
      });
    }
    return (
      <ContainerPage
        page_title={
          <div>
            <Link className="StOpcVal" to="/stock/receiveProduct">
              {Translate("titles", "receiveProduct")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <span> {Translate("titles", "product")}</span>
          </div>
        }
      >
        <div className="RlPs InFlx" 
          style={{ 
            zIndex: "2", 
            padding: "10px 0", 
            height: "48px",
            justifyContent: "space-between"
          }}
        >


          <form onSubmit={this.SetLogisticalDescription}>
            <div className="FlHg StBrdRdS ClSidTh">
              <InlinedElement
                style={{ padding: "0" }}
                secnd={
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      onChange={this.ChangeLogistical_description}
                      name="filter_by_logistical_description"
                      style={{ width: "300px", maxWidth: "30vw" }}
                      className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                      value={this.state.logistical_description}
                      placeholder={Translate("product", "filterbyname")}
                    />
                  </div>
                }
                leftElm={
                  <button
                    className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                    style={{ padding: "10px" }}
                  >
                    {this.props.logistical_description ? OutSvg : CheckSvg}
                  </button>
                }
              />
              </div>
          </form>

          <GroupeBtnSp
            style={{ 
              transform: "scale(1.2)", 
              display: "flex", 
              alignItems: "center",
              marginRight: "10px",
              marginLeft: 0
            }}
            data_btn={[
              { type: "BDgInpc", action: this.refreshPage, svgbtn: RefreshSvg },
              {
                type: "BDgInpc",
                action: () => 
                  this.actionclick("/products/import"),
                svgbtn: MoreHSvg,
                tooltip: Translate("orders", "importorder"),
              },
              {
                type: "BdgBlcl",
                action: () =>
                  this.actionclick(
                    "/stock/products/add?page=" + this.props.page
                  ),
                svgbtn: AddProductSvg,
              },
            ]}
          />

        </div>

        <div>
          {GetPrdPage === "0" ? (
            <TableLoader />
          ) : (
            <Fragment>
              <Table
                thead_elem={[
                  ...PRODUCTS_TABLE_HEADER,
                  this.state.premmison
                    ? Translate("product", "action").toUpperCase()
                    : "",
                ]}
                tbody_elem={bodytab}
                goTo={this.Gotodetail}
              />
              <div role="pagination" className="lytWdp SmMarS1">
                <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                  <span>
                    {Translate("orders", "showing") +
                      " " +
                      (page * 20 - 19) +
                      " - " +
                      (page * 20 < this.action.count
                        ? page * 20
                        : this.action.count) +
                      " " +
                      Translate("orders", "of") +
                      " " +
                      this.action.count}
                  </span>
                  <PaginationBar
                    className="StAutMr"
                    NbPage={Math.trunc(this.action.count / 20) + 1}
                    currentPage={parseInt(page)}
                    ChangePage={this.props.onChangePage}
                    blockNext={this.action.next}
                    blockPrev={this.action.prev}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <Switch>
          <Route
            path="/stock/products/update/:idprod"
            component={AddProduct}
            exact
          />

          <Route path="/stock/products/add" component={AddProduct} exact />
          {this.state.premmison && (
            <Route
              path="/products/availibal/:idproduct"
              component={ProductAvail}
              exact
            />
          )}
          {/*<Route path="/products/alert/:idproduct" component={ProductAlert} />
                    <Route path="/products/request/:idproduct" component={ProductTransport} />*/}
        </Switch>
        {this.state.removingProductId && (
          <TabShow
            tab_title={Translate("profile", "confirmaction")}
            wdth="550px"
          >
            {ProductDeleted === "1" && <Loader />}
            <div className="MrAot" style={{ width: "94%" }}>
              <p className="StdirLn FlWd DltTpMar">
                {Translate("product", "confrmproduct")}
              </p>
              <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                <div
                  className="StwdMaxC CrsPoi StWdDst"
                  style={{ height: "30px" }}
                  onClick={() => this.showOpt("removingProductId")}
                >
                  <BtnLg text={Translate("profile", "cancel")} classSet={""} />
                </div>
                <div
                  className="StwdMaxC CrsPoi StWdDst"
                  style={{ height: "30px" }}
                  onClick={this.handleDeleteProduct.bind(this)}
                >
                  <BtnLg
                    text={Translate("profile", "delete")}
                    classSet={"TxDng"}
                  />
                </div>
              </div>
            </div>
          </TabShow>
        )}
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { GetPrdPage, PrdPageData } = state.UnlinkProduct;
  const { ProductAdded, dataProduct, ProductFinded } = state.product;
  const { ProductDeleted, dataDelete } = state.Stock;
  const { dataProfile, dtStoreinfo } = state.user;

  return {
    GetPrdPage,
    PrdPageData,
    ProductDeleted,
    dataDelete,
    dataProfile,
    dtStoreinfo,
    ProductAdded,
    dataProduct,
    ProductFinded,
  };
}

const actionCreators = {
  GetProductPage: productActions.GetProductPage,
  GetAllProduct: productActions.GetAllProduct,
  DeletProduct: productActions.DeletProduct,
  ClearStore: commonActions.ClearStore,
  SendAlert: alertActions.SendAlert,
};

const connectedProductsPage = connect(mapState, actionCreators)(ProductsPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedProductsPage);
export { QueryProps as ProductsPage };
