import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { productActions, alertActions } from "../../../actions";
import TabShow from "../_Common/TabShow";
import GroupeForm from "../../_Common/GroupeForm";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import Loader from "../../_Common/Loader";
import { Event } from "../_Common";

interface Props {
  ProductAdded?: string;
  data?: any;
  ProductFinded?: string;
  GetAllProduct: () => void;
  AddProduct: (product: any) => void;
  SendAlert: (code: string, message: string) => void;
  match: {
    params: {
      idprod: string;
    };
  };
  history: any;
}

const AddProduct: React.FC<Props> = ({
  ProductAdded,
  data,
  ProductFinded,
  GetAllProduct,
  AddProduct,
  SendAlert,
  match,
  history,
}) => {
  const [pageTitle, setPageTitle] = useState(
    Translate("product", "addnewproduct")
  );
  const [product_name, setProduct_name] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [submited, setSubmited] = useState(true);
  const [errors, setErrors] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "product_name") {
      setProduct_name(value);
    } else if (name === "product_id") {
      setProduct_id(value);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let isValid = true;
    if (!product_name) {
      isValid = false;
      setErrors(Translate("error", "reqfield"));
    } else {
      setErrors("");
    }
    const product = {
      product_name,
      product_id,
    };
    if (isValid === true) {
      setSubmited(true);
      Event("ADD_PRODUCT", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
      AddProduct(product);
    }
  };

  useEffect(() => {
    const { params } = match;
    const { idprod } = params;
    if (idprod) GetAllProduct();
  }, [match, GetAllProduct]);

  useEffect(() => {
    const { params } = match;
    const { idprod } = params;
    if (ProductAdded === "2") {
      GetAllProduct();
      history.push("/stock/products");
    } else if (ProductAdded === "3") {
      SendAlert("50", Translate("alert", "failaddpro"));
    } else {
      if (
        ProductFinded !== "2" ||
        !data ||
        !data?.list ||
        !Array.isArray(data?.list)
      ) {
        return;
      }
      const product = data?.list?.find(
        (product: any) => product.id + "" === idprod + ""
      );
      if (product) {
        setProduct_name(product.logistical_description);
        setProduct_id(product.product_id);
        setPageTitle(Translate("product", "updateproduct"));
      } else {
        // history.push("/stock/products");
      }
    }
  }, [
    ProductAdded,
    ProductFinded,
    data,
    GetAllProduct,
    SendAlert,
    history,
    match,
  ]);

  return (
    <TabShow tab_title={pageTitle}>
      <div className="MrAot" style={{ width: "88%" }}>
        <form method="post" onSubmit={handleSubmit}>
          <GroupeForm
            id={"productname"}
            name={"product_name"}
            value={product_name}
            placeholder={Translate("product", "productname")}
            text={Translate("product", "productname")}
            type={"text"}
            workfun={handleChange}
            stclass={"ClSidTh"}
            error={errors}
          />
          <GroupeForm
            id={"productid"}
            name={"product_id"}
            value={product_id}
            placeholder={Translate("product", "productid")}
            text={Translate("product", "productid")}
            type={"text"}
            workfun={handleChange}
            stclass={"ClSidTh"}
          />
          <div className="Hlwd MrAot StMarMx StMrtp">
            <Button BtnText={Translate("product", "addprodct")} />
          </div>
        </form>
      </div>
      {ProductAdded === "1" && <Loader />}
    </TabShow>
  );
};

function mapState(state: any) {
  const { ProductAdded, dataProduct, ProductFinded, data } = state.product;
  return { ProductAdded, dataProduct, ProductFinded, data };
}

const actionCreators = {
  AddProduct: productActions.AddProduct,
  GetAllProduct: productActions.GetAllProduct,
  SendAlert: alertActions.SendAlert,
};

const connectedAddProduct = connect(mapState, actionCreators)(AddProduct);
export { connectedAddProduct as AddProduct };
