import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../_Common/Button";
import GroupeForm from "../../_Common/GroupeForm";
import { Translate } from "../../../utils/lang/translate";
import { alertActions, userActions } from "../../../actions";
import { LockSvg } from "../_Common/IconSvg";

interface LoginInfoProps {
  chngPass: string;
  chngPassData: any;
  GetingUser: string;
  dataProfile: {
    username: string;
    email: string;
  };
  oldpassword: string;
  password: string;
  confpassword: string;
  ChangePassword: any;
  GetProfileUser: () => void;
  SendAlert: (id: string, message: string) => void;
}

const LoginInfo = (props: LoginInfoProps) => {
  const {
    chngPass,
    chngPassData,
    GetingUser,
    dataProfile,
    ChangePassword,
    GetProfileUser,
    SendAlert,
  } = props;

  const [state, setState] = useState<LoginInfoProps | any>({
    username: "",
    email: "",
    oldpassword: "",
    password: "",
    confpassword: "",
  });
  const [errorsPassword, setErrorsPassword] = useState("");
  const [errorsConfPass, setErrorsConfPas] = useState("");
  const [errors, setErrors] = useState("");

  useEffect(() => {
    if (GetingUser === "1" && dataProfile) {
      setState({
        username: dataProfile.username,
        email: dataProfile.email,
      });
    }
    if (chngPass === "2") {
      SendAlert("50", Translate("alert", "updtpsfail"));
    }
    if (chngPass === "1") {
      SendAlert("41", Translate("alert", "updpasssuc"));
    }
  }, [chngPass, GetingUser]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    let isValid = true;
    if (!state.password) {
      isValid = false;
      setErrorsPassword(Translate("error", "reqfield"));
    } else {
      setErrorsPassword("");
    }
    if (!state.confpassword) {
      isValid = false;
      setErrorsConfPas(Translate("error", "reqfield"));
    } else {
      setErrorsConfPas("");
    }
    if (
      state.password &&
      state.confpassword &&
      state.password !== state.confpassword
    ) {
      isValid = false;
      setErrors(Translate("auths", "confirmPass"));
    } else {
      setErrors("");
    }
    if (isValid === true) {
      setErrors("");
      setErrorsPassword("");
      setErrorsConfPas("");
      let user = {
        old_password: state.oldpassword,
        password: state.password,
        confirmed_password: state.confpassword,
      };
      ChangePassword(user);
    }
  };

  return (
    <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs lytWdp stbd2 StDrc">
      <div className="stbd2">
        <h3 className="DlMg">{Translate("settings", "logininfo")}</h3>
      </div>
      {
        <div className="ClSidTh StBrdTb FlWd StBgbrds lytWdp3">
          <div
            className="MrAot Hlwd"
            style={{ width: "90%", padding: "10px 0" }}
          >
            <form method="post" autoComplete="off" onSubmit={handleSubmit}>
              <div className="InFlx flxDrc responsFlx FlWd spctwB">
                <div className="Hlwd">
                  <GroupeForm
                    id={"username"}
                    name={"username"}
                    placeholder={Translate("auths", "username")}
                    text={Translate("auths", "username")}
                    type={"text"}
                    value={state.username}
                    workfun={() => null}
                    icon={LockSvg}
                    readOnly
                    disabled
                  />
                  <GroupeForm
                    id={"email"}
                    name={"email"}
                    placeholder={Translate("auths", "email")}
                    text={Translate("auths", "email")}
                    type={"email"}
                    value={state.email}
                    workfun={() => null}
                    icon={LockSvg}
                    readOnly
                    disabled
                  />
                </div>
                <div className="Hlwd">
                  <GroupeForm
                    id={"oldpassword"}
                    name={"oldpassword"}
                    placeholder={Translate("auths", "oldpassword")}
                    text={Translate("auths", "oldpassword")}
                    type={"password"}
                    value={state.oldpassword}
                    workfun={handleChange}
                  />
                  <GroupeForm
                    id={"password"}
                    name={"password"}
                    placeholder={Translate("auths", "password")}
                    text={Translate("auths", "password")}
                    type={"password"}
                    value={state.password}
                    workfun={handleChange}
                    error={errorsPassword}
                  />
                  <GroupeForm
                    id={"confpassword"}
                    name={"confpassword"}
                    placeholder={Translate("auths", "confpassword")}
                    text={Translate("auths", "confpassword")}
                    type={"password"}
                    value={state.confpassword}
                    workfun={handleChange}
                    error={errors || errorsConfPass}
                  />
                </div>
              </div>
              <div className="MrAot StMarMx StMrtp" style={{ width: "200px" }}>
                <Button BtnText={Translate("orders", "save")} />
              </div>
            </form>
          </div>
        </div>
      }
    </div>
  );
};

function mapState(state: any) {
  const { chngPass, chngPassData, GetingUser, dataProfile } = state.user;

  return { chngPass, chngPassData, GetingUser, dataProfile };
}

const actionCreators = {
  ChangePassword: userActions.ChangePassword,
  GetProfileUser: userActions.GetProfileUser,
  SendAlert: alertActions.SendAlert,
};

const connectedLoginInfo = connect(mapState, actionCreators)(LoginInfo);
export { connectedLoginInfo as LoginInfo };
