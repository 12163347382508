import { pickupsConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
export const pickupsActions = {
  AddPickup,
  GetPickups,
  GetPossibleOrders,
  GetPickupOrders,
  UpdatePickup,
  UpdatePickupStatus,
  ClearPickupsStore,
  PrintBordereauPickup,
};
function AddPickup(pickup, type = "starter") {
  return (dispatch) => {
    dispatch(FunctionService.request(pickupsConstants.ADD_PICKUP_REQUEST));
    let url =
      type === "starter"
        ? "stores/order_transportation/"
        : "stores/stock_transportation/";
    RequestService.PostRequest(url, pickup, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, pickupsConstants.ADD_PICKUP_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, pickupsConstants.ADD_PICKUP_FAILURE)
        );
      });
  };
}
function UpdatePickup(pickup, idpickup) {
  return (dispatch) => {
    dispatch(FunctionService.request(pickupsConstants.UPDATE_PICKUP_REQUEST));
    RequestService.PatchRequest(
      "stores/order_transportation/" + idpickup + "/",
      pickup,
      { Authorization: "Token " + token }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res, pickupsConstants.UPDATE_PICKUP_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, pickupsConstants.UPDATE_PICKUP_FAILURE)
        );
      });
  };
}
function UpdatePickupStatus(pickup, idpickup, type = "starter") {
  return (dispatch) => {
    dispatch(
      FunctionService.request(pickupsConstants.UPDATE_STATUS_PICKUP_REQUEST)
    );
    let url =
      type === "starter"
        ? "stores/order_transportation/"
        : "stores/stock_transportation/";
    RequestService.PatchRequest(url + idpickup + "/", pickup, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res,
            pickupsConstants.UPDATE_STATUS_PICKUP_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err,
            pickupsConstants.UPDATE_STATUS_PICKUP_FAILURE
          )
        );
      });
  };
}
function GetPickups(filter, type = "starter", persistFilter = true) {
  return (dispatch) => {
    dispatch(FunctionService.request(pickupsConstants.GET_PICKUP_REQUEST));
    let url =
      type === "starter"
        ? "stores/order_transportation/"
        : "stores/stock_transportation/";
    RequestService.GetRequest(url + filter, { Authorization: "Token " + token })
      .then((res) => {
        if (type === "starter")
          dispatch(
            FunctionService.succes(
              { ressuc: res.data, filter: persistFilter ? filter : "" },
              pickupsConstants.GET_PICKUP_SUCCESS
            )
          );
        else
          dispatch(
            FunctionService.succes(
              { ressuc: res.data, filter: persistFilter ? filter : "" },
              pickupsConstants.GET_PICKUP_SUCCESS
            )
          );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            pickupsConstants.GET_PICKUP_FAILURE
          )
        );
      });
  };
}
function GetPossibleOrders(filter = "?status=4&pagination=false") {
  return (dispatch) => {
    dispatch(
      FunctionService.request(pickupsConstants.GET_POSSIBLE_ORDERS_REQUEST)
    );
    RequestService.GetRequest("stores/orders/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data },
            pickupsConstants.GET_POSSIBLE_ORDERS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            pickupsConstants.GET_POSSIBLE_ORDERS_FAILURE
          )
        );
      });
  };
}
function GetPickupOrders(idpickup) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(pickupsConstants.GET_PICKUPORDERS_REQUEST)
    );
    RequestService.GetRequest(
      "stores/order_transportation/orders/?transportation=" + idpickup,
      { Authorization: "Token " + token }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data },
            pickupsConstants.GET_PICKUPORDERS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            pickupsConstants.GET_PICKUPORDERS_FAILURE
          )
        );
      });
  };
}
function ClearPickupsStore() {
  return (dispatch) => {
    dispatch(FunctionService.request(pickupsConstants.CLEAR));
  };
}

function PrintBordereauPickup(pickups) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(pickupsConstants.POST_BORDEREAU_PICKUP_REQUEST)
    );
    RequestService.PostRequest(
      "stores/order_transportation/print_pickup_slip/",
      { pickups: [pickups] },
      {
        Authorization: "Token " + token,
      },
      { responseType: "blob", isExportFromServer: true }
    )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        dispatch(
          FunctionService.succes(
            file,
            pickupsConstants.POST_BORDEREAU_PICKUP_SUCCESS
          )
        );
      })

      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err,
            pickupsConstants.POST_BORDEREAU_PICKUP_FAILURE
          )
        );
      });
  };
}
