import React, { Component } from "react";
import ContainerPage from "../_Common/ContainerPage";
import { Translate } from "../../../utils/lang/translate";
import { StoreInfo, Paymenntinfo } from "./StoreInfo";
import { UserInfo } from "./User.info";
import { LoginInfo } from "./Login.Info";
import { IntegrationsPage } from "./IntegrationsPage";
import { TeamPage } from "./StoreTeam/TeamPage";
import { SubscriptionPage } from "./SubscriptionPage";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import StoreSettingsForm from "./Shop/StoreSettingsForm";
import ThemeSettingsForm from "./Shop/ThemeSettingsForm";
import SubdomainSettingsForm from "./Shop/SubdomainSettingsForm";
import FacebookSettingsForm from "./Shop/FacebookSettingsForm";
import FinishSettingShop from "./Shop/FinishSettingShop";
import ShopPublishedSuccess from "./Shop/ShopPublishedSuccess";
import { Webhooks } from "../Webhooks/Webhooks";
class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "settings");
  }
  render() {
    const { dtStoreinfo } = this.props;
    return (
      <ContainerPage page_title={Translate("titles", "settings")}>
        {/*<UnderDevPage/>*/}
        <div className="InFlx flxDrc responsFlx FlWd JstfCnt">
          <div className="StVl2dt ">
            <Switch>
              <Route path="/settings/store/payement" component={Paymenntinfo} />
              <Route path="/settings/store" component={StoreInfo} />
              <Route path="/settings/user" component={UserInfo} />
              <Route path="/settings/security" component={LoginInfo} />
              <Route
                path="/settings/integration"
                component={IntegrationsPage}
              />
              <Route path="/settings/team" component={TeamPage} />
              <Route
                path="/settings/subscription"
                component={SubscriptionPage}
              />
              <Route path="/settings/webhooks" component={Webhooks} />
              <Redirect from="*" to="/settings/user" />
            </Switch>
          </div>
        </div>
      </ContainerPage>
    );
    /* */
    //<UnderDevPage/>
  }
}

function mapState(state) {
  const { dtStoreinfo } = state.user;
  return {
    dtStoreinfo,
  };
}

const connectedSettingsPage = connect(mapState, {})(SettingsPage);
export { connectedSettingsPage as SettingsPage };
