import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../_Common/Table";
import { paymentActions } from "../../../actions";
import BalanceTab from "../_Common/BalanceTab";
import LoaderBln from "../_Common/LoaderBln";
import ContainerPage from "../_Common/ContainerPage";
import GroupeBtn from "../_Common/GroupeBtn";
import ChartsTab from "./utilsPayment/ChartsTab";
import {
  Translate,
  FormatCurrency,
  FormatDate,
} from "../../../utils/lang/translate";
import { ViewSvg, PrintSvg } from "../_Common/IconSvg";
import TableLoader from "../_Common/TableLoader";
import { EmptyScreen } from "../_Common/EmptyScreen";
import { initGAPg, Event } from "../_Common";
import { PAYMENTS_TABLE_HEADER } from "../../constants";

class HomePayment extends Component {
  constructor(props) {
    super(props);

    if (this.props.ViewPay !== "1") {
      this.props.ViewPayment();
    }
    this.Gotodetail = this.Gotodetail.bind(this);
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "payements");
    initGAPg();
  }
  Gotodetail(idval) {
    Event("PAYEMENT", "USE_VIEW_FACTURE_BUTTON", "CLICK_EVENT");
    this.props.history.push("/payments/facture/" + idval);
  }
  PopChk(idval) {
    Event("PAYEMENT", "USE_PRINT_FACTURE_BUTTON", "CLICK_EVENT");
    let usePr = window.open("/payments/facture/" + idval);
    usePr.ViewPay = this.props.ViewPay;
    usePr.ViewPaydata = this.props.ViewPaydata;
  }
  render() {
    const { ViewPay, ViewPaydata } = this.props;
    let slpay = "....";
    let slunc = "....";
    let Prsl = "0";
    let payments = [],
      paymentsDataset = [],
      nbrOrdersDataset = [];
    if (ViewPay === "1") {
      if (this.props?.dtStoreinfo?.country?.id === 1) {
        slpay = FormatCurrency.format(ViewPaydata["solde a payer"]);
        slunc = FormatCurrency.format(ViewPaydata["solde en cours"]);
      } else {
        slpay = ViewPaydata["solde a payer"] + "  " + "TND";
        slunc = ViewPaydata["solde en cours"] + "  " + "TND";
      }

      if (ViewPaydata["solde en cours"] !== 0) {
        Prsl = Math.floor(
          (ViewPaydata["solde a payer"] * 100) / ViewPaydata["solde en cours"]
        );
      }
      payments = ViewPaydata.payment
        .slice(0)
        .reverse()
        .map((elem, key) => {
          return [
            key + 1000,
            <div
              className="StwdMaxC StBrdRd TxtScc"
              style={{ padding: "5px 13px" }}
            >
              {elem.nb_orders}
            </div>,
            Number(elem.product_price).toFixed(2),
            <div style={{ minWidth: "95px" }}>
              {FormatDate(elem.payment_date)}
            </div>,
            <GroupeBtn
              data_btn={[
                {
                  type: "BDgInpc",
                  action: () => this.PopChk(key + 1000 + "?print=true"),
                  svgbtn: PrintSvg,
                },
                {
                  type: "BDgInpc",
                  action: () => this.Gotodetail(key + 1000),
                  svgbtn: ViewSvg,
                },
              ]}
            />,
          ];
        });
      ViewPaydata.payment.forEach((el) => {
        paymentsDataset.push({
          x: new Date(el.payment_date).toISOString().split("T")[0],
          y: Number(el.product_price),
        });
        nbrOrdersDataset.push({
          x: new Date(el.payment_date).toISOString().split("T")[0],
          y: el.nb_orders,
        });
      });
    }
    return (
      <ContainerPage page_title={Translate("titles", "payements")}>
        <div className="InFlx responsFlx flxDrc FlWd">
          <div className="StwdVlf1 StBrdTb StBgbrds ClSidTh">
            <div className="" style={{ margin: "7px 20px" }}>
              <h3 className="StdirLn">
                {Translate("payement", "payementsdetail")}
              </h3>
              <div className="RlPs FlWd" style={{ height: "300px" }}>
                <ChartsTab
                  dataset1={paymentsDataset}
                  dataset2={nbrOrdersDataset}
                />
              </div>
            </div>
          </div>
          <div className="StwdVlf2 StBrdTb StBgbrds ClSidTh Mrg2">
            <div className="RlPs" style={{ margin: "7px 20px" }}>
              <div className="FlWd InFlx spctwB">
                <h3 className="StdirLn">{Translate("payement", "balance")}</h3>
                <div className="StDrc">
                  <GroupeBtn
                    data_btn={[
                      {
                        type: "BDgInpc",
                        action: () => this.PopChk("all?print=true"),
                        svgbtn: PrintSvg,
                      },
                      {
                        type: "BDgInpc",
                        action: () => this.Gotodetail("all"),
                        svgbtn: ViewSvg,
                      },
                    ]}
                  />
                </div>
              </div>
              {ViewPay !== "1" ? (
                <LoaderBln />
              ) : (
                <div className="FlWd TxtCn">
                  <BalanceTab val={Prsl} />
                  <div className="BtNset StMrtp1">
                    <span className="DsBlc stbold" style={{ fontSize: "19px" }}>
                      {slunc}
                    </span>
                    <span className="StSmlS">
                      <div className="InFlx flxDrc JstfCnt AlgnItm">
                        <span className="StwdSetat StBrdRd BdgTra2"></span>
                        <span className="StWdDst StOpcVal">
                          {Translate("payement", "uncollectedbalance")}
                        </span>
                      </div>
                    </span>
                  </div>
                  <div className="BtNset">
                    <span className="DsBlc stbold" style={{ fontSize: "20px" }}>
                      {slpay}
                    </span>
                    <span className="StSmlS">
                      <div className="InFlx flxDrc JstfCnt AlgnItm">
                        <span className="StwdSetat StBrdRd BdgBlcl"></span>
                        <span className="StWdDst StOpcVal">
                          {Translate("payement", "payablebalance")}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="StMrtp RlPs" id="divToPrint">
          {ViewPay !== "1" ? (
            <TableLoader />
          ) : payments.length > 0 ? (
            <Table thead_elem={PAYMENTS_TABLE_HEADER} tbody_elem={payments} />
          ) : (
            <EmptyScreen
              titleGlb={Translate("payement", "nopayement")}
              TextShow={Translate("payement", "nopaytxt")}
              PictShow="/assets/img/emptyPay.png"
            />
          )}
        </div>
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { ViewPay, ViewPaydata } = state.Viewpayment;
  const { dtStoreinfo } = state.user;
  return { ViewPay, ViewPaydata, dtStoreinfo };
}

const actionCreators = {
  ViewPayment: paymentActions.ViewPayment,
};

const connectedHomePayment = connect(mapState, actionCreators)(HomePayment);
export { connectedHomePayment as HomePayment };
