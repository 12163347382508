import React, { Component, Fragment } from "react";
import ElementSide from "../_Common/ElementSide";
import ShopSideBar from "./ShopSideBar";
import {
  DashboardSvg,
  OrdersSvg,
  UntOrdersSvg,
  PaymentsSvg,
  SettingsSvg,
  PickupsSvg,
  BackupSvg,
  ConfirmationSvg,
  StockSvg,
  ProductSvg,
  TakenProdSvg,
  ReceiveProdSvg,
} from "./SideBarSvg";
import {
  IdentitySvg,
  LockSvg,
  storeStaffIcon,
  webhookIcon,
  PeopleSvg,
} from "../_Common/IconSvg";
import { Translate } from "../../../utils/lang/translate";
import { Link } from "react-router-dom";
import { shopActions, alertActions, ordersActions } from "../../../actions";
import { connect } from "react-redux";
import SideBarLoader from "./SideBarLoader";
import ElementSideDrop from "../_Common/ElementSideStockDropDown";
import { initGAPg, Event } from "../_Common";
import { checkUserHasShopPermissionById } from "../../../utils/helpers";
import logoSideBar from "../../../utils/img/maystro-blue.svg";
import logoSideBarNoText from "../../../utils/img/maystro-blue-notext.svg";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areSettingsVisible: false,
      areSettingsinfo: false,
    };
    this.handleSettingsDropDown = this.handleSettingsDropDown.bind(this);
    this.handleSettingsInfoDropDown =
      this.handleSettingsInfoDropDown.bind(this);
  }

  componentDidMount() {
    this.props.GetShopInfo();
    this.props.GetUnfOrdersCount();
  }

  componentWillReceiveProps(nextProps) {
    const { shopError, SendAlert } = this.props;
    if (nextProps.shopError && shopError !== nextProps.shopError) {
      SendAlert("50", nextProps.shopError);
    }
    if (
      this.props.pathname !== nextProps.pathname &&
      nextProps.pathname === "/unfulfilled/order"
    ) {
      this.props.GetUnfOrdersCount();
    }
  }

  handleSettingsDropDown() {
    this.setState({
      ...this.state,
      areSettingsVisible: !this.state.areSettingsVisible,
    });
  }
  handleSettingsInfoDropDown() {
    this.setState({
      ...this.state,
      areSettingsinfo: !this.state.areSettingsinfo,
    });
  }
  componentDidMount() {
    initGAPg();
  }

  render() {
    const {
      OrderGeting,
      OrdersList,
      GetinunfOrd,
      dataInfOrd,
      GetingUser,
      dataProfile,
      dtStoreinfo,
      sideState,
      dataInfOrdCount,
    } = this.props;
    let pathorder = "/orders";
    let pathunf = "/unfulfilled/order";
    if (OrderGeting === "2") {
      pathorder = "/orders" + OrdersList.filter;
    }
    if (GetinunfOrd === "2") {
      pathunf = "/unfulfilled/order" + (dataInfOrd.filter ?? "");
    }

    return (
      <div className="StWdSd StAbsvlm ClSidTh StBxSh stbd OvfAtoY HdOvFlwX FxPs">
        <div
          style={{
            paddingBottom: "53px",
            overflowY: "auto",
            height: "calc(100vh - 69px)",
          }}
        >
          <div className="TxtCn">
            <Link to="/dashboard">
              {sideState ? (
                <img
                  className={"IcnSiz2"}
                  style={{ marginTop: "10px" }}
                  alt="Maystro sidebar logo"
                  src={logoSideBarNoText}
                />
              ) : (
                <img
                  className="StWdIlg"
                  id="Logo_Maystro"
                  alt="Maystro sidebar logo"
                  src={logoSideBar}
                />
              )}
            </Link>
          </div>
          <span style={{ padding: "5px 0", display: "block" }}></span>
          {GetingUser === "0" ? (
            <SideBarLoader />
          ) : (
            GetingUser === "1" &&
            dtStoreinfo &&
            dataProfile && (
              <Fragment>
                {(dataProfile.is_store || dataProfile.add_order) && (
                  <div className="TxtCn SmMarS2">
                    <Link to="/order/add/new">
                      <button className="Fntcl FntfMin Brdblue rmOut StBrdRd BdgGc CrsPoi BtNset StPrwd">
                        {sideState ? (
                          <span
                            style={{ fontWeight: "bold", fontSize: "20px" }}
                          >
                            +
                          </span>
                        ) : (
                          "+ " + Translate("sidebar", "addorder")
                        )}
                      </button>
                    </Link>
                  </div>
                )}
                <div rol="top_side">
                  <ElementSide
                    clickEvent={() => {
                      Event(
                        "DASHBOARD",
                        "CLICK_ELEMENT_SIDEBAR_DASHBOARD",
                        "CLICK_EVENT"
                      );
                    }}
                    reduce={sideState}
                    icon={DashboardSvg}
                    to={"/dashboard"}
                    active={this.props.pathname === "/dashboard"}
                    text={Translate("sidebar", "dashboard")}
                  />
                  {(dataProfile.is_store || dataProfile.view_order) && (
                    <Fragment>
                      <ElementSide
                        clickEvent={() => {
                          Event(
                            "ORDERS",
                            "CLICK_ELEMENT_SIDEBAR_ORDERS",
                            "CLICK_EVENT"
                          );
                        }}
                        reduce={sideState}
                        icon={OrdersSvg}
                        to={pathorder}
                        active={this.props.pathname === "/orders"}
                        text={Translate("sidebar", "orders")}
                      />
                      {dataProfile.intgraed &&
                        (dataProfile.is_store || dataProfile.view_order) && (
                          <ElementSide
                            clickEvent={() => {
                              Event(
                                "UNFULFILLED_ORDERS",
                                "CLICK_ELEMENT_SIDEBAR_UNFULFILLED_ORDERS",
                                "CLICK_EVENT"
                              );
                            }}
                            reduce={sideState}
                            icon={UntOrdersSvg}
                            to={pathunf}
                            active={
                              this.props.pathname === "/unfulfilled/order"
                            }
                            text={Translate("sidebar", "unfulfilledorders")}
                            notif
                            temp_orders_count={
                              dataInfOrdCount?.temp_orders_count
                            }
                          />
                        )}
                    </Fragment>
                  )}

                  {/* ici mon code */}
                  {(dataProfile.is_store || dataProfile.view_stock) && (
                    <div rol="bottum_side">
                      <div onClick={this.handleSettingsDropDown}>
                        <ElementSideDrop
                          icon={StockSvg}
                          select={this.state.areSettingsVisible}
                          reduce={sideState}
                          active={
                            this.props.pathname.indexOf("/stock") != "-1" ||
                            (this.props.pathname.split("/")[1] === "shop" &&
                              this.props.pathname.split("/")[2] === "product")
                          }
                          text={Translate("sidebar", "stock")}
                          to={"#"}
                        />
                      </div>
                      {this.state.areSettingsVisible && (
                        <div>
                          {dtStoreinfo.stock_managed ? (
                            <div style={{ marginLeft: "10px" }}>
                              <ElementSide
                                clickEvent={() => {
                                  Event(
                                    "STOCK",
                                    "CLICK_ELEMENT_SIDEBAR_PRODUCTS",
                                    "CLICK_EVENT"
                                  );
                                }}
                                reduce={sideState}
                                icon={ProductSvg}
                                to="/stock/products"
                                active={
                                  this.props.pathname === "/stock/products"
                                }
                                text={Translate("sidebar", "products")}
                              />

                              <ElementSide
                                clickEvent={() => {
                                  Event(
                                    "STOCK",
                                    "CLICK_ELEMENT_SIDEBAR_VOUCHER_RECEIVE",
                                    "CLICK_EVENT"
                                  );
                                }}
                                reduce={sideState}
                                icon={ReceiveProdSvg}
                                to="/stock/receiveProduct"
                                active={
                                  this.props.pathname ===
                                  "/stock/receiveProduct"
                                }
                                text={Translate("sidebar", "receiveProd")}
                              />
                              <ElementSide
                                clickEvent={() => {
                                  Event(
                                    "STOCK",
                                    "CLICK_ELEMENT_SIDEBAR_VOUCHER_TAKEN",
                                    "CLICK_EVENT"
                                  );
                                }}
                                reduce={sideState}
                                icon={TakenProdSvg}
                                to="/stock/takenProduct"
                                active={
                                  this.props.pathname === "/stock/takenProduct"
                                }
                                text={Translate("sidebar", "takenProd")}
                              />
                            </div>
                          ) : (
                            <div style={{ marginLeft: "10px" }}>
                              <ElementSide
                                clickEvent={() => {
                                  Event(
                                    "STOCK",
                                    "CLICK_ELEMENT_SIDEBAR_PRODUCTS",
                                    "CLICK_EVENT"
                                  );
                                }}
                                reduce={sideState}
                                icon={ProductSvg}
                                to="/stock/products"
                                active={
                                  this.props.pathname === "/stock/products"
                                }
                                text={Translate("sidebar", "products")}
                              />

                              <ElementSide
                                clickEvent={() => {
                                  Event(
                                    "STOCK",
                                    "CLICK_ELEMENT_SIDEBAR_VOUCHER_RECEIVE",
                                    "CLICK_EVENT"
                                  );
                                }}
                                reduce={sideState}
                                icon={ReceiveProdSvg}
                                to="/stock/receiveProduct"
                                active={
                                  this.props.pathname ===
                                  "/stock/receiveProduct"
                                }
                                text="Received"
                              />
                              <ElementSide
                                clickEvent={() => {
                                  Event(
                                    "STOCK",
                                    "CLICK_ELEMENT_SIDEBAR_VOUCHER_TAKEN",
                                    "CLICK_EVENT"
                                  );
                                }}
                                reduce={sideState}
                                icon={TakenProdSvg}
                                to="/stock/takenProduct"
                                active={
                                  this.props.pathname === "/stock/takenProduct"
                                }
                                text="Taken"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {(dataProfile.is_store || dataProfile.view_payment) && (
                    <ElementSide
                      clickEvent={() => {
                        Event(
                          "PAYMENTS",
                          "CLICK_ELEMENT_SIDEBAR_PAYMENTS",
                          "CLICK_EVENT"
                        );
                      }}
                      reduce={sideState}
                      icon={PaymentsSvg}
                      to="/payments"
                      active={this.props.pathname === "/payments"}
                      text={Translate("sidebar", "payments")}
                    />
                  )}
                  {(dataProfile.is_store || dataProfile.view_pickup) &&
                    !dtStoreinfo.stock_managed && (
                      <ElementSide
                        clickEvent={() => {
                          Event(
                            "PICKUPS",
                            "CLICK_ELEMENT_SIDEBAR_PICKUPS",
                            "CLICK_EVENT"
                          );
                        }}
                        reduce={sideState}
                        icon={PickupsSvg}
                        to="/pickups"
                        active={this.props.pathname === "/pickups"}
                        text={Translate("sidebar", "pickups")}
                      />
                    )}
                  {(dataProfile.is_store || dataProfile.view_pickup) &&
                    dtStoreinfo.stock_managed &&
                    dtStoreinfo.id ===
                      "3a2855aa-9319-481e-ac50-164083b8a297" && (
                      <ElementSide
                        clickEvent={() => {
                          Event(
                            "PICKUPS",
                            "CLICK_ELEMENT_SIDEBAR_PICKUPS",
                            "CLICK_EVENT"
                          );
                        }}
                        reduce={sideState}
                        icon={PickupsSvg}
                        to="/pickups"
                        active={this.props.pathname === "/pickups"}
                        text={Translate("sidebar", "stockpickups")}
                      />
                    )}

                  {dtStoreinfo.is_gold && (
                    <ElementSide
                      clickEvent={() => {
                        Event(
                          "CONFIRMATION",
                          "CLICK_ELEMENT_SIDEBAR_CONFIRMATION",
                          "CLICK_EVENT"
                        );
                      }}
                      reduce={sideState}
                      icon={ConfirmationSvg}
                      to="/confirmation"
                      active={this.props.pathname === "/confirmation"}
                      text={Translate("sidebar", "confirmation")}
                    />
                  )}
                  {dtStoreinfo?.id &&
                    checkUserHasShopPermissionById(dtStoreinfo.id) && (
                      <ShopSideBar
                        sideState={sideState}
                        pathname={this.props.pathname}
                      />
                    )}
                </div>
                <span
                  className="DsBlc FlWd"
                  style={{
                    height: "1px",
                    backgroundColor: "var(--fntClr)",
                    margin: "14px 0",
                    opacity: ".2",
                  }}
                ></span>
                <div rol="bottom_side">
                  <div onClick={this.handleSettingsInfoDropDown}>
                    <ElementSideDrop
                      icon={SettingsSvg}
                      select={this.state.areSettingsinfo}
                      reduce={sideState}
                      active={this.props.pathname.split("/")[1] === "settings"}
                      text={Translate("sidebar", "settings")}
                    />
                  </div>
                  {this.state.areSettingsinfo && (
                    <div style={{ marginLeft: "10px" }}>
                      <ElementSide
                        clickEvent={() => {
                          Event(
                            "SETTINGS",
                            "CLICK_ELEMENT_SIDEBAR_USER",
                            "CLICK_EVENT"
                          );
                        }}
                        icon={IdentitySvg}
                        reduce={sideState}
                        to="/settings/user"
                        active={this.props.pathname === "/settings/user"}
                        text={Translate("settings", "userinfo")}
                      />
                      <ElementSide
                        icon={storeStaffIcon}
                        reduce={sideState}
                        clickEvent={() => {
                          Event(
                            "SETTINGS",
                            "CLICK_ELEMENT_SIDEBAR_STORE_INFO",
                            "CLICK_EVENT"
                          );
                        }}
                        to="/settings/store"
                        active={this.props.pathname == "/settings/store"}
                        text={Translate("settings", "storeinfo")}
                      />
                      <ElementSide
                        clickEvent={() => {
                          Event(
                            "SETTINGS",
                            "CLICK_ELEMENT_SIDEBAR_SECURITY",
                            "CLICK_EVENT"
                          );
                        }}
                        icon={LockSvg}
                        reduce={sideState}
                        to="/settings/security"
                        active={this.props.pathname === "/settings/security"}
                        text={Translate("settings", "logininfo")}
                      />
                      <ElementSide
                        clickEvent={() => {
                          Event(
                            "SETTINGS",
                            "CLICK_ELEMENT_SIDEBAR_TEAM",
                            "CLICK_EVENT"
                          );
                        }}
                        icon={PeopleSvg}
                        reduce={sideState}
                        to="/settings/team/home"
                        active={
                          this.props.pathname.indexOf("/settings/team") !== -1
                        }
                        text={Translate("settings", "storeteam")}
                      />

                      <ElementSide
                        icon={webhookIcon}
                        reduce={sideState}
                        to="/settings/webhooks"
                        active={this.props.pathname === "/settings/webhooks"}
                        text={Translate("settings", "webhooks")}
                      />
                    </div>
                  )}

                  <ElementSide
                    clickEvent={() => {
                      Event(
                        "BACKUP",
                        "CLICK_ELEMENT_SIDEBAR_BACKUP",
                        "CLICK_EVENT"
                      );
                    }}
                    icon={BackupSvg}
                    reduce={sideState}
                    to="/backup"
                    active={this.props.pathname.indexOf("/backup") != "-1"}
                    text={Translate("sidebar", "backup")}
                  />
                  {/*(dataProfile.is_store || dataProfile.view_issue_store) && <ElementSide icon={IssuesSvg} to="/issues" active={this.props.pathname=="/issues"} text={Translate('sidebar','issues')}/>*/}
                </div>
              </Fragment>
            )
          )}
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { OrderGeting, OrdersList } = state.orders;
  const { GetinunfOrd, dataInfOrd, dataInfOrdCount } = state.unfOrders;
  const { GetingUser, dataProfile, dtStoreinfo, gtStoreInf } = state.user;
  const { sideState } = state.sidebar;
  const { dataShop, shopError } = state.shop;
  return {
    OrderGeting,
    OrdersList,
    GetinunfOrd,
    dataInfOrd,
    GetingUser,
    dataProfile,
    dtStoreinfo,
    gtStoreInf,
    sideState,
    dataShop,
    shopError,
    dataInfOrdCount,
  };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
  SendAlert: alertActions.SendAlert,
  GetUnfOrdersCount: ordersActions.GetUnfOrdersCount,
};

const connectedSideBar = connect(mapState, actionCreators)(SideBar);
export { connectedSideBar as SideBar };
