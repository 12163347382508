import React, { useEffect } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../actions";
import { Translate } from "../../../utils/lang/translate";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { Event, initGAPg } from "../../StorePages/_Common";

interface Props {
  history: any;
  user: any;
}

const CompleteRegister: React.FC<Props> = ({ history, user }) => {
  useEffect(() => {
    document.title = "Maystro Delivrey - " + Translate("titles", "register");
    initGAPg();
  }, []);

  const goToStoreRegistration = (e: React.MouseEvent<HTMLDivElement>) => {
    Event("REGISTER", "CHOOSE_CREATE_STORE_CARD", "CLICK_EVENT");
    history.push("/store/add");
  };

  const goToJoinStore = (e: React.MouseEvent<HTMLDivElement>) => {
    Event("REGISTER", "CHOOSE_CREATE_STORE_CARD", "CLICK_EVENT");
    history.push("/store/join");
  };

  if (!user || !Cookies.get("temp-user-infos")) {
    return <Redirect to="/register" />;
  }

  return (
    <div className="CnGl DlPg DlMg FxPs FlWd FlHg OvfAtoY">
      <div className="FlHg InFlx responsFlx flxDrc spcEvn AlgnItm">
        <div
          className="InFlx Stclmnf JstfCnt AlgnItm TxtCn ClSidTh CrsPoi"
          style={{ width: "30%", borderRadius: "24px", padding: "3% 5%" }}
          onClick={goToStoreRegistration}
        >
          <img
            src="/assets/img/store_owner.png"
            alt="Maystro Delivery logo"
            style={{ width: "40%", maxWidth: "70vw" }}
          />
          <h4>{Translate("auths", "amstoreowner")}</h4>
          <p>{Translate("auths", "amstoreownerdesc")}</p>
        </div>
        <div
          className="InFlx Stclmnf JstfCnt AlgnItm TxtCn ClSidTh CrsPoi"
          style={{ width: "30%", borderRadius: "24px", padding: "3% 5%" }}
          onClick={goToJoinStore}
        >
          <img
            src="/assets/img/join_team.png"
            alt="Maystro Delivery logo"
            style={{ width: "40%", maxWidth: "70vw" }}
          />
          <h4>{Translate("auths", "aminvited")}</h4>
          <p>{Translate("auths", "aminviteddesc")}</p>
        </div>
      </div>
    </div>
  );
};

function mapState(state: any) {
  const { Sended, user } = state.registration;
  return { Sended, user };
}

const actionCreators = {
  RegisterTap: userActions.RegisterTap,
};

const connectedCompleteRegister = connect(
  mapState,
  actionCreators
)(CompleteRegister);
export { connectedCompleteRegister as CompleteRegister };
