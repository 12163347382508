import React from "react";
import { Translate } from "../../../utils/lang/translate";
import ReactTooltip from "react-tooltip";
export default function TootlipCurrentOrders(props) {
  let class_status =
    " TxtCn StPdsml sDfpnc sb4R " +
    (props.classes || "status") +
    "_" +
    props.status;

  //let wth = "calc(32px * " + props.data_btn.length + " )";

  return (
    <div className="StBrdRdSc" style={{ width: "auto" }}>
      {Array.isArray(props.data_btn) &&
        props.data_btn.map((elem, key) => {
          return (
            <div
              className="spctwB AlgnItm InFlx d-large"
              style={{
                width: "-webkit-fill-available",
                justifyContent: "flex-end",
              }}
              key={key}
              data-tip
              data-for={`${elem.tooltip}-tooltip-${key}`}
            >
              <span
                onClick={elem.action}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                }}
              >
                {elem.svgbtn}
              </span>

              <span className="number_current_orders Fntcl">
                {elem.nmbrbtn}
              </span>
              <span className="text_current_orders Fntcl">{elem.titlebtn}</span>
              {elem.tooltip && (
                <ReactTooltip id={`${elem.tooltip}-tooltip-${key}`}>
                  {elem.tooltip}
                </ReactTooltip>
              )}
            </div>
          );
        })}
    </div>
  );
}
