import React, { useEffect, useState } from 'react'
import GroupeForm from '../../_Common/GroupeForm';
import { Translate } from '../../../utils/lang/translate';
import { priceChecker } from '../../../utils/helpers';
import { ProductShopVariants } from '../../../types';
import { deleteIcon } from '../_Common/IconSvg';
import { Product } from './LandingPageForm';
import ShopSelectFromSearchForVariants from './ShopSelectFromSearchForVariants';

interface Props {
    productList? : { title: string; id: string }[]
    handleChange: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      key?: number
    ) => void;
    onDeleteVariant: (id: string, index: number) => void;
    handleProductChange: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    variants: ProductShopVariants[]
    showDeleteIcon?: boolean
    index: number
    onVariantTitleChange: (event: React.ChangeEvent<HTMLInputElement>)=>void
    product: Product | null,
    variant: ProductShopVariants
    isModify: boolean
    onVariantProductNameChange: (name: string) =>void
  }

const VariantsSetupFields : React.FC<Props> = ({
    productList,
    handleChange,
    onDeleteVariant,
    handleProductChange,
    showDeleteIcon,
    index,
    onVariantTitleChange,
    product,
    variants,
    variant,
    isModify,
    onVariantProductNameChange
  }) => {

    const initialVariantProductValue = {
      title: variant.name,
      id: variant.product
    }

  const [variantProduct, setVariantProduct] = useState<Product>(initialVariantProductValue)
  
  useEffect(()=>{

    if(isModify){
      const selectedVariant = productList?.find((prod:any) => prod.id === variant.product )
      const currentVariant = selectedVariant ? {id: selectedVariant.id, title: selectedVariant.title} : {id:"",title:""}
      setVariantProduct(currentVariant)
    }
  },[productList, variant])
  

  const handleVariantProductChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    const { value } = e.target as HTMLInputElement;
    const selectedProd = productList?.find((prod) => prod.title === value);

    if (value.length === 0) {
      onVariantProductNameChange(variant.product)
    }

    if (!selectedProd){
      setVariantProduct({ title: value, id:"" })
    } else {
      setVariantProduct(selectedProd)
      handleProductChange(e)
    }
  }

  const hasSameNameAsOtherVariants = variants.some(
    (variant, k) => index !== k && variants[index].name.toLowerCase() === variant.name.toLowerCase()
  );

  return (
    <div
      key={index}
      className={`align-items-end grid marginBottom1 variants-fields-grid `}
    >
      <ShopSelectFromSearchForVariants
        id={`product${index}`}
        name="product"
        index="title"
        option={productList}
        text={Translate("product", "product")}
        workfun={handleVariantProductChange}
        placeholder={Translate("shop", "selectedProductPlaceholder")}
        value={variantProduct.title}
        stclass={!variant.product ? "borderError" : ""}
        required
        is_variant={true}
        variantProduct={variantProduct}
        variant={variant}
        variants={variants}
      />

      <GroupeForm
        id="title"
        name="title"
        placeholder={Translate("shop", "titlePlaceHolder")}
        text={Translate("shop", "title")}
        type="text"
        value={variant.name}
        workfun={onVariantTitleChange}
        stclass={
          !variant.name || hasSameNameAsOtherVariants
            ? "borderError"
            : ""
        }
        required
      />

      <GroupeForm
        id={`variant-price-${index}`}
        name="price"
        text={Translate("shop", "price")}
        type="number"
        placeholder={Translate("shop", "price")}
        min={1}
        value={variant.price || ""}
        workfun={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          handleChange(e, index);
        }}
        inputStyle={
          variant.reduced_price
            ? { textDecoration: "line-through" }
            : {}
        }
        stclass={
          !priceChecker(variant.price, variant.reduced_price)
            ? "borderError"
            : ""
        }
        required
      />

      <GroupeForm
        id={`reduced_variant-price-${index}`}
        name="reduced_price"
        text={Translate("shop", "reducedPrice")}
        type="number"
        placeholder={Translate("shop", "reducedPrice")}
        min={1}
        value={variant.reduced_price || ""}
        workfun={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          handleChange(e, index);
        }}
      />

      {showDeleteIcon && (
        <span onClick={() => onDeleteVariant(variant.product, index)}>
          {deleteIcon}
        </span>
      )}
    </div>
  );
}

export default VariantsSetupFields