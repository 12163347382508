import { ordersConstants, userConstants } from "../constants";

export function orders(state, action) {
  switch (action.type) {
    case ordersConstants.ADD_ORDER_REQUEST:
      return {
        OrderAdded: "1",
        dataAddOrder: action.payload,
      };
    case ordersConstants.ADD_ORDER_SUCCESS:
      return {
        OrderAdded: "2",
        dataAddOrder: action.payload,
      };
    case ordersConstants.ADD_ORDER_FAILURE:
      return {
        OrderAdded: "3",
        dataAddOrder: action.payload,
      };
    case ordersConstants.GET_ORDER_REQUEST:
      return {
        OrderGeting: "1",
        OrdersList: action.payload,
      };
    case ordersConstants.GET_ORDER_SUCCESS:
      return {
        OrderGeting: "2",
        OrdersList: action.payload,
      };
    case ordersConstants.GET_ORDER_FAILURE:
      return {
        OrderGeting: "3",
        OrdersList: action.payload,
      };
    case ordersConstants.GET_ORDER_DETAIL_REQUEST:
      return {
        OrderDetailGeting: "1",
        OrderDetail: action.payload,
      };
    case ordersConstants.GET_ORDER_DETAIL_SUCCESS:
      return {
        OrderDetailGeting: "2",
        OrderDetail: action.payload,
      };
    case ordersConstants.GET_ORDER_DETAIL_FAILURE:
      return {
        OrderDetailGeting: "3",
        OrderDetail: action.payload,
      };
    case ordersConstants.GET_ALL_ORDER_REQUEST:
      return {
        OrderAllGeting: "1",
        OrdersALL: action.payload,
      };
    case ordersConstants.GET_ALL_ORDER_SUCCESS:
      return {
        OrderAllGeting: "2",
        OrdersALL: action.payload,
      };
    case ordersConstants.GET_ALL_ORDER_FAILURE:
      return {
        OrderAllGeting: "3",
        OrdersALL: action.payload,
      };
    case ordersConstants.GET_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        GettingOrderHist: "1",
        OrderHist: action.payload,
      };
    case ordersConstants.GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        GettingOrderHist: "2",
        OrderHist: action.payload,
      };
    case ordersConstants.GET_ORDER_HISTORY_FAILURE:
      return {
        ...state,
        GettingOrderHist: "3",
        OrderHist: action.payload,
      };
    case ordersConstants.UPDATE_ORDER_REQUEST:
      return {
        OrderUpdating: "1",
        DataUpdateOrder: action.payload,
      };
    case ordersConstants.UPDATE_ORDER_SUCCESS:
      return {
        OrderUpdating: "2",
        DataUpdateOrder: action.payload,
      };
    case ordersConstants.UPDATE_ORDER_FAILURE:
      return {
        OrderUpdating: "3",
        DataUpdateOrder: action.payload,
      };
    case ordersConstants.UPDATE_STATUS_ORDER_REQUEST:
      return {
        ...state,
        OrderUpdSta: "0",
        DataUpdSta: action.payload,
      };
    case ordersConstants.UPDATE_STATUS_ORDER_SUCCESS:
      return {
        ...state,
        OrderUpdSta: "1",
        DataUpdSta: action.payload,
      };
    case ordersConstants.UPDATE_STATUS_ORDER_FAILURE:
      return {
        ...state,
        OrderUpdSta: "2",
        DataUpdSta: action.payload,
      };
    case ordersConstants.IMPORT_ORDER_REQUEST:
      return {
        ImportingOrd: "0",
        DataImport: action.payload,
      };
    case ordersConstants.IMPORT_ORDER_SUCCESS:
      return {
        ImportingOrd: "1",
        DataImport: action.payload,
      };
    case ordersConstants.IMPORT_ORDER_FAILURE:
      return {
        ImportingOrd: "2",
        DataImport: action.payload,
      };
    case ordersConstants.IMPORT_ORDPRDS_REQUEST:
      return {
        ImportingOrdPrd: "0",
        DataImportPrds: action.payload,
      };
    case ordersConstants.IMPORT_ORDPRDS_SUCCESS:
      return {
        ImportingOrdPrd: "1",
        DataImportPrds: action.payload,
      };
    case ordersConstants.IMPORT_ORDPRDS_FAILURE:
      return {
        ImportingOrdPrd: "2",
        DataImportPrds: action.payload,
      };
    case userConstants.GET_TYPES_WEBHOOK_REQUEST:
      return {
        ...state,
        gettingwebhookTypes: "1",
        datawebhookTypes: action.payload,
      };
    case userConstants.GET_TYPES_WEBHOOK_SUCCESS:
      return {
        ...state,
        gettingwebhookTypes: "2",
        datawebhookTypes: action.payload,
      };
    case userConstants.GET_TYPES_WEBHOOK_FAILURE:
      return {
        ...state,
        gettingwebhookTypes: "3",
        datawebhookTypes: action.payload,
      };
    case userConstants.GET_WEBHOOK_REQUEST:
      return {
        ...state,
        gettingwebhook: "1",
        datawebhook: action.payload,
      };
    case userConstants.GET_WEBHOOK_SUCCESS:
      return {
        ...state,
        gettingwebhook: "2",
        datawebhook: action.payload,
      };
    case userConstants.GET_WEBHOOK_FAILURE:
      return {
        ...state,
        gettingwebhook: "3",
        datawebhook: action.payload,
      };
    case userConstants.DELETE_WEBHOOK_REQUEST:
      return {
        ...state,
        deletingwebhook: "1",
        deletewebhook: action.payload,
      };
    case userConstants.DELETE_WEBHOOK_SUCCESS:
      return {
        ...state,
        deletingwebhook: "2",
        deletewebhook: action.payload,
      };
    case userConstants.DELETE_WEBHOOK_FAILURE:
      return {
        ...state,
        deletingwebhook: "3",
        deletewebhook: action.payload,
      };
    case userConstants.POST_WEBHOOK_REQUEST:
      return {
        ...state,
        postingwebhook: "1",
        senddatawebhook: action.payload,
      };
    case userConstants.POST_WEBHOOK_SUCCESS:
      return {
        ...state,
        postingwebhook: "2",
        senddatawebhook: action.payload,
      };
    case userConstants.POST_WEBHOOK_FAILURE:
      return {
        ...state,
        postingwebhook: "3",
        senddatawebhook: action.payload,
      };
    case userConstants.TEST_WEBHOOK_REQUEST:
      return {
        ...state,
        testingwebhook: "1",
        sendtestwebhook: action.payload,
      };
    case userConstants.TEST_WEBHOOK_SUCCESS:
      return {
        ...state,
        testingwebhook: "2",
        sendtestwebhook: action.payload,
      };
    case userConstants.TEST_WEBHOOK_FAILURE:
      return {
        ...state,
        testingwebhook: "3",
        sendtestwebhook: action.payload,
      };
    case userConstants.POST_BORDEREAU_REQUEST:
      return {
        ...state,
        postingbordereau: "1",
        dataBordereau: action.payload,
      };
    case userConstants.POST_BORDEREAU_SUCCESS:
      return {
        ...state,
        postingbordereau: "2",
        dataBordereau: action.payload,
      };
    case userConstants.POST_BORDEREAU_FAILURE:
      return {
        ...state,
        postingbordereau: "3",
        dataBordereau: action.payload,
      };
    case ordersConstants.GET_CURRENT_ORDERS_REQUEST:
      return {
        ...state,
        gettingCurrentOrders: "1",
        datCurrentOrders: action.payload,
      };
    case ordersConstants.GET_CURRENT_ORDERS_SUCCESS:
      return {
        ...state,
        gettingCurrentOrders: "2",
        datCurrentOrders: action.payload,
      };
    case ordersConstants.GET_CURRENT_ORDERS_FAILURE:
      return {
        ...state,
        gettingCurrentOrders: "3",
        datCurrentOrders: action.payload,
      };
    case "CLEAR":
      return {};
    default:
      return { ...state };
  }
}

export function unfOrders(state, action) {
  switch (action.type) {
    case ordersConstants.GET_UNF_ORDER_REQUEST:
      return {
        ...state,
        GetinunfOrd: "1",
        dataInfOrd: action.payload,
      };
    case ordersConstants.GET_UNF_ORDER_SUCCESS:
      return {
        ...state,
        GetinunfOrd: "2",
        dataInfOrd: action.payload,
      };
    case ordersConstants.GET_UNF_ORDER_FAILURE:
      return {
        ...state,
        GetinunfOrd: "3",
        dataInfOrd: action.payload,
      };

    case ordersConstants.GET_UNF_ORDER_TIMELINE_REQUEST:
      return {
        ...state,
        GettingUnfOrdTimeline: "1",
        dataUnfOrTimeline: action.payload,
      };
    case ordersConstants.GET_UNF_ORDER_TIMELINE_SUCCESS:
      return {
        ...state,
        GettingUnfOrdTimeline: "2",
        dataUnfOrTimeline: action.payload,
      };
    case ordersConstants.GET_UNF_ORDER_TIMELINE_FAILURE:
      return {
        ...state,
        GettingUnfOrdTimeline: "3",
        dataUnfOrTimeline: action.payload,
      };

    case ordersConstants.UPDATE_UNF_ORDER_REQUEST:
      return {
        ...state,
        UpdinunfOrd: "1",
        dataUpInfOrd: action.payload,
      };
    case ordersConstants.UPDATE_UNF_ORDER_SUCCESS:
      return {
        ...state,
        UpdinunfOrd: "2",
        dataUpInfOrd: action.payload,
      };
    case ordersConstants.UPDATE_UNF_ORDER_FAILURE:
      return {
        ...state,
        UpdinunfOrd: "3",
        dataUpInfOrd: action.payload,
      };

    case ordersConstants.GET_UNF_ORDER_COUNT_REQUEST:
      return {
        ...state,
        GetinunfOrdCount: "1",
        dataInfOrdCount: action.payload,
      };
    case ordersConstants.GET_UNF_ORDER_COUNT_SUCCESS:
      return {
        ...state,
        GetinunfOrdCount: "2",
        dataInfOrdCount: action.payload,
      };
    case ordersConstants.GET_UNF_ORDER_COUNT_FAILURE:
      return {
        ...state,
        GetinunfOrdCount: "3",
        dataInfOrdCount: action.payload,
      };

    case ordersConstants.UPDATE_CALLBACK_UNF_ORDER_REQUEST:
      return {
        ...state,
        UpdateCallbackUnfOrder: "1",
      };
    case ordersConstants.UPDATE_CALLBACK_UNF_ORDER_SUCCESS:
      return {
        ...state,
        UpdateCallbackUnfOrder: "2",
        dataInfOrd: {
          ...state.dataInfOrd,
          ressuc: {
            ...state.dataInfOrd.ressuc,
            results: state.dataInfOrd.ressuc.results.map((ord) => {
              if (ord.id === action.payload.orderId)
                return {
                  ...ord,
                  is_callback: action.payload.isCallback,
                };
              return ord;
            }),
          },
        },
      };
    case ordersConstants.UPDATE_CALLBACK_UNF_ORDER_FAILURE:
      return {
        ...state,
        UpdateCallbackUnfOrder: "3",
      };

    case ordersConstants.UPDATE_POSTPONED_UNF_ORDER_REQUEST:
      return {
        ...state,
        UpdatePostponedUnfOrder: "1",
      };
    case ordersConstants.UPDATE_POSTPONED_UNF_ORDER_SUCCESS:
      return {
        ...state,
        UpdatePostponedUnfOrder: "2",
        dataInfOrd: {
          ...state.dataInfOrd,
          ressuc: {
            ...state.dataInfOrd.ressuc,
            results: state.dataInfOrd.ressuc.results.map((ord) => {
              if (ord.id === action.payload.orderId)
                return {
                  ...ord,
                  postponed_to: action.payload.postponed_to,
                };
              return ord;
            }),
          },
        },
      };
    case ordersConstants.UPDATE_POSTPONED_UNF_ORDER_FAILURE:
      return {
        ...state,
        UpdatePostponedUnfOrder: "3",
      };

    case "CLEAR_UNF_ORD":
      return {};
    default:
      return { ...state };
  }
}
export function confOrders(state, action) {
  switch (action.type) {
    case ordersConstants.GET_CONF_SUMMARY_REQUEST:
      return {
        ...state,
        GettingSummary: "0",
        SummaryData: action.payload,
      };
    case ordersConstants.GET_CONF_SUMMARY_SUCCESS:
      return {
        ...state,
        GettingSummary: "1",
        SummaryData: action.payload,
      };
    case ordersConstants.GET_CONF_SUMMARY_FAILURE:
      return {
        ...state,
        GettingSummary: "2",
        SummaryData: action.payload,
      };
    case ordersConstants.GET_CONF_ORDER_REQUEST:
      return {
        ...state,
        GettingConfirmOrders: "0",
        ConfOrdersList: action.payload,
      };
    case ordersConstants.GET_CONF_ORDER_SUCCESS:
      return {
        ...state,
        GettingConfirmOrders: "1",
        ConfOrdersList: action.payload,
      };
    case ordersConstants.GET_CONF_ORDER_FAILURE:
      return {
        ...state,
        GettingConfirmOrders: "2",
        ConfOrdersList: action.payload,
      };
    case ordersConstants.UPDATE_CONF_ORDER_REQUEST:
      return {
        ...state,
        UpdinunfOrd: "1",
        dataUpInfOrd: action.payload,
      };
    case ordersConstants.UPDATE_CONF_ORDER_SUCCESS:
      return {
        ...state,
        UpdinunfOrd: "2",
        dataUpInfOrd: action.payload,
      };
    case ordersConstants.UPDATE_CONF_ORDER_FAILURE:
      return {
        ...state,
        UpdinunfOrd: "3",
        dataUpInfOrd: action.payload,
      };
    case "CLEAR_CONF_ORD":
      return {};
    default:
      return { ...state };
  }
}
