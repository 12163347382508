import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Translate } from "../../../../utils/lang/translate";
import GroupeForm from "../../../_Common/GroupeForm";
import { alertActions, paymentActions } from "../../../../actions";
import Button from "../../../_Common/Button";
import Loader from "../../../_Common/Loader";
import { Redirect, Link } from "react-router-dom";
import { initGAPg, Event } from "../../_Common";

interface PaymenntinfoProps {
  fullname: string;
  address: string;
  accountnumber: string;
  keyAccount: string;
  accountrip: string;
  ccpid: string;
  addingCcp: string;
  CcpData: any;
  updatingCcp: string;
  CcpUpdateData: any;
  getingCcp: string;
  getCcpData: any;
  UpdateStore: any;
  history: any;
  AddCCPAcount: any;
  UpdateCCPAccount: any;
  SendAlert: (id: string, message: string) => void;
}

const Paymenntinfo = (props: PaymenntinfoProps) => {
  const {
    getingCcp,
    getCcpData,
    history,
    UpdateCCPAccount,
    AddCCPAcount,
    SendAlert,
    addingCcp,
    updatingCcp,
  } = props;

  const [state, setState] = useState<PaymenntinfoProps | any>({
    fullname:
      getingCcp === "1" && getCcpData && getCcpData.count > 0
        ? getCcpData.results[0].full_name
        : "",
    address:
      getingCcp === "1" && getCcpData && getCcpData.count > 0
        ? getCcpData.results[0].address
        : "",
    accountnumber:
      getingCcp === "1" && getCcpData && getCcpData.count > 0
        ? getCcpData.results[0].account_number
        : "",
    keyAccount:
      getingCcp === "1" && getCcpData && getCcpData.count > 0
        ? getCcpData.results[0].key
        : "",
    accountrip:
      getingCcp === "1" && getCcpData && getCcpData.count > 0
        ? getCcpData.results[0].account_rip
        : "",
    ccpid:
      getingCcp === "1" && getCcpData && getCcpData.count > 0
        ? getCcpData.results[0].id
        : "",
  });

  const [isMobile, setIsMobile] = useState<boolean>(getWindowSize().innerWidth >= 789 ? false : true);

  let prefixCCP: string = "00799999";

  if (!getingCcp) {
    history.push("/settings/store");
  }

  useEffect(() => {
    function handleWindowResize() {
      if (getWindowSize().innerWidth >= 789) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [getWindowSize]);

  useEffect(() => {
    initGAPg();
  }, []);
  useEffect(() => {
    if (addingCcp === "2" || updatingCcp === "2") {
      SendAlert("50", Translate("alert", "prompt"));
    } else if (addingCcp === "1" || updatingCcp === "1") {
      SendAlert("41", Translate("alert", "promptSuccess"));
    }
  }, [addingCcp, updatingCcp]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    let ccpacount = {
      full_name: state.fullname,
      address: state.address,
      key: state.keyAccount,
      account_number: state.accountnumber,
      account_rip: prefixCCP + state.accountnumber + state.keyAccount,
    };
    if (getingCcp === "1" && getCcpData && getCcpData.count > 0) {
      Event("STORE_CCP", "EDIT_CCP_ACCOUNT", "CLICK_EVENT");
      UpdateCCPAccount(ccpacount, state.ccpid);
    } else {
      Event("STORE_CCP", "ADD_CCP_ACCOUNT", "CLICK_EVENT");
      AddCCPAcount(ccpacount);
    }
  };
  if (addingCcp === "1" || updatingCcp === "1") {
    return <Redirect to="/settings/store" />;
  }

  return (
    <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs sTbxSz StPdsml">
      {(addingCcp === "0" || updatingCcp === "0") && <Loader />}
      <div className="stbd2">
        <h3 className="DlMg">
          <div className="StDrc">
            <Link className="StOpcVal" to="/settings/store">
              {Translate("settings", "storeinfo")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <div className="Inlflx">{Translate("profile", "paymenntinfo")}</div>
          </div>
        </h3>
      </div>
      <div>
        <form method="post" onSubmit={handleSubmit}>
          <div className="MrAot" style={{ width: "85%" }}>
            <div
              className={
                !isMobile
                  ? "FlWd InFlx flxDrc StMrtp"
                  : "FlWd InFlx flxDrc Stclmnf StMrtp"
              }
            >
              <div className={!isMobile ? "Hlwd" : ""}>
                <GroupeForm
                  id={"fullname"}
                  name={"fullname"}
                  placeholder={Translate("auths", "fullname")}
                  text={Translate("auths", "fullname")}
                  type={"text"}
                  pattern={"[A-Za-z]+"}
                  value={state.fullname}
                  workfun={handleChange}
                />
              </div>
              <div className={!isMobile ? "Hlwd Mrg2" : ""}>
                <GroupeForm
                  id={"address"}
                  name={"address"}
                  placeholder={Translate("payement", "adress")}
                  text={Translate("payement", "adress")}
                  type={"text"}
                  value={state.address}
                  workfun={handleChange}
                />
              </div>
            </div>
            <div
              className={
                !isMobile
                  ? "FlWd InFlx flxDrc StMrtp"
                  : "FlWd InFlx flxDrc Stclmnf StMrtp"
              }
            >
              <div className={!isMobile ? "Hlwd InFlx flxDrc" : "InFlx flxDrc"}>
                <div style={{ width: "70%" }}>
                  <GroupeForm
                    id={"accountnumber"}
                    name={"accountnumber"}
                    placeholder={Translate("payement", "accountnumber")}
                    text={Translate("payement", "accountnumber")}
                    type={"number"}
                    pattern={"[0-9]{X,10}+"}
                    value={state.accountnumber}
                    workfun={handleChange}
                  />
                </div>
                <div className="Mrg2" style={{ width: "27%" }}>
                  <GroupeForm
                    id={"keyAccount"}
                    name={"keyAccount"}
                    placeholder={Translate("payement", "key")}
                    text={Translate("payement", "key")}
                    type={"number"}
                    pattern={"[0-9]{X,2}+"}
                    value={state.keyAccount}
                    workfun={handleChange}
                  />
                </div>
              </div>
              <div className={!isMobile ? "Hlwd Mrg2" : ""}>
                <GroupeForm
                  id={"Accountrip"}
                  name={"accountrip"}
                  placeholder={
                    prefixCCP + state.accountnumber + state.keyAccount
                  }
                  text={Translate("payement", "accountrip")}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="MrAot StMarMx StMrtp" style={{ width: "200px" }}>
            <Button
              BtnText={
                getingCcp === "1" && getCcpData && getCcpData.count > 0
                  ? Translate("orders", "save")
                  : Translate("settings", "addacount")
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};

function mapState(state: any) {
  const {
    addingCcp,
    CcpData,
    getingCcp,
    getCcpData,
    updatingCcp,
    CcpUpdateData,
  } = state.paymentMethod;
  return {
    addingCcp,
    CcpData,
    getingCcp,
    getCcpData,
    updatingCcp,
    CcpUpdateData,
  };
}

const actionCreators = {
  AddCCPAcount: paymentActions.AddCCPAcount,
  UpdateCCPAccount: paymentActions.UpdateCCPAccount,
  SendAlert: alertActions.SendAlert,
};

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}

const connectedPaymenntinfo = connect(mapState, actionCreators)(Paymenntinfo);
export { connectedPaymenntinfo as Paymenntinfo };
