import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../actions";
import GroupeForm from "../../_Common/GroupeForm";
import Loader from "../../_Common/Loader";
import HeadLog from "../_Common/HeadLog";
import ContainerLog from "../_Common/ContainerLog";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import { Link, Redirect } from "react-router-dom";

interface JoinStoreProps {
  Sended: string;
  user: {
    user_name: string;
    full_name: string;
    email: string;
    phone: string;
    password: string;
    confirmed_password: string;
  };
  CrtingMember: string;
  dataCreateMember: any;
  VeryMember: string;
  dataVerifyMember: any;
  CreateMember: Function;
  VerifyMember: Function;
  history: any;
}

const JoinStore = ({
  Sended,
  user,
  CrtingMember,
  dataCreateMember,
  VeryMember,
  dataVerifyMember,
  CreateMember,
  VerifyMember,
  history,
}: JoinStoreProps) => {
  const [state, setState] = useState({
    user_name: "",
    full_name: "",
    email: "",
    phone: "",
    password: "",
    confirmed_password: "",
    store_code: "",
  });
  if (Sended === "0" || user) {
    state.user_name = user.user_name;
    state.full_name = user.full_name;
    state.email = user.email;
    state.phone = user.phone;
    state.password = user.password;
    state.confirmed_password = user.confirmed_password;
  } else {
    history.push("/register");
  }
  useEffect(() => {
    document.title = "Maystro Delivrey - " + Translate("titles", "joinstore");
    if (user && user.phone) {
      VerifyMember({ phone: user.phone });
    }
  }, []);

  useEffect(() => {
    if (
      VeryMember === "2" &&
      dataVerifyMember &&
      dataVerifyMember.data &&
      (dataVerifyMember.data["invitation does not exist"] ||
        dataVerifyMember?.data?.detail ===
          "get() returned more than one InvitationStoreMember -- it returned 2!")
    ) {
      setTimeout(() => history.push("/register"), 5000);
    }
  }, [VeryMember, dataVerifyMember]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (VeryMember === "1" && dataVerifyMember && state.store_code) {
      const member = {
        user_id: dataVerifyMember.user_id,
        team: dataVerifyMember.team,
        token: parseInt(state.store_code),
        user_name: state.user_name,
        full_name: state.full_name,
        email: state.email,
        phone: state.phone,
        password: state.password,
        confirmed_password: state.confirmed_password,
      };
      CreateMember(member);
    }
  };

  if (CrtingMember === "1") {
    return <Redirect to="/login" />;
  } else if (CrtingMember === "2") {
    return <Redirect to="/register" />;
  }

  return VeryMember !== "2" ? (
    <ContainerLog>
      {(CrtingMember === "0" || VeryMember === "0") && <Loader />}
      <HeadLog
        titleHead={Translate("auths", "storeteam")}
        descHead={Translate("auths", "textjoin")}
      />
      <form action="post" onSubmit={handleSubmit} className="StMarMx">
        <GroupeForm
          id={"store_code"}
          name={"store_code"}
          placeholder={Translate("auths", "storecode")}
          text={Translate("auths", "storecode")}
          type={"password"}
          value={state.store_code}
          workfun={handleChange}
        />
        <div className="SmtGrp MrAot StMarMx">
          <Button BtnText={Translate("auths", "finish")} />
        </div>
      </form>
      <div className="StOpcVal">
        <div className="StMrg">
          <span className="StWdDst">{Translate("auths", "ownstore")}</span>
          <Link className="StBle stbold StWdDst" to="/store/add">
            {Translate("auths", "enterinfo")}
          </Link>
        </div>
      </div>
    </ContainerLog>
  ) : (
    <ContainerLog>
      {false && (
        <HeadLog
          titleHead={Translate("auths", "storeteam")}
          descHead={Translate("auths", "textjoin")}
        />
      )}
      {dataVerifyMember &&
        dataVerifyMember.data &&
        dataVerifyMember.data["invitation does not exist"] && (
          <div>
            <p className="StRedClr stbold">
              {Translate("alert", "failfindinvitedmember")}
            </p>
            <p className="">
              {
                "You'll be automatically redirected to the concerning page in 5 seconds ..."
              }
            </p>
          </div>
        )}
      {dataVerifyMember &&
        dataVerifyMember.data &&
        dataVerifyMember?.data?.detail ===
          "get() returned more than one InvitationStoreMember -- it returned 2!" && (
          <div>
            <p className="StRedClr stbold">
              {Translate("auths", "phonenumberValidation")}
            </p>
            <p className="">
              {
                "You'll be automatically redirected to the concerning page in 5 seconds ..."
              }
            </p>
          </div>
        )}
    </ContainerLog>
  );
};

function mapState(state: any) {
  const { Sended, user } = state.registration;
  const { CrtingMember, dataCreateMember, VeryMember, dataVerifyMember } =
    state.memberTeam;
  return {
    Sended,
    user,
    CrtingMember,
    dataCreateMember,
    VeryMember,
    dataVerifyMember,
  };
}

const actionCreators = {
  CreateMember: userActions.CreateMember,
  VerifyMember: userActions.VerifyMember,
};

const connectedJoinStore = connect(mapState, actionCreators)(JoinStore);
export { connectedJoinStore as JoinStore };
