import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { shopActions } from "../../../../actions";
import { DataShop } from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import { LandingPageSvg } from "../../SideBar/SideBarSvg";
import { Event } from "../../_Common";
import ContainerPage from "../../_Common/ContainerPage";
import { EmptyScreen } from "../../_Common/EmptyScreen";
import { ShopSvg, SimpleFlechRightSvg } from "../../_Common/IconSvg";
import TableLoader from "../../_Common/TableLoader";
import "./ShopSettingMain.scss";

const VIDEO_LINK = [
  "https://www.youtube.com/embed/gu38NsIAQiE",
  "https://www.youtube.com/embed/dc5TRDZmKxw",
  "https://www.youtube.com/embed/nrXdue4qfrM",
  "https://www.youtube.com/embed/5rioEtU9Ocs",
  "https://www.youtube.com/embed/uIc4qE7bB3s",
  "https://www.youtube.com/embed/VKn5EIRA-nA",
  "https://www.youtube.com/embed/5OjdEhqqH9M",
];

interface Props {
  dataShop: DataShop;
  shopLoading: boolean;
  GetShopInfo: () => void;
}

const ShopSettingMain: React.FC<Props> = ({
  dataShop,
  shopLoading,
  GetShopInfo,
}) => {
  const [selectedVideoId, setSelectedVideoId] = useState<number | null>(null);
  useEffect(() => {
    GetShopInfo();
  }, []);

  return (
    <ContainerPage
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">{Translate("titles", "settings")}</span>
        </div>
      }
    >
      {shopLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : (
        <>
          {!dataShop?.published ? (
            <EmptyScreen
              PictShow="/assets/img/no_shop.png"
              titleGlb={Translate("shop", "noShopTitle")}
              actionBtns={[
                {
                  text: Translate("shop", "setupShop"),
                  to: "/shop/settings/store",
                  event: () => {
                    Event("SHOP", `CONFIGURE_SHOP_CALL_ACTION`, "CLICK_EVENT");
                  },
                },
              ]}
            />
          ) : (
            <div className="ShopSettingMain">
              <div className="links">
                <Link
                  to="/shop/settings/store"
                  className="link"
                  onClick={() => {
                    Event("SHOP", `MAIN_SETTINGS_SHOP_BUTTON`, "CLICK_EVENT");
                  }}
                >
                  <div className="icon">{ShopSvg}</div>
                  <div className="texts">
                    <h2>{Translate("titles", "shop")}</h2>
                    <p>{Translate("shop", "viewManageShop")}</p>
                  </div>
                  <div className="arrow">{SimpleFlechRightSvg}</div>
                </Link>
                <Link
                  to="/shop/settings/header"
                  className="link"
                  onClick={() => {
                    Event(
                      "SHOP",
                      `MAIN_SETTINGS_LANDING_PAGE_BUTTON`,
                      "CLICK_EVENT"
                    );
                  }}
                >
                  <div className="icon">{LandingPageSvg}</div>
                  <div className="texts">
                    <h2>{Translate("shop", "landingPage")}</h2>
                    <p>{Translate("shop", "viewManageLandingPage")}</p>
                  </div>
                  <div className="arrow">{SimpleFlechRightSvg}</div>
                </Link>
              </div>
            </div>
          )}
          <h3 className="how-it-works">{Translate("shop", "howItWorks")}</h3>
          <div className="shop-videos">
            {VIDEO_LINK.map((link, index) => (
              <div className="shop-video-container">
                <img
                  src={`/assets/img/shop-video-thumbnail-${index + 1}.jpg`}
                  alt={`Maystro Shop tutorial ${index + 1}`}
                  onClick={() => {
                    setSelectedVideoId(index);
                  }}
                />
                <p>Maystro Shop tutorial {index + 1}</p>
              </div>
            ))}
          </div>
          {typeof selectedVideoId === "number" && (
            <div
              className="fullscreen-video"
              onClick={() => {
                setSelectedVideoId(null);
              }}
            >
              <iframe
                width="800"
                height="450"
                src={VIDEO_LINK[selectedVideoId]}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                // @ts-ignore
                style={{ "--mobile-top": `${500 + 185 * selectedVideoId}px` }}
              />
            </div>
          )}
        </>
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { shopLoading, dataShop, shopError } = state.shop;
  return { shopLoading, dataShop, shopError };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
  UpdateDataShop: shopActions.UpdateDataShop,
  UploadDataShopLogo: shopActions.UploadDataShopLogo,
};

export default connect(mapState, actionCreators)(ShopSettingMain);
