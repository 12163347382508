export const productConstants = {

  GET_LOW_STOCK_REQUEST: "GET_LOW_STOCK_REQUEST",
  GET_LOW_STOCK_SUCCESS: "GET_LOW_STOCK_SUCCESS",
  GET_LOW_STOCK_FAILURE: "GET_LOW_STOCK_FAILURE",

  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",
  ADD_PRODUCT_REQUEST: "ADD_PRODUCT_REQUEST",

  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
  GET_PRODUCT_REQUEST: "GET_PRODUCT_REQUEST",

  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",
  UPDATE_PRODUCT_REQUEST: "UPDATE_PRODUCT_REQUEST",

  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",
  DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",

  STOCK_PRODUCT_SUCCESS: "GET_STOCK_PRODUCT_SUCCESS",
  STOCK_PRODUCT_FAILURE: "GET_STOCK_PRODUCT_FAILURE",
  STOCK_PRODUCT_REQUEST: "GET_STOCK_PRODUCT_REQUEST",

  UNLINK_PRODUCT_SUCCESS: "GET_UNLINK_PRODUCT_SUCCESS",
  UNLINK_PRODUCT_FAILURE: "GET_UNLINK_PRODUCT_FAILURE",
  UNLINK_PRODUCT_REQUEST: "GET_UNLINK_PRODUCT_REQUEST",

  GET_PRODUCT_PAGE_SUCCESS: "GET_PRODUCT_PAGE_SUCCESS",
  GET_PRODUCT_PAGE_FAILURE: "GET_PRODUCT_PAGE_FAILURE",
  GET_PRODUCT_PAGE_REQUEST: "GET_PRODUCT_PAGE_REQUEST",

  GET_PRODUCT_HISTORY_SUCCESS: "GET_PRODUCT_HISTORY_SUCCESS",
  GET_PRODUCT_HISTORY_FAILURE: "GET_PRODUCT_HISTORY_FAILURE",
  GET_PRODUCT_HISTORY_REQUEST: "GET_PRODUCT_HISTORY_REQUEST",

  GET_PRODUCT_PERFORMANCE_SUCCESS: "GET_PRODUCT_PERFORMANCE_SUCCESS",
  GET_PRODUCT_PERFORMANCE_FAILURE: "GET_PRODUCT_PERFORMANCE_FAILURE",
  GET_PRODUCT_PERFORMANCE_REQUEST: "GET_PRODUCT_PERFORMANCE_REQUEST",

  GET_AVAILABLE_QUANTITY_SUCCESS: "GET_AVAILABLE_QUANTITY_SUCCESS",
  GET_AVAILABLE_QUANTITY_FAILURE: "GET_AVAILABLE_QUANTITY_FAILURE",
  GET_AVAILABLE_QUANTITY_REQUEST: "GET_AVAILABLE_QUANTITY_REQUEST",

  GET_ALL_RECEIVED_PRODUCTS_SUCCESS: "GET_ALL_RECEIVED_PRODUCTS_SUCCESS",
  GET_ALL_RECEIVED_PRODUCTS_FAILURE: "GET_ALL_RECEIVED_PRODUCTS_FAILURE",
  GET_ALL_RECEIVED_PRODUCTS_REQUEST: "GET_ALL_RECEIVED_PRODUCTS_REQUEST",

  GET_ALL_EXIT_PRODUCTS_SUCCESS: "GET_ALL_EXIT_PRODUCTS_SUCCESS",
  GET_ALL_EXIT_PRODUCTS_FAILURE: "GET_ALL_EXIT_PRODUCTS_FAILURE",
  GET_ALL_EXIT_PRODUCTS_REQUEST: "GET_ALL_EXIT_PRODUCTS_REQUEST",

  GET_DETAILS_RECEIVED_PRODUCTS_SUCCESS:
    "GET_DETAILS_RECEIVED_PRODUCTS_SUCCESS",
  GET_DETAILS_RECEIVED_PRODUCTS_FAILURE:
    "GET_DETAILS_RECEIVED_PRODUCTS_FAILURE",
  GET_DETAILS_RECEIVED_PRODUCTS_REQUEST:
    "GET_DETAILS_RECEIVED_PRODUCTS_REQUEST",

  GET_DETAILS_TAKEN_PRODUCTS_SUCCESS: "GET_DETAILS_TAKEN_PRODUCTS_SUCCESS",
  GET_DETAILS_TAKEN_PRODUCTS_FAILURE: "GET_DETAILS_TAKEN_PRODUCTS_FAILURE",
  GET_DETAILS_TAKEN_PRODUCTS_REQUEST: "GET_DETAILS_TAKEN_PRODUCTS_REQUEST",

  GET_ALL_RECEIVED_PRODUCTS_FILTRED_SUCCESS:
    "GET_ALL_RECEIVED_PRODUCTS_FILTRED_SUCCESS",
  GET_ALL_RECEIVED_PRODUCTS_FILTRED_FAILURE:
    "GET_ALL_RECEIVED_PRODUCTS_FILTRED_FAILURE",
  GET_ALL_RECEIVED_PRODUCTS_FILTRED_REQUEST:
    "GET_ALL_RECEIVED_PRODUCTS_FILTRED_REQUEST",

  EXPORT_RECEIVE_PRODUCT_REQUEST: "EXPORT_RECEIVE_PRODUCT_REQUEST",
  EXPORT_RECEIVE_PRODUCT_SUCCESS: "EXPORT_RECEIVE_PRODUCT_SUCCESS",
  EXPORT_RECEIVE_PRODUCT_FAILURE: "EXPORT_RECEIVE_PRODUCT_FAILURE",
};
