import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, Prompt } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { alertActions, productActions, shopActions } from "../../../actions";
import { DEFAULT_PRICING_OPTION } from "../../../constants";
import {
  customSection,
  DataShop,
  DeletedShopPhoto,
  ProductShop,
  ProductShopPhoto,
  ProductShopWithVariants,
} from "../../../types";
import { convertDescriptionToSlug, priceChecker } from "../../../utils/helpers";
import { Translate } from "../../../utils/lang/translate";
import useExitPrompt from "../../../utils/useExitPrompt";
import Button from "../../_Common/Button";
import GroupeForm from "../../_Common/GroupeForm";
import { Event, Select } from "../_Common";
import ContainerPage from "../_Common/ContainerPage";
import TableLoader from "../_Common/TableLoader";
import ImagesList from "./ImagesList";
import RichTextEditor from "./RichTextEditor";
import ProductPricingOptionsFields from "./ProductPricingOptionsFields";
import "./ShopPage.scss";
import TagInput from "../../_Common/TagInput";
import { Switch } from "@mui/material";
import ShopSelectFormSearch from "../../_Common/ShopSelectFormSearch";
import { LoadingSmallSvg, aiStatrsSvg } from "../_Common/IconSvg";
import SectionList from "./SectionList";
import VariantsSetupFields from "./VariantsSetupFields";
import Cookies from "js-cookie";

const token = Cookies.get("auth");

const INITIAL_OPTION = {
  name: "Option name",
  quantity: 1,
  price: 0,
  reduced_price: null,
  id: "",
};

const INITIAL_VARIANTS = {
  name: "",
  price: "",
  reduced_price: null, 
  product: "",
};

const INITIAL_PRODUCT_FORM: ProductShop = {
  title: "",
  slug: "",
  options: [{ ...INITIAL_OPTION }],
  categories: [],
  description: "",
  how_to_use: "",
  images: [],
  specifications: "",
  product_id: "",
  display_id: "",
  published: true,
  id: "",
  landing_page: "",
  lang: "ar",
  rate: "5",
  pricing_option: DEFAULT_PRICING_OPTION,
  template_details: [{ custom_sections: [] }],
};

const INITIAL_PRODUCT_FORM_WITH_VARIANTS: ProductShopWithVariants = {
  title: "",
  slug: "",
  categories: [],
  description: "",
  how_to_use: "",
  images: [],
  specifications: "",
  display_id: "",
  published: true,
  id: "",
  landing_page: "",
  lang: "ar",
  rate: "5",
  pricing_option: DEFAULT_PRICING_OPTION,
  template_details: [{ custom_sections: [] }],
  variants: [INITIAL_VARIANTS],
  is_variant: true
};
const slugRegex = new RegExp("^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$");

const languages = [
  ["ar", Translate("profile", "ar")],
  ["fr", Translate("profile", "fr")],
  ["en", Translate("profile", "en")],
];

interface ImageToUpload {
  imageFile: File;
  id: string;
}

export interface Product {
  title: string;
  id: string;
}

const SESSION_ID = uuid();

interface Props {
  dtStoreinfo: any;
  GetAllProduct: any;
  GetShopInfoWithPricing: () => void;
  dataShop: DataShop;
  shopError: boolean;
  shopLoading: boolean;
  productShopPhoto: ProductShopPhoto;
  deletedShopPhoto: DeletedShopPhoto;
  ModifyProductShop: (
    dataShop: DataShop,
    productShop: ProductShop,
    successCallback?: () => void
  ) => void;
  ModifyProductShopWithVariants: (
    dataShop: DataShop,
    productShop: ProductShopWithVariants,
    successCallback?: () => void
  ) => void;
  CreateProductShop: (
    productShop: ProductShop,
    newImages: File[],
    data: DataShop,
    setProgress?: (percentage: number | null) => void,
    successCallback?: () => void
  ) => void;
  CreateProductShopWithVariants: (
    productShop: ProductShopWithVariants,
    newImages: File[],
    data: DataShop,
    setProgress?: (percentage: number | null) => void,
    successCallback?: () => void
  ) => void;
  DeletePhotoProductShop: (
    imageId: string,
    productId: string,
    successCallback?: () => void
  ) => void;
  UploadPhotoProductShop: (
    newImage: File,
    productId: string,
    setProgress?: (percentage: number | null) => void,
    successCallback?: () => void
  ) => void;
  data: any;
  match: any;
  ProductFinded: any;
  history: any;
  SendAlert: (code: string, text: string, action?: string) => void;
  GetGeneratedDescreptionWithAi: (id: string, lang: string) => Promise<string | null>
}

const LandingPageForm: React.FC<Props> = ({
  GetAllProduct,
  GetShopInfoWithPricing,
  dataShop,
  shopLoading,
  productShopPhoto,
  deletedShopPhoto,
  ModifyProductShop,
  ModifyProductShopWithVariants,
  CreateProductShop,
  CreateProductShopWithVariants,
  UploadPhotoProductShop,
  DeletePhotoProductShop,
  data,
  match,
  ProductFinded,
  history,
  SendAlert,
  dtStoreinfo
}) => {
  const { idprod } = match.params;
  const [productList, setProductList] = useState<
    { title: string; id: string }[]
  >([]);
  const [product, setProduct] = useState<Product | null>(null);
  const [productForm, setProductForm] = useState(INITIAL_PRODUCT_FORM);
  const [imagesToUpload, setImagesToUpload] = useState<ImageToUpload[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isChange, setIsChange] = useExitPrompt(false);
  const [isModify, setIsModify] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [isVariants, setIsVariants] = useState(false)
  const [productFormWithVariants, setProductFormWithVariants] = useState(INITIAL_PRODUCT_FORM_WITH_VARIANTS);
  const [loading, setLoading] = useState(false);

  const handleGenerateDescreptionWithAI = () => {
    setLoading(true);
    if(productForm.product_id !== ""){

      fetch(
        "https://b.maystro-delivery.com/api/shop/generate-description/" + productForm.product_id + 
        "/?language=" + productForm.lang.toUpperCase(),
        { 
          headers: {
            Authorization: "Token " + token  
          }
        }
      ).then(res => res.json())
      .then((res) => {
        setProductForm(prev => {
          return { ...prev, description: res.description }
        })
      })
      .catch((err) => {
        console.error(err);
        setProductForm(prev => {
          return { ...prev, description: "an error occured couldn't generate descreption !" }
        });
      })
      .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    document.title =
      "Maystro Delivery - " + Translate("titles", "addProductShop");
    GetShopInfoWithPricing();

    if (idprod === "addwithvariants") setIsVariants(true)

    return () => {
      setIsChange(false);
    };
  }, []);

  useEffect(() => {
    if (
      !shopLoading &&
      dataShop?.pricingOptions &&
      Array.isArray(dataShop.products)
    ) {
      if (!dataShop.published) history.push("/shop/settings/");
      if (idprod === "add" || idprod === "addwithvariants" ) {
        GetAllProduct();
        return;
      }

      if (idprod ==="add" || idprod === "addwithvariants") return;

      const productDataShop = dataShop.products.find(
        (prod) => prod.id.toString() === idprod.toString()
      );
      
      if (!productDataShop) {
        history.push("/shop/landing-page");
        return;
      }
      // show the "Variants Set up" section + get all the product list if the landing page has variants in the "modify mode"
      if ('is_variant' in productDataShop && productDataShop.is_variant) {
        setIsVariants(true)
        GetAllProduct()
      }

      const pricing_option =
        productDataShop.pricing_option ?? DEFAULT_PRICING_OPTION;

        if(!('is_variant' in productDataShop && productDataShop.is_variant)) {
        setProductForm({
          ...productForm,
          ...productDataShop,
          pricing_option,
          display_id: idprod,
          images: productDataShop.images ,
        });
        
      }else{
        setProductFormWithVariants(productFormWithVariants => {
          return {
            ...productFormWithVariants,
            ...productDataShop,
            pricing_option,
            display_id: idprod,
            images:  productDataShop.images,
          }
        })
      }

      setIsModify(true);
      setIsLoading(false);
    }
  }, [dataShop]);

  useEffect(() => {
    if (
      ProductFinded === "2" &&
      data?.list &&
      Array.isArray(data.list) &&
      dataShop
    ) {
      const productsWithoutLandingPage = data.list.filter((prod: any) =>
        dataShop.products.every((shopProd) => {
          if("product_id" in shopProd) {
            return shopProd.product_id !== prod.id
          } 
        })

      );
      const list = (!isVariants ? productsWithoutLandingPage : data.list).map((prod: any) => ({
        id: prod.id,
        title: prod.logistical_description,
      })) 

      setProductList(list)
      setIsLoading(false);
    }
  }, [data, dataShop]);

  useEffect(() => {
    if (!isVariants) {
      if (
        productShopPhoto?.url &&
        productShopPhoto.productId === productForm.id
      ) {
        setProductForm({
          ...productForm,
          images: [
            ...productForm.images,
            {
              url: productShopPhoto.url,
              id: productShopPhoto.id,
              order: productForm.images.length,
            },
          ],
        });
      }
      
    } else {
      if (
        productShopPhoto?.url &&
        productShopPhoto.productId === productFormWithVariants.id
      ) {
        setProductFormWithVariants({
          ...productFormWithVariants,
          images: [
            ...productFormWithVariants.images,
            {
              url: productShopPhoto.url,
              id: productShopPhoto.id,
              order: productFormWithVariants.images.length,
            },
          ],
        });
      }
    }
  }, [productShopPhoto]);

  useEffect(() => {
    if (!isVariants) {
      if (
        deletedShopPhoto?.imageId &&
        deletedShopPhoto.productId === productForm.id
      ) {
        setProductForm({
          ...productForm,
          images: productForm.images
            .filter((img) => img.id !== deletedShopPhoto.imageId)
            .map((img, key) => {
              return { ...img, order: key };
            }),
        });
      }
      
    } else {
      if (
        deletedShopPhoto?.imageId &&
        deletedShopPhoto.productId === productFormWithVariants.id
      ) {
        setProductFormWithVariants({
          ...productFormWithVariants,
          images: productFormWithVariants.images
            .filter((img) => img.id !== deletedShopPhoto.imageId)
            .map((img, key) => {
              return { ...img, order: key };
            }),
        });
      }
    }
  }, [deletedShopPhoto]);

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key?: number
  ) => {
    const { value, name } = event.target as HTMLInputElement;
    if (typeof key !== "number")
      !isVariants ? setProductForm({ ...productForm, [name]: value }) : setProductFormWithVariants({ ...productFormWithVariants , [name]: value })
    else{
    if (!isVariants) {
      setProductForm({
        ...productForm,
        options: productForm.options.map((option, i) => {
          if (i === key)
            return { ...option, [name]: value !== "" ? value : null };
          return option;
        }),
      })
    } else {
      setProductFormWithVariants({
        ...productFormWithVariants,
        variants: productFormWithVariants.variants.map((variant, i) => {
          if (i === key)
            return { ...variant, [name]: value !== "" ? value : null };
          return variant;
        }),
      });
    }
    setIsChange(true);
  }
  };

  const handleRichTextChange = (content: string, name: string) => {
    if(!isVariants) {
    if (name in productForm) {
      setProductForm({ ...productForm, [name]: content });
    }
  } else{
    if (name in productFormWithVariants) {
      setProductFormWithVariants({ ...productFormWithVariants, [name]: content });
    }
  }
  setIsChange(true);
  };

  const addSection = () => {
    if(!isVariants) {
      setProductForm({
        ...productForm,
        template_details: [
          {
            custom_sections: [
              ...productForm.template_details[0].custom_sections,
              { title: "", content: "" },
            ],
          },
        ],
      });
    } else {
      setProductFormWithVariants({
        ...productFormWithVariants,
        template_details: [
          {
            custom_sections: [
              ...productFormWithVariants.template_details[0].custom_sections,
              { title: "", content: "" },
            ],
          },
        ],
      });
    }
    setIsChange(true);
  };

  const deleteSection = (key: number) => {
    const updatedSectionList =
        (!isVariants ? productForm : productFormWithVariants).template_details[0].custom_sections.filter(
          (section, k) => k !== key
        );

    if (!isVariants) {
      setProductForm({
        ...productForm,
        template_details: [
          {
            custom_sections: updatedSectionList,
          },
        ],
      });
    }else{

    setProductFormWithVariants({
      ...productFormWithVariants,
      template_details: [
        {
          custom_sections: updatedSectionList,
        },
      ],
    });
  }
  setIsChange(true);
  };

  const editSectionContent = (content: string, key: number) => {
    const updatedSectionList =
    (!isVariants ? productForm : productFormWithVariants).template_details[0].custom_sections.map((section, k) =>
        k === key ? { ...section, content } : section
      );

    if (!isVariants) {
      setProductForm({
        ...productForm,
        template_details: [
          {
            custom_sections: updatedSectionList,
          },
        ],
      });
    } else {

    setProductFormWithVariants({
      ...productFormWithVariants,
      template_details: [
        {
          custom_sections: updatedSectionList,
        },
      ],
    });
  }
  setIsChange(true);
  };

  const editSectionTitle = (title: string, key: number) => {
    const updatedSectionList =
    (!isVariants ? productForm : productFormWithVariants).template_details[0].custom_sections.map((section, k) =>
        k === key ? { ...section, title } : section
      );

    if (!isVariants) {
      setProductForm({
        ...productForm,
        template_details: [
          {
            custom_sections: updatedSectionList,
          },
        ],
      });
    } else {
      
    setProductFormWithVariants({
      ...productFormWithVariants,
      template_details: [
        {
          custom_sections: updatedSectionList,
        },
      ],
    });
  }
  setIsChange(true);
  };

  const handleSlugChange = (event: any) => {
    event.preventDefault();
    const { value } = event.target;
    const lastChar = value.at(-1);
    const isValidChar = /^[a-zA-Z0-9-]$/.test(lastChar);
    if (value === "" || (isValidChar && value.length < 30)) {
      if (!isVariants) {
        setProductForm({ ...productForm, slug: value.toLowerCase() }); 
      } else {
        setProductFormWithVariants({ ...productFormWithVariants, slug: value.toLowerCase() });
      }
      setIsChange(true);
    }
  };

  const handleChangeCategories = (newCategories: string[], name: string) => {
    if (!isVariants) {
      setProductForm({ ...productForm, categories: newCategories });
    } else {
      setProductFormWithVariants({...productFormWithVariants, categories: newCategories});
    }
    setIsChange(true);
  };

  const handleAddOption: () => void = () => {
    if (productForm.options.length < 10) {
      setProductForm({
        ...productForm,
        options: [...productForm.options, { ...INITIAL_OPTION, name: "" }],
      });
      setIsChange(true);
    }
  };

  const handleDeleteOption: (key: number) => void = (key) => {
    if (productForm.options.length > 1) {
      setProductForm({
        ...productForm,
        options: productForm.options.filter((option, i) => key !== i),
      });
      setIsChange(true);
    }
  };

  const addImage = (newImage: File) => {
    if (!isVariants) {
      if (
        productForm.images.length <= 5 &&
        !productForm.images.some(
          (img) => img.id === newImage.name + newImage.size
        )
      ) {
        if (isModify) {
          UploadPhotoProductShop(newImage, productForm.id, setProgress, () => {
            setIsChange(true);
          });
        } else {
          setProductForm({
            ...productForm,
            images: [
              ...productForm.images,
              {
                url: URL.createObjectURL(newImage),
                id: newImage.name + newImage.size,
                order: productForm.images.length,
              },
            ],
          });
          setImagesToUpload([
            ...imagesToUpload,
            {
              imageFile: newImage,
              id: newImage.name + newImage.size,
            },
          ]);
          setIsChange(true);
        }
      }
      
    } else {
      if (
        productFormWithVariants.images.length <= 5 &&
        !productFormWithVariants.images.some(
          (img) => img.id === newImage.name + newImage.size
        )
      ) {
        if (isModify) {
          UploadPhotoProductShop(newImage, productFormWithVariants.id, setProgress, () => {
            setIsChange(true);
          });
        } else {
          setProductFormWithVariants({
            ...productFormWithVariants,
            images: [
              ...productFormWithVariants.images,
              {
                url: URL.createObjectURL(newImage),
                id: newImage.name + newImage.size,
                order: productFormWithVariants.images.length,
              },
            ],
          });
          setImagesToUpload([
            ...imagesToUpload,
            {
              imageFile: newImage,
              id: newImage.name + newImage.size,
            },
          ]);
          setIsChange(true);
        }
      }
    }
  };

  const removeImage = (imageId: string) => {
    if (!isVariants) {
      if (isModify) {
        DeletePhotoProductShop(imageId, productForm.id, () => {
          setIsChange(true);
        });
      } else {
        setProductForm({
          ...productForm,
          images: productForm.images
            .filter((img) => imageId !== img.id)
            .map((img, key) => {
              return { ...img, order: key };
            }),
        });

        setImagesToUpload(imagesToUpload.filter((img) => imageId !== img.id));
        setIsChange(true);
      } 

    } else {
      if (isModify) {
        DeletePhotoProductShop(imageId, productFormWithVariants.id, () => {
          setIsChange(true);
        });
      } else {
        setProductFormWithVariants({
          ...productFormWithVariants,
          images: productFormWithVariants.images
            .filter((img) => imageId !== img.id)
            .map((img, key) => {
              return { ...img, order: key };
            }),
        });

        setImagesToUpload(imagesToUpload.filter((img) => imageId !== img.id));
        setIsChange(true);
      }
    }
  };

  const handleImagePositionChange = (
    position: number,
    direction: "left" | "right"
  ) => {
    if (!isVariants) {
      if (direction === "left" && position > 0) {
        const imagesLeftPart = productForm.images.slice(0, position - 1);
        const imagesRightPart = productForm.images.slice(
          position + 1,
          productForm.images.length
        );

        setProductForm({
          ...productForm,
          images: [
            ...imagesLeftPart,
            productForm.images[position],
            productForm.images[position - 1],
            ...imagesRightPart,
          ].map((img, key) => {
            return { ...img, order: key };
          }),
        });
        setIsChange(true);
      }
      if (direction === "right" && position < productForm.images.length - 1) {
        const imagesLeftPart = productForm.images.slice(0, position);
        const imagesRightPart = productForm.images.slice(
          position + 2,
          productForm.images.length
        );
        setProductForm({
          ...productForm,
          images: [
            ...imagesLeftPart,
            productForm.images[position + 1],
            productForm.images[position],
            ...imagesRightPart,
          ].map((img, key) => {
            return { ...img, order: key };
          }),
        });
        setIsChange(true);
      }
    } else {
      if (direction === "left" && position > 0) {
        const imagesLeftPart = productFormWithVariants.images.slice(0, position - 1);
        const imagesRightPart = productFormWithVariants.images.slice(
          position + 1,
          productFormWithVariants.images.length
        );
        setProductFormWithVariants({
          ...productFormWithVariants,
          images: [
            ...imagesLeftPart,
            productFormWithVariants.images[position],
            productFormWithVariants.images[position - 1],
            ...imagesRightPart,
          ].map((img, key) => {
            return { ...img, order: key };
          }),
        });
        setIsChange(true);
      }
      if (direction === "right" && position < productFormWithVariants.images.length - 1) {
        const imagesLeftPart = productFormWithVariants.images.slice(0, position);
        const imagesRightPart = productFormWithVariants.images.slice(
          position + 2,
          productFormWithVariants.images.length
        );
        setProductFormWithVariants({
          ...productFormWithVariants,
          images: [
            ...imagesLeftPart,
            productFormWithVariants.images[position + 1],
            productFormWithVariants.images[position],
            ...imagesRightPart,
          ].map((img, key) => {
            return { ...img, order: key };
          }),
        });
        setIsChange(true);
      }
    }
  };

  const handleIsVisible = () => {
    if (!isVariants) {
      setProductForm({ ...productForm, published: !productForm.published });   
    } else {
      setProductFormWithVariants({ ...productFormWithVariants, published: !productFormWithVariants.published });
    }
    setIsChange(true);
  };

  const handleProductChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key?: number
  ) => {
    if (!isVariants) {
      const { value } = e.target as HTMLInputElement;
      const selectedProd = productList.find((prod) => prod.title === value);
      if (selectedProd) {
        const slug = convertDescriptionToSlug(selectedProd!.title);
        setProductForm({
          ...productForm,
          title: selectedProd!.title,
          product_id: selectedProd!.id,
          slug,
        });
        setProduct(selectedProd);
        setIsChange(true);
      } else {
        setProduct({ title: value, id: product?.id ?? "" });
      }
    } else {

    const { value } = e.target as HTMLInputElement;
    const selectedProd = productList.find((prod) => prod.title === value);

      setProductFormWithVariants({
        ...productFormWithVariants,
        variants: productFormWithVariants.variants.map((variant, i) => {
          if (i === key) return { ...variant, name: selectedProd!.title, product:selectedProd!.id };
          return variant;
        }),
      });
  
      setIsChange(true);
    }
  };

  // Clear the product and name values when product field is empty
  const handleVariantProductFieldClear = (productId: string) =>{
  setProductFormWithVariants((prevProductFormWithVariants) => ({
    ...prevProductFormWithVariants,
    variants: prevProductFormWithVariants.variants.map((variant) =>
      variant.product === productId ? { ...variant, name: "", product: "" } : variant
    ),
  }));

}

  const handleLang = (option: any) => {
    const value = option[0];
    if (!isVariants) {
      if (value === "ar" || value === "fr" || value === "en") {
        setProductForm({ ...productForm, lang: option[0] });
        setIsChange(true);
      }
    } else{
      if (value === "ar" || value === "fr" || value === "en") {
        setProductFormWithVariants({ ...productFormWithVariants, lang: option[0] });
        setIsChange(true);
      }
    }
  };

  const handleRate = (option: any) => {
    const value = option[0];
    if (!isVariants) {
      if (value > 0 && value <= 5) {
        setProductForm({ ...productForm, rate: value });
        setIsChange(true);
      }
    } else {
      if (value > 0 && value <= 5) {
        setProductFormWithVariants({ ...productFormWithVariants, rate: value });
        setIsChange(true);
      }
    }
  };

  const handlePricingOption = (option: any) => {
    if (!isVariants) {
      setProductForm({ ...productForm, pricing_option: option[0] });
    } else {
      setProductFormWithVariants({ ...productFormWithVariants, pricing_option: option[0] });
    }
    setIsChange(true);
  };

  const handleSave = () => {
    const pricing_option =
      productForm.pricing_option !== DEFAULT_PRICING_OPTION
        ? productForm.pricing_option
        : null;
    const newProduct  = {
      ...productForm,
      pricing_option,
    } 

    const newProductWithVariants = {
      ...productFormWithVariants,
      pricing_option,
    }
    if (isModify) {
      Event("SHOP", "MODIFY_SHOP_PRODUCT", "CLICK_EVENT");
      if (!isVariants) {
        ModifyProductShop(dataShop, newProduct, () => {
          setIsChange(false);
          SendAlert("41", Translate("shop", "changesSaved"));
          history.push("/shop/landing-page");
        });
      } else {
        ModifyProductShopWithVariants(dataShop, newProductWithVariants, () => {
          setIsChange(false);
          SendAlert("41", Translate("shop", "changesSaved"));
          history.push("/shop/landing-page");
        })
      }
    } else {
      Event("SHOP", "CREATE_SHOP_PRODUCT", "CLICK_EVENT");
      const images = imagesToUpload.map((elem) => elem.imageFile);
      if (!isVariants) {
        CreateProductShop(newProduct, images, dataShop, setProgress, () => {
          setImagesToUpload([]);
          setIsChange(false);
          history.push("/shop/landing-page");
        });

      } else {
        CreateProductShopWithVariants(newProductWithVariants, images, dataShop, setProgress, () => {
          setImagesToUpload([]);
          setIsChange(false);
          history.push("/shop/landing-page");
        });
      }
    }
  };

  const handlePreview = () => {
    Event("SHOP", "PREVIEW_LANDING_PAGE", "CLICK_EVENT");
    localStorage.setItem(
      `landing-page-${SESSION_ID}`,
      JSON.stringify({
        product: !isVariants ? productForm : productFormWithVariants,
        currency:
          (!isVariants? productForm.lang === "ar" : productFormWithVariants.lang === "ar")
            ? dtStoreinfo?.country.ar_currency
            : dtStoreinfo?.country?.lt_currency,
        isVariants
      })
    );
    const win = window.open(
      `/shop/landing-page/preview?sessionId=${SESSION_ID}`,
      "_blank"
    );
    win?.focus();
  };

  const deliveryPricingOption = useMemo(
    () =>
      dataShop?.pricingOptions?.map((pr) => [
        pr.id,
        pr.id === DEFAULT_PRICING_OPTION
          ? Translate("shop", "defaultPricingOption")
          : pr.name,
      ]),
    [dataShop]
  );

  const deliveryPricingOptionValue = useMemo(
    () =>
      deliveryPricingOption?.find(
        (arr) => !isVariants ? arr[0] === productForm.pricing_option : arr[0] === productFormWithVariants.pricing_option
      ),
    [deliveryPricingOption, productForm, productFormWithVariants, isVariants]
  );

const currentForm = isVariants ? productFormWithVariants : productForm;

const hasValidDisplayIdOrProductId = productForm.display_id || productForm.product_id;
const hasValidTitle = currentForm.title;
const hasValidSlug = slugRegex.test(currentForm.slug);
const hasValidOptions = productForm.options.every(
  (option) =>
    option.name &&
    option.quantity &&
    priceChecker(option.price, option.reduced_price)
);
const hasDuplicateOptionNames = productForm.options.some((option, key) =>
  productForm.options.some(
    (opt, k) => key !== k && opt.name === option.name
  )
);
const hasImages = currentForm.images.length >= 1;
const hasValidDescription = currentForm.description;
const hasValidPricingOption = currentForm.pricing_option;
const hasCustomSections = currentForm.template_details[0]?.custom_sections.some(
  (section: customSection) => !section.title || !section.content
);

const hasValidVariants = productFormWithVariants.variants.every(
  (variant) =>
    variant.name &&
    variant.product &&
    priceChecker(variant.price, variant.reduced_price)
);

const hasDuplicateVariantNames = productFormWithVariants.variants.some((variant, key) =>
  productFormWithVariants.variants.some(
    (va, k) => key !== k && va.name.toLowerCase() === variant.name.toLowerCase()
  )
);

const isPreviewButtonDisabled =
  shopLoading ||
 ( !isVariants && !hasValidDisplayIdOrProductId) ||
  !hasValidTitle ||
  !hasValidSlug ||
  !hasImages ||
  !hasValidDescription ||
  !hasValidPricingOption ||
  hasCustomSections ||
  (!isVariants ? 
  !hasValidOptions ||
  hasDuplicateOptionNames 
  :
  !hasValidVariants ||
  hasDuplicateVariantNames
  )

  const IsSaveButtonDisabled = isPreviewButtonDisabled || !isChange;

   const handleAddVariant = () => {
      setProductFormWithVariants(productFormWithVariants => {
        return {
          ...productFormWithVariants,
          variants: [
              ...productFormWithVariants.variants,
              {
                  name: "",
                  price: productFormWithVariants.variants[0].price,
                  reduced_price:  productFormWithVariants.variants[0].reduced_price,
                  product: ""
              }
          ],
        }
      });
      setIsChange(true);
  }

  const handleDeleteVariant: (id: string, index: number) => void = (id, index) => {
      setProductFormWithVariants(productFormWithVariants => {
        return {
          ...productFormWithVariants,
          variants: productFormWithVariants.variants.filter((variant, i) => {
            if (id) return variant.product !== id
            return index !== i           
            })
        }
      })
      setIsChange(true);
  };

  const handleVariantTitleChange = (event: React.ChangeEvent<HTMLInputElement>, key:number) => {
    const { value } = event.target as HTMLInputElement;

    setProductFormWithVariants(productFormWithVariants =>{ 
      return {
      ...productFormWithVariants,
      variants:  productFormWithVariants.variants.map((variant, idx) => idx === key ? {...variant, name: value} : variant)
    }});

  }

  const handleVariantProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    const slug = convertDescriptionToSlug(value);

    setProductFormWithVariants(productFormWithVariants => {
      return {
        ...productFormWithVariants,
        title: value,
        slug
      }
    })
  }

  return (
    <ContainerPage
      responsive
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst StOpcVal">
            {Translate("orders", "flech")}
          </div>
          <Link
            to={"/shop/landing-page"}
            onClick={() => {
              Event(
                "SHOP",
                `BREADCRUMB_FROM_LANDING_PAGE_FORM_TO_LANDING_PAGE`,
                "CLICK_EVENT"
              );
            }}
          >
            <span className="StOpcVal">{Translate("shop", "landingPage")}</span>
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">
            {idprod === "add" || idprod === "addwithvariants"
              ? Translate("shop", "createLandingPage")
              : Translate("shop", "editLandingPage")}
          </span>
        </div>
      }
      data_top={
        !isLoading && (
          <div className="InFlx">
            <Button
              clName="bundle-button"
              BtnText={Translate("shop", "preview")}
              onClick={() => {
                if (!isPreviewButtonDisabled) {
                  handlePreview();
                }
              }}
              style={{
                border: "none",
                color: "#008AFF",
                fontSize: "16px",
                width: "150px",
              }}
              disabled={isPreviewButtonDisabled}
            />
            <Button
              clName="bundle-button"
              BtnText={
                shopLoading ? LoadingSmallSvg : Translate("shop", "save")
              }
              onClick={() => {
                if (!IsSaveButtonDisabled) {
                  handleSave();
                }
              }}
              style={{
                backgroundColor: "#008AFF",
                color: "white",
                fontSize: "16px",
                width: "150px",
              }}
              disabled={IsSaveButtonDisabled}
            />
          </div>
        )
      }
    >
      {isLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : (
        <>
          <Prompt when={isChange} message={Translate("alert", "prompt")} />
          <div className="LandingPageForm">
            <div>
              <ContainerPage
                subContainer
                page_title={Translate("product", "productdetails")}
                small_title={true}
                withOvrl
                componentClass="landingPage-container hAuto"
              >
                <div
                  className={`align-items-end grid marginBottom1 ${
                    (idprod === "add" ) && !isVariants ? "grid-1-1-1" : "grid-1-1"
                  }`}
                >
                  {(idprod === "add" ) && !isVariants && (
                    <ShopSelectFormSearch
                      id="product"
                      name="product"
                      index="title"
                      option={productList}
                      text={Translate("product", "product")}
                      workfun={handleProductChange}
                      placeholder={Translate(
                        "shop",
                        "selectedProductPlaceholder"
                      )}
                      value={product?.title}
                      stclass={!productForm.product_id ? "borderError" : ""}
                      required
                    />
                  )}
                  {!isVariants && (
                    <GroupeForm
                      id="title"
                      name="title"
                      placeholder={Translate("shop", "titlePlaceHolder")}
                      text={Translate("shop", "title")}
                      type="text"
                      value={productForm.title}
                      workfun={handleChange}
                      stclass={!productForm.title ? "borderError" : ""}
                      required
                    />
                  )}

                  {isVariants && (
                    <GroupeForm
                      id="product-name"
                      name="title"
                      placeholder={Translate("shop", "productNamePlaceholder")}
                      text={Translate("shop", "productName")}
                      type="text"
                      value={productFormWithVariants.title}
                      workfun={handleVariantProductNameChange}
                      stclass={!productFormWithVariants.title ? "borderError" : ""}
                      required
                    />
                  )}
                  <div className="Flwd InFlx flxDrc AlgnItm RlPs">
                    <GroupeForm
                      id="slug"
                      name="slug"
                      placeholder={Translate("shop", "slugPlaceHolder")}
                      text={Translate("shop", "slug")}
                      pattern="^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$"
                      type="text"
                      value={!isVariants ? productForm.slug : productFormWithVariants.slug}
                      workfun={handleSlugChange}
                      style={{ width: "100%" }}
                      stclass={
                        !slugRegex.test(!isVariants ? productForm.slug : productFormWithVariants.slug) ? "borderError" : ""
                      }
                      required
                    />
                    <div className="StAbs slug-slash">/</div>
                  </div>
                </div>
                <div className="marginTop1">
                  <RichTextEditor
                    generateBtn={token === "bfdc78ab1be1e82cbe65c1ef5102fb878fb5213a"}
                    loadingGenerateBtn={loading}
                    disabledGenerateBtn={productForm.product_id === "" || loading}
                    onClickGenerateBtn={handleGenerateDescreptionWithAI}
                    generatedBtnSvg={aiStatrsSvg}
                    title={Translate("shop", "description")}
                    id="description"
                    value={!isVariants ? productForm.description : productFormWithVariants.description}
                    setValue={(content) =>
                      handleRichTextChange(content, "description")
                    }
                    isError={!isVariants ? !productForm.description : !productFormWithVariants.description}
                  />
                  <SectionList
                    sections={!isVariants ? productForm.template_details[0].custom_sections : productFormWithVariants.template_details[0].custom_sections}
                    addSection={addSection}
                    deleteSection={deleteSection}
                    editSectionContent={editSectionContent}
                    editSectionTitle={editSectionTitle}
                  />
                </div>
                <ImagesList
                  images={!isVariants ? productForm.images : productFormWithVariants.images}
                  addImage={addImage}
                  removeImage={removeImage}
                  changePosition={handleImagePositionChange}
                  progress={progress}
                  shopLoading={shopLoading}
                />
                <div className="rate">
                  <Select
                    label={Translate("shop", "rate")}
                    Options={[["5.00"], ["4.50"], ["4.00"], ["3.50"], ["3.00"]]}
                    fieldShow={0}
                    name="rate"
                    value={!isVariants ? [productForm.rate] : [productFormWithVariants.rate]}
                    onChange={handleRate}
                    maxSize="200px"
                  />
                </div>
              </ContainerPage>
              {!isVariants ? (
                <ContainerPage
                  subContainer
                  page_title={Translate("shop", "productPricingOptions")}
                  small_title={true}
                  withOvrl
                  componentClass="landingPage-container hAuto"
                >
                  {productForm.options.length > 1 ? (
                    <ProductPricingOptionsFields
                      options={productForm.options}
                      handleChange={handleChange}
                      handleDeleteOption={handleDeleteOption}
                      handleAddOption={handleAddOption}
                    />
                  ) : (
                    <>
                      <div className="grid grid-1-1 RlPs marginTopSmall OptionsField">
                        <GroupeForm
                          id={`price-option-${0}`}
                          name="price"
                          text={Translate("shop", "price")}
                          type="number"
                          placeholder={Translate("shop", "price")}
                          min={0}
                          value={productForm.options[0].price}
                          workfun={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => {
                            handleChange(e, 0);
                          }}
                          inputStyle={
                            productForm.options[0].reduced_price
                              ? { textDecoration: "line-through" }
                              : {}
                          }
                          stclass={
                            !priceChecker(
                              productForm.options[0].price,
                              productForm.options[0].reduced_price
                            )
                              ? "borderError"
                              : ""
                          }
                          required
                        />
                        <GroupeForm
                          id={`reduced_price-option-${0}`}
                          name="reduced_price"
                          text={Translate("shop", "reducedPrice")}
                          type="number"
                          placeholder={Translate("shop", "reducedPrice")}
                          min={0}
                          value={productForm.options[0].reduced_price}
                          workfun={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                          ) => {
                            handleChange(e, 0);
                          }}
                        />
                      </div>
                      <p className="pricing-text">
                        {Translate("shop", "productPricingOptionText")}
                      </p>
                      <div
                        className="addOptionButton-main"
                        onClick={handleAddOption}
                      >
                        + {Translate("shop", "addOption")}
                      </div>
                    </>
                  )}
                </ContainerPage>
              ) : (
                <ContainerPage
                  subContainer
                  page_title={Translate("shop", "variantsSetup")}
                  small_title={true}
                  withOvrl
                  componentClass="landingPage-container hAuto"
                >

                  {productFormWithVariants.variants.map((variant,idx)=>{
                  return (
                    <VariantsSetupFields
                        key={variant.product || idx}
                        productList={productList}
                        handleChange={handleChange}
                        onDeleteVariant={handleDeleteVariant}
                        handleProductChange={(e) => handleProductChange(e,idx)}
                        variants={productFormWithVariants.variants}
                        showDeleteIcon={idx > 0 }
                        index={idx}
                        onVariantTitleChange={(e) => handleVariantTitleChange(e,idx)}
                        product={product}
                        variant={variant}
                        isModify={isModify}
                        onVariantProductNameChange = {handleVariantProductFieldClear}
                    />)})}

                    <div className="addOptionButton" onClick={handleAddVariant}>
                        + {Translate("shop", "addAnotherVariant")} 
                    </div>
                </ContainerPage>
              )}
            </div>
            <div>
              <div>
                <ContainerPage
                  subContainer
                  page_title={Translate("shop", "deliveryDetails")}
                  small_title={true}
                  withOvrl
                  componentClass="landingPage-container hAuto"
                >
                  <Select
                    label={Translate("shop", "choosePricingOption")}
                    Options={deliveryPricingOption}
                    fieldShow={1}
                    name="pricing_option"
                    value={deliveryPricingOptionValue}
                    onChange={handlePricingOption}
                    maxSize="200px"
                  />
                </ContainerPage>
                <ContainerPage
                  subContainer
                  page_title="SEO"
                  small_title={true}
                  withOvrl
                  componentClass="landingPage-container hAuto"
                >
                  <TagInput
                    text={Translate("shop", "productCategories")}
                    placeholder={Translate("shop", "categoryPlaceholder")}
                    name="categories"
                    values={ !isVariants ? productForm.categories : productFormWithVariants.categories}
                    handleChange={handleChangeCategories}
                    optional
                  />
                </ContainerPage>
                <ContainerPage
                  subContainer
                  page_title={Translate("shop", "landingPage")}
                  small_title={true}
                  withOvrl
                  componentClass="landingPage-container hAuto"
                >
                  <Select
                    label={Translate("shop", "chooseLanguage")}
                    Options={languages}
                    fieldShow={1}
                    name="lang"
                    value={languages.find((arr) => !isVariants ? arr[0] === productForm.lang : arr[0] === productFormWithVariants.lang)}
                    onChange={handleLang}
                    maxSize="200px"
                  />
                  <div className="is-visible-toggle">
                    <Switch
                      checked={!isVariants ? productForm.published : productFormWithVariants.published}
                      onClick={handleIsVisible}
                    />
                    <p>{Translate("shop", "makeProductVisible")}</p>
                  </div>
                </ContainerPage>
              </div>
            </div>
          </div>
        </>
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const {
    dataShop,
    shopError,
    shopLoading,
    productShopPhoto,
    deletedShopPhoto,
  } = state.shop;
  const { dtStoreinfo } = state.user;
  const { ProductFinded, data } = state.product;
  return {
    dtStoreinfo,
    ProductFinded,
    data,
    dataShop,
    shopError,
    shopLoading,
    productShopPhoto,
    deletedShopPhoto,
  };
}

const actionCreators = {
  GetAllProduct: productActions.GetAllProduct,
  GetShopInfoWithPricing: shopActions.GetShopInfoWithPricing,
  ModifyProductShop: shopActions.ModifyProductShop,
  ModifyProductShopWithVariants: shopActions.ModifyProductShopWithVariants,
  CreateProductShop: shopActions.CreateProductShop,
  CreateProductShopWithVariants: shopActions.CreateProductShopWithVariants,
  DeletePhotoProductShop: shopActions.DeletePhotoProductShop,
  UploadPhotoProductShop: shopActions.UploadPhotoProductShop,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(LandingPageForm);
