import React, { useState, useEffect, useRef } from "react";
import { Translate } from "../../utils/lang/translate";

function arrayEquals(a: any[], b: any[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

interface Props {
  values: any;
  text: string;
  name: string;
  handleChange: (newCategories: string[], name: string) => void;
  id?: string;
  more?: string;
  style?: React.CSSProperties;
  stclass?: string;
  placeholder?: string;
  defaultValue?: any;
  readOnly?: boolean;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  optional?: boolean;
}

const TagInput: React.FC<Props> = ({
  style,
  id,
  text,
  stclass,
  handleChange,
  name,
  placeholder,
  values,
  defaultValue,
  readOnly,
  disabled,
  error,
  more,
  required,
  optional,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [canHandleChange, setCanHandleChange] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof values === "object") setCanHandleChange(true);
    if (!arrayEquals(selectedValues, values)) {
      setSelectedValues(values);
    }
  }, [values]);

  useEffect(() => {
    if (canHandleChange && !arrayEquals(selectedValues, values))
      handleChange(selectedValues, name);
  }, [selectedValues]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;

    if (value.length < inputValue.length)
      setInputValue(value);
    if (value.length > inputValue.length) {
      const newChar = value.at(-1) as string;
      if (/^[a-z0-9\u0621-\u064A\u0660-\u0669]$/.test(newChar))
        setInputValue(value);
      if (newChar === " " || newChar === ",")
        handleAddTag();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAddTag();
  }

  const handleTextPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const clipBoardText = e.clipboardData.getData('text');
    const reduceInit: { newInputValue: string; newSelectedValues: string[] } = {
      newInputValue: "",
      newSelectedValues: [],
    };
    const { newInputValue, newSelectedValues } = clipBoardText
      .toLowerCase()
      .split("")
      .filter((char) => /^[,a-z0-9\u0621-\u064A\u0660-\u0669]$/.test(char))
      .reduce((acc, curr) => {
        if (curr !== ",")
          return { ...acc, newInputValue: acc.newInputValue.concat(curr) };
        return {
          newInputValue: "",
          newSelectedValues: [...acc.newSelectedValues, acc.newInputValue],
        };
      }, reduceInit);
    const tagsToAdd = handleMultipleNewTags([
      ...newSelectedValues,
      newInputValue,
    ]);
    setSelectedValues([...selectedValues, ...tagsToAdd]);
  };

  const handleAddTag = () => {
    if (
      !selectedValues.find((val) => val === inputValue) &&
      inputValue.length > 0
    )
      setSelectedValues([...selectedValues, inputValue]);
    setInputValue("");
  };

  const handleDeleteTag = (tag: string) => {
    setSelectedValues(selectedValues.filter((val) => val !== tag));
  };

  const handleMultipleNewTags = (newTags: string[]): string[] => {
    return newTags.filter(
      (newTag) =>
        newTag.length > 0 && !selectedValues.some((tag) => tag === newTag)
    );
  };

  return (
    <form 
      className="grp-fm StdirLn StFnt" 
      style={style}
      onSubmit={handleSubmit}
    >
      <div className="SsGrpRm RlPs InFlx responsFlx spcBtwn flxDrc">
        <label htmlFor={id}>
          {text}
          {optional && <span> ({Translate("shop", "optional")})</span>}
        </label>
        <div className="StOpcVal">{more}</div>
      </div>
      <div
        className="SsGrpRm StMrg RlPs InFlx lytwdp2p FrInp StBrdRd AlgnItm cursor-text"
        style={{ flexWrap: "wrap" }}
        onClick={() => {
          if (inputRef.current) inputRef.current.focus();
        }}
      >
        {selectedValues?.map((val) => (
          <BubbleTag key={val} tagName={val} deleteTag={handleDeleteTag} />
        ))}
        <input
          ref={inputRef}
          className={
            "BubbleInput FntfMin StdirLn StSizLn StHgInp Fntcl " +
            (stclass ? stclass : "")
          }
          type="text"
          name={name}
          id={id}
          defaultValue={defaultValue}
          value={inputValue}
          placeholder={placeholder}
          onChange={handleInputChange}
          onPaste={handleTextPaste}
          autoComplete="off"
          aria-autocomplete={"none"}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          size={inputValue.length > 10 ? inputValue.length : 10}
        />
        <span className="StRedClr">{error}</span>
      </div>
    </form>
  );
};

interface TagBubbleProps {
  tagName: string;
  deleteTag: (tag: string) => void;
}

const BubbleTag: React.FC<TagBubbleProps> = ({ tagName, deleteTag }) => {
  return (
    <div className="BubbleTag">
      {tagName} <span onClick={() => deleteTag(tagName)}>x</span>
    </div>
  );
};

export default TagInput;
