import { style } from "@mui/system";
import React from "react";
import { Translate } from "../../../utils/lang/translate";
import {
  fleshStockSvg,
  fleshBashSvg,
  upLightSVG,
  downLightSVG,
} from "../SideBar/SideBarSvg";
import Cookies from "js-cookie";
export default function ElementSideDrop(props) {
  return (
    <div style={{ cursor: "pointer" }}>
      <div className="FlWd StHgt RlPs SmMarS ">
        {!props.reduce && (
          <span
            className={
              props.active
                ? "StAbs FlHg  StLanNlin BrdLft StTpNl WdSmP StTrns bgselectstock"
                : ""
            }
          ></span>
        )}
        <div
          className={
            props.active
              ? "bgselectstock RglWdM FlHg StBrdRdS StTrns"
              : "RglWdM FlHg StBrdRdS StTrns"
          }
        >
          <div
            className={
              "InFlx AlgnItm flxDrc FlHg FlWd " +
              (props.reduce ? "JstfCnt" : "")
            }
          >
            <div className="">
              <div className="InFlx">{props.icon}</div>
            </div>
            {!props.reduce && (
              <div className="FlHg InvStMrtg FlWd">
                <div className="TbDp RlPs FlWd FlHg InFlx spctwB AlgnItm">
                  <div className="TbCdp RlPs TxtCn VrAlg">
                    <span className="StSmlS">{props.text}</span>
                  </div>
                  {props.new ? (
                    <div className="marInStart InFlx AlgnItm">
                      <div
                        style={{
                          backgroundColor: "#2BC194",
                          padding: "4px 10px",
                          borderRadius: "500px",
                          fontSize: "14px",
                        }}
                      >
                        {Translate("shop", "new")}
                      </div>
                    </div>
                  ) : props.select ? (
                    <div
                      style={{
                        marginTop: "4px",
                      }}
                    >
                      {fleshStockSvg}
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "4px",
                      }}
                    >
                      {fleshBashSvg}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
