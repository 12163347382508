import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { alertActions } from "../../../actions";
import { shopActions } from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { Translate } from "../../../utils/lang/translate";
import { Event, initGAPg } from "../_Common";
import { DataShop, ProductShop, ProductShopVariants } from '../../../types';
import TableLoader from "../_Common/TableLoader";
import { EmptyScreen } from "../_Common/EmptyScreen";
import Button from "../../_Common/Button";
import Table from "../_Common/Table";
import GroupeBtn from "../_Common/GroupeBtn";
import { DeletSvg, EditSvg, ViewSvg, linkSvg } from "../_Common/IconSvg";
import ConfirmationAlert from "../../_Common/ConfirmationAlert";
import "./LandingPageList.scss";
import LandingOptionsButton from "../_Common/LandingOptionsButton";

type CombinedProductShopType = ProductShop & {
  variants: ProductShopVariants[];
  is_variant: boolean;
}

interface CombinedDataShopType extends DataShop {
  products: CombinedProductShopType[];
};	

interface Props {
  history: any;
  GetLandingPageInfo: () => void;
  DeleteProductShop: (
    id: string,
    dataShop: CombinedDataShopType,
    successCallback?: () => void
  ) => void;
  dataShop: CombinedDataShopType;
  shopLoading: boolean;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const LandingPageList: React.FC<Props> = ({
  GetLandingPageInfo,
  DeleteProductShop,
  dataShop,
  shopLoading,
  SendAlert,
  history,
}) => {
  const [deleteShopProductId, setDeleteShopProductId] = useState<string | null>(
    null
  );

  useEffect(() => {
    initGAPg();
    GetLandingPageInfo();
  }, []);

  useEffect(() => {
    if (!shopLoading && dataShop) {
      if (!dataShop.published) history.push("/shop/settings");
    }
  }, [dataShop]);

  const handleDeleteFromShop = () => {
    if (deleteShopProductId) {
      setDeleteShopProductId(null);
      DeleteProductShop(deleteShopProductId, dataShop, () => {
        SendAlert("41", Translate("shop", "changesSaved"));
      });
    }
  };


  const bodyTab = useMemo(
    () =>
      dataShop?.products?.map((shopProduct) => {
        const productName = shopProduct.title;
        let price = 0;
        let reducedPrice: number | null = null;
        let thePrice: number = 0

        if (!shopProduct.is_variant) {
          if (shopProduct.options[0]) {
            price =
              typeof shopProduct.options[0].price === "string"
                ? parseInt(shopProduct.options[0].price)
                : shopProduct.options[0].price;
            reducedPrice =
              typeof shopProduct.options[0].reduced_price === "string"
                ? parseInt(shopProduct.options[0].reduced_price)
                : shopProduct.options[0].reduced_price;
            thePrice = reducedPrice ?? price;
          }
        } else {
          if (shopProduct.variants[0]) {
            price = +shopProduct.variants[0].price;
            reducedPrice = shopProduct.variants[0].reduced_price ? +shopProduct.variants[0].reduced_price : null;
            thePrice = reducedPrice ?? price;
          }
        }

        const statusClass = shopProduct.published ? "active" : "inactive";
        const statusText = Translate("shop", statusClass);
        const statusDiv = (
          <div className={`ShopProductStatus ${statusClass}`}>{statusText}</div>
        );

        let actionButtons: any[] = [];

        actionButtons.push({
          type: `BDgInpc ${!shopProduct.published && "notAllowed"}`,
          action: () => {
            if (shopProduct.published) {
              Event("SHOP", "VISIT_LANDING_PAGE", "CLICK_EVENT");
              window.open(shopProduct.landing_page, "_blank");
            }
          },
          svgbtn: ViewSvg,
          tooltip: shopProduct.published
            ? Translate("product", "visitLandingPage")
            : "",
        });

        actionButtons.push({
          type: "BDgInpc",
          action: () => {
            Event("SHOP", "EDIT_SHOP_PRODUCT_BUTTON", "CLICK_EVENT");
            history.push(`/shop/landing-page/${shopProduct.id}`);
          },
          svgbtn: EditSvg,
          tooltip: Translate("shop", "modifyProductShop"),
        });

        actionButtons.push({
          type: "BDgInpc",
          action: () => {
            Event("SHOP", "DELETE_SHOP_PRODUCT_BUTTON", "CLICK_EVENT");
            setDeleteShopProductId(shopProduct.id);
          },
          svgbtn: DeletSvg,
          tooltip: Translate("shop", "deleteFromShop"),
        });

        actionButtons.push({
          type: `BDgInpc ${!shopProduct.published && "notAllowed"}`,
          action: () => {
            if (shopProduct.published) {
              Event("SHOP", "COPY_LANDING_PAGE_LINK", "CLICK_EVENT");
              navigator.clipboard.writeText(shopProduct.landing_page);
              SendAlert("41", Translate("shop", "copySuccess"));
            }
          },
          svgbtn: linkSvg,
          tooltip: shopProduct.published
            ? Translate("shop", "copyLandingPage")
            : "",
        });

        const actions = <GroupeBtn data_btn={actionButtons} />;
        return [productName, thePrice, statusDiv, actions];
      }),
    [dataShop]
  );

  const hasShopProducts =
    Array.isArray(dataShop?.products) && dataShop.products.length > 0;

  return (
    <ContainerPage
    responsive={true}
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">{Translate("shop", "landingPage")}</span>
        </div>
      }
      data_top={
        !shopLoading &&
        Array.isArray(dataShop?.products) && (
          <LandingOptionsButton/>
        )
      }
    >
      {shopLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : !hasShopProducts ? (
        <EmptyScreen
          PictShow="/assets/img/no_landing_pages.png"
          titleGlb={Translate("shop", "noLandingPageTitle")}
          TextShow={Translate("shop", "noLandingPageText")}
          actionBtns={[
            {
              text: Translate("shop", "createLandingPage"),
              to: "/shop/landing-page/add",
            },
          ]}
        />
      ) : (
        <div className="LandingPageList">
          <Table
            thead_elem={[
              Translate("product", "productname"),
              Translate("payement", "price"),
              Translate("orders", "status"),
              Translate("product", "action"),
            ]}
            tbody_elem={bodyTab}
          />
          {deleteShopProductId && (
            <ConfirmationAlert
              title={Translate("shop", "deleteProductMaystroStore")}
              text={Translate("product", "deleteFromShopConfirmation")}
              onClose={() => {
                Event("SHOP", "DELETE_SHOP_PRODUCT_NO", "CLICK_EVENT");
                setDeleteShopProductId(null);
              }}
              onConfirm={() => {
                Event("SHOP", "DELETE_SHOP_PRODUCT_YES", "CLICK_EVENT");
                handleDeleteFromShop();
              }}
            />
          )}
        </div>
      )}
    </ContainerPage>
  );
};
function mapState(state: any) {
  const { dataShop, shopLoading } = state.shop;
  return {
    dataShop,
    shopLoading,
  };
}

const actionCreators = {
  GetLandingPageInfo: shopActions.GetLandingPageInfo,
  DeleteProductShop: shopActions.DeleteProductShop,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(LandingPageList);
