import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { alertActions } from "../../../../actions";
import {
  DataShop,
  UpdateDataShopSettings,
  UploadDataShopLogoSettings,
} from "../../../../types";
import "./ShopSettings.scss";
import TableLoader from "../../_Common/TableLoader";
import { shopActions } from "../../../../actions";
import NavigationComputer from "./NavigationComputer";
import NavigationMobileTop from "./NavigationMobileTop";
import NavigationMobileBottom from "./NavigationMobileBottom";
import ContainerPage from "../../_Common/ContainerPage";
import { Translate } from "../../../../utils/lang/translate";
import { Link } from "react-router-dom";
import { Event } from "../../_Common";

interface Props {
  history: any;
  location: any;
  dataShop: DataShop;
  shopLoading: boolean;
  shopError: any;
  GetShopInfo: () => void;
  UpdateDataShop: (
    data: DataShop,
    settings: UpdateDataShopSettings,
    successCallback?: () => void
  ) => void;
  UploadDataShopLogo: (
    data: DataShop,
    settings: UploadDataShopLogoSettings,
    logo: File,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
  children: React.ReactElement<any>;
}

const ShopContainer: React.FC<Props> = ({
  history,
  location,
  dataShop,
  shopLoading,
  shopError,
  GetShopInfo,
  UpdateDataShop,
  UploadDataShopLogo,
  children,
  SendAlert,
}) => {
  const redirectIfUserCantVisitPage = () => {
    const hasPermissionTheme =
      location.pathname === "/shop/settings/theme" &&
      dataShop.settings_stage >= 1;
    const hasPermissionDomain =
      location.pathname === "/shop/settings/domain" &&
      dataShop.settings_stage >= 2;
    const hasPermissionFacebook =
      location.pathname === "/shop/settings/facebook" &&
      dataShop.settings_stage >= 3;
    const hasPermissionFinish =
      location.pathname === "/shop/settings/finish" &&
      dataShop.settings_stage >= 4;

    if (
      !hasPermissionTheme ||
      !hasPermissionDomain ||
      !hasPermissionFacebook ||
      !hasPermissionFinish
    ) {
      if (dataShop.settings_stage === 0) history.push("/shop/settings/store");
      if (dataShop.settings_stage === 1) history.push("/shop/settings/theme");
      if (dataShop.settings_stage === 2) history.push("/shop/settings/domain");
      if (dataShop.settings_stage === 3)
        history.push("/shop/settings/facebook");
    }
  };

  useEffect(() => {
    GetShopInfo();
  }, []);

  useEffect(() => {
    if (dataShop && !shopLoading) {
      redirectIfUserCantVisitPage();
    }
  }, [dataShop]);

  return (
    <ContainerPage
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst StOpcVal">
            {Translate("orders", "flech")}
          </div>
          <Link
            to="/shop/settings"
            onClick={() => {
              Event(
                "SHOP",
                `BREADCRUMB_FROM_SHOP_SETTINGS_TO_MAIN_SETTINGS`,
                "CLICK_EVENT"
              );
            }}
          >
            <span className="StOpcVal">{Translate("titles", "settings")}</span>
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">{Translate("shop", "setupShop")}</span>
        </div>
      }
    >
      <div className="InFlx flxDrc responsFlx FlWd JstfCnt">
        <div style={{ width: "100%" }}>
          <div className="ShopContainer">
            {shopLoading && (
              <div className="RlPs" style={{ paddingTop: "60px" }}>
                <TableLoader />
              </div>
            )}
            {dataShop && !shopLoading && (
              <>
                <NavigationComputer
                  dataShop={dataShop}
                  history={history}
                  location={location}
                />
                <NavigationMobileTop
                  dataShop={dataShop}
                  history={history}
                  location={location}
                />
                <div className="children">
                  {children &&
                    React.cloneElement(children, {
                      dataShop,
                      shopLoading,
                      shopError,
                      UpdateDataShop,
                      UploadDataShopLogo,
                      history,
                      SendAlert,
                    })}
                </div>
                <NavigationMobileBottom
                  dataShop={dataShop}
                  history={history}
                  location={location}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { shopLoading, dataShop, shopError } = state.shop;
  return { shopLoading, dataShop, shopError };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
  UpdateDataShop: shopActions.UpdateDataShop,
  UploadDataShopLogo: shopActions.UploadDataShopLogo,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(ShopContainer);
