import React, { useEffect, useState } from 'react'
import ArrowSvg from '../_Common/ArrowSvg';
import PlusSvg from '../_Common/PlusSvg';
import CloseSvg from '../_Common/CloseSvg';
import { ProductShopWithVariants } from '../../../types';

interface Variant {
    name: string;
    price: string;
    reduced_price: string | null;
    product:string,
  }
  
  interface SelectedVariant extends Variant {
    quantity: number;
  }

  type Props = {
    onVariantChange: (variant: Variant) => void,
    product: ProductShopWithVariants,
    theme: string,
    onOrderChange : (totalPrice: number) =>void,
    lang: "en" | "fr" | "ar",
  }

  const variantsDict = {
    en: "Variants",
    fr: "Variantes",
    ar: "المتغيرات",
  }

  const quantityDict = {
    en: "Quantity",
    fr: "Quantité",
    ar: "الكمية",
  }

const LandingVariantsSection = ({product, onVariantChange, theme, onOrderChange, lang} : Props) => {


  const variants = product.variants
  const [quantity, setQuantity] = useState<number>(1)
  const [selectedVariant, setSelectedVariant] = useState<Variant | null | undefined>(variants[0]);
  const [selectedVariants, setSelectedVariants] = useState<SelectedVariant[]>([]);  

  useEffect(()=>{
    calculateOrderPrice()
   },[selectedVariants])
  
   const handleVariantSelection = (variantName:string) =>{
      const currentVariant = variants.find(variant => variant.name.toLowerCase() === variantName.toLowerCase())
       setSelectedVariant(currentVariant)
       onVariantChange(currentVariant as Variant)
       setQuantity(1)
   }

   const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };
 
  const handleAddVariant = () => {
    if (selectedVariant) {
      const existingVariantIndex = selectedVariants.findIndex(
        (variant) => variant.name === selectedVariant.name
      );

      if (existingVariantIndex !== -1) {
        setSelectedVariants((prevVariants) =>
          prevVariants.map((variant, index) =>
            index === existingVariantIndex
              ? { ...variant, quantity: variant.quantity + quantity }
              : variant
          )
        );
      } else {
        const newVariant: SelectedVariant = { ...selectedVariant, quantity };
        setSelectedVariants((prevVariants) => [...prevVariants, newVariant]);
      }
      setQuantity(1);
    }
  };  
  
  const calculateOrderPrice = () => {
    let orderPrice = 0;
    selectedVariants.forEach((variant) => {
      orderPrice +=
        (variant.reduced_price ? +variant.reduced_price : +variant.price) *
        variant.quantity;
    });
    onOrderChange(orderPrice);
  };

  const handleDeleteVariant = (product: string) => {
    const newVariantsList = selectedVariants.filter(
      (variant) => variant.product !== product
    );
    setSelectedVariants(newVariantsList);
  };

  const translateWords = (word: string, lang: "en" | "fr" | "ar") => {
    if (word === "variants") {
      return variantsDict[lang];
    } else {
      return quantityDict[lang];
    }
  };


  return (
    <>
      <div className="variants-section">

        <ul className="selected-variants">
          {selectedVariants.map((variant, index) => (
            <li key={index}>
              <span>({variant.quantity})</span>
              <span>{variant.name}</span>
              <button title='delete' onClick={()=>handleDeleteVariant(variant.product)}><CloseSvg/></button>
            </li>
          ))}
        </ul>

        <div className="variants-selection">

          <div className="variants-selector">
            <label htmlFor="variants">{translateWords("variants",lang)}</label>
            <div className='variants-selector-container'>
              <select
                title="variants"
                id="variants"
                onChange={(e)=>handleVariantSelection(e.target.value)}
              >
                {variants.map((variant) => (
                  <option key={variant.product} value={variant.name}>
                    {variant.name}
                  </option>
                ))}
              </select>
              <ArrowSvg theme={theme}/>
            </div>
          </div>

          <div className="variants-quantity" key={selectedVariant?.product}>
            <label htmlFor='variant-qt'>{translateWords("quantity",lang)}</label>
           
            <div className="variants-quantity-selector">
              <input className="quantity-of-variant" id='variant-qt' type="number" value={quantity} onChange={(e)=>setQuantity( Math.max(+e.target.value , 1) )}/>
              <div className="variants-quantity-btns">
                <button className="increase-btn" title='increase' onClick={handleIncrease}>
                  <ArrowSvg theme={theme}/>
                </button>
                <button className="decrease-btn" title='decrease' onClick={handleDecrease}>
                  <ArrowSvg theme={theme}/>
                </button>
              </div>
            </div>
          </div>

          <button className="variants-btn" title='add' onClick={handleAddVariant}>
            <PlusSvg/>
          </button>

        </div>

      </div>
    </>
  );
}

export default LandingVariantsSection